import React from 'react'
import {
	FormControlLabel,
	FormHelperText,
	Checkbox as MuiCheckbox,
	CheckboxProps as MuiCheckboxProps,
} from '@mui/material'
import { Controller, FieldErrors } from 'react-hook-form'
import { RFHControl } from '../../types'

export type CheckboxProps = {
	name?: string
	label?: string
	control?: RFHControl
	errors?: FieldErrors
	className?: string
	helperText?: string
} & Omit<MuiCheckboxProps, 'className' | 'name'>

const Checkbox = ({
	name,
	label,
	control,
	defaultValue,
	className,
	helperText,
	...rest
}: CheckboxProps) => (
	<>
		<div className={className}>
			{name && control && (
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field }) => (
						<FormControlLabel
							label={label}
							control={
								<MuiCheckbox
									{...field}
									{...rest}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							}
						/>
					)}
				/>
			)}
			{!control && (
				<FormControlLabel
					label={label}
					control={
						<MuiCheckbox
							name={name}
							defaultValue={defaultValue}
							{...rest}
						/>
					}
				/>
			)}
		</div>
		{helperText && (
			<FormHelperText className="pl-8">{helperText}</FormHelperText>
		)}
	</>
)

export default Checkbox
