import React, { useMemo } from 'react'
import { PhoneNumberField } from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { InputAdornment } from '@mui/material'

const MobilePhoneForm = ({ onSubmit, loading }) => {
	const defaultValues = useMemo(
		() => ({
			mobilePhone: '',
		}),
		[]
	)

	const schema = yup.object().shape({
		mobilePhone: yup
			.string()
			.min(14)
			.required('Mobile phone is required'),
	})

	const form = useForm({
		schema,
		defaultValues,
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
	} = form

	const sendCode = () => {
		const { mobilePhone } = form.getValues()
		onSubmit(mobilePhone)
	}

	return (
		<form onSubmit={handleSubmit(sendCode)} noValidate>
			<div className="px-3 dark:text-white mb-5">
				<PhoneNumberField
					name="mobilePhone"
					className="w-full"
					label="Mobile Phone"
					placeholder="Enter mobile phone"
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">+1</InputAdornment>
						),
					}}
					control={control}
					errors={errors}
				/>
			</div>
			<p className="mb-5">
				By clicking the "I agree" button below, I give MTG Alerts my
				express written consent to send me text message notifications.
				Message and data rates may apply. I understand that my consent
				is voluntary and not a condition of purchase. I may text STOP
				to any message to cancel further text communications. Carriers
				are not liable for delayed or undelivered messages.
				<br />
				<br />
				For more information, please read our privacy policy (
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.thebigpos.com/privacy-policy/"
				>
					https://www.thebigpos.com/privacy-policy/
				</a>
				) and terms of use (
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.thebigpos.com/terms-of-use/"
				>
					https://www.thebigpos.com/terms-of-use/
				</a>
				)
			</p>
			<LoadingBtn
				disabled={isSubmitting || loading}
				loading={isSubmitting || loading}
				fullWidth
				variant="contained"
				type="submit"
				text="I Agree"
			/>
		</form>
	)
}

export default MobilePhoneForm
