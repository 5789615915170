import React from 'react'
import clsx from 'clsx'

const ActiveVersionAlert = ({ status }) => {
	const activeVersionStyleMap = {
		inactive: 'bg-orange-100 text-orange-800 border-orange-200',
		active: 'bg-green-100 text-green-800 border-green-200',
	}

	return (
		<div
			className={clsx(
				'px-3 py-2 rounded mb-1 border text-xs',
				activeVersionStyleMap[status]
			)}
		>
			Editing {status === 'inactive' ? 'an inactive' : 'the active'}{' '}
			version
		</div>
	)
}

export default ActiveVersionAlert
