import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'

export type TrueFalseIconProps = {
	value: boolean
	className?: string
}

const TrueFalseIcon = ({ value, className }: TrueFalseIconProps) => {
	const colorMap = {
		green: 'text-green-600',
		red: 'text-red-600',
	}

	return value ? (
		<CheckIcon className={clsx(colorMap.green, className)} />
	) : (
		<CloseIcon className={clsx(colorMap.red, className)} />
	)
}

export default TrueFalseIcon
