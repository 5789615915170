import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { TaskComment } from '@matech/thebigpos-sdk'
import { IconButton } from '../../../../components/Button'
import { formatDate } from '../../../../services/helper'

type ChatBubbleProps = {
	comment: TaskComment
	onConfirmDelete: (comment: TaskComment) => void
	createdByMe: boolean
}

const ChatBubble = ({
	comment,
	createdByMe,
	onConfirmDelete,
}: ChatBubbleProps) => {
	const bubbleStyles = createdByMe
		? 'bg-blue-400 text-white self-end'
		: 'bg-gray-200 text-black self-start'
	const maxWidthStyles = 'max-w-full sm:max-w-1/2 md:max-w-[50%]'

	return (
		<div className="mb-2 w-full flex flex-col">
			<div
				className={`inline-flex ${createdByMe ? 'justify-end items-center' : 'justify-start items-start'}`}
			>
				<div
					className={`p-3 rounded-lg ${bubbleStyles} ${maxWidthStyles} min-w-[10%]`}
				>
					{!createdByMe && (
						<div className="flex justify-start text-black">
							<small>
								<strong>
									{comment.createdBy.firstName}{' '}
									{comment.createdBy.lastName}
								</strong>
							</small>
						</div>
					)}
					<p>{comment.comment}</p>
				</div>
				{createdByMe && (
					<div className="flex flex-col items-center pb-3 pt-3">
						<IconButton
							color="error"
							size="small"
							aria-label="delete"
							onClick={() => onConfirmDelete(comment)}
						>
							<DeleteIcon style={{ fontSize: 16 }} />
						</IconButton>
					</div>
				)}
			</div>
			<div
				className={`flex ${createdByMe ? 'self-end' : 'self-start'} items-center mt-1`}
			>
				<small className="text-gray-500 italic pr-8">
					{formatDate(comment.createdAt, true)}
				</small>
			</div>
		</div>
	)
}

export default ChatBubble
