import React from 'react'
import { LoanQueue } from '@matech/thebigpos-sdk'
import Badge, { BadgeProps } from '../Badge'

export type LoanQueueStatusBadgeProps = {
	status: LoanQueue['status']
}

const LoanQueueStatusBadge = ({
	status,
}: LoanQueueStatusBadgeProps) => {
	const statusColorMap: Record<
		LoanQueue['status'],
		BadgeProps['color']
	> = {
		Pending: 'info',
		Processing: 'warning',
		Completed: 'success',
		Failed: 'danger',
		Error: 'danger',
		Canceled: 'warning',
		Retrying: 'warning',
	}

	return <Badge color={statusColorMap[status]} label={status} />
}

export default LoanQueueStatusBadge
