import React from 'react'
import type { DialogProps } from './Dialog'
import TaskForm, { TaskFormProps } from '../../forms/TaskForm'
import Dialog from './Dialog'

export type ModalTaskFormProps = Omit<DialogProps, 'onSubmit'> &
	TaskFormProps

const ModalTaskForm = ({
	task,
	onSubmit,
	onClose,
	loanId,
	borrowers,
	isGlobalDisabled,
	...rest
}: ModalTaskFormProps) => {
	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			title={task ? 'Edit Task' : 'Add Task'}
			onClose={onClose}
			{...rest}
		>
			<TaskForm
				task={task}
				onSubmit={onSubmit}
				onCancel={onClose}
				loanId={loanId}
				borrowers={borrowers}
				isGlobalDisabled={isGlobalDisabled}
			/>
		</Dialog>
	)
}

export default ModalTaskForm
