import React, { useState } from 'react'
import { Editor, EditorProps } from '@monaco-editor/react'
import clsx from 'clsx'
import { editor } from 'monaco-editor'
import { isDarkMode } from '../services/helper'
import LoadingDots from './LoadingDots'

export type ValidationErrorProps = {
	message: string
	startLineNumber: number
}

export type CodeEditorProps = EditorProps & {
	loading?: boolean
	className?: string
	onValidate?: (markers: editor.IMarker) => void
	errors?: string[]
}

const CodeEditor = ({
	loading = false,
	className,
	onValidate,
	errors = [],
	...rest
}: CodeEditorProps) => {
	const [validationErrors, setValidationErrors] = useState<
		editor.IMarker[]
	>([])
	const handleValidate = (markers: editor.IMarker[]) => {
		setValidationErrors(markers)
		if (onValidate) {
			onValidate(markers)
		}
	}
	return (
		<div className={className}>
			{loading && (
				<div className="flex flex-col items-center w-full">
					<LoadingDots className="w-48" />
				</div>
			)}
			{!loading && (
				<Editor
					width="100%"
					onValidate={handleValidate}
					theme={isDarkMode() ? 'vs-dark' : 'light'}
					className={clsx(
						validationErrors.length > 0
							? 'border-red-500'
							: 'border-gray-200',
						'border-2'
					)}
					{...rest}
				/>
			)}
			{(validationErrors.length > 0 || errors.length > 0) && (
				<div className="ml-2 pt-2 w-full text-xs">
					{validationErrors.map((error: ValidationErrorProps) => (
						<div key={error.message} className="text-red-600">
							Line {error.startLineNumber}: {error.message}
						</div>
					))}
					{errors.length > 0 && (
						<div className="text-red-600">
							{errors?.map((error) => (
								<div key={error}>
									{/* @todo errors are sometimes strings and sometimes objects */}
									{typeof error === 'object' ? '' : error}
								</div>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	)
}

export default CodeEditor
