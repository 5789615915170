import React from 'react'
import { useIntl } from 'react-intl'
import { Task, UserLoanTask } from '@matech/thebigpos-sdk'
import { Button } from '../../../../components/Button'
import { getTheme } from '../../../../config'
import { useWindowSize } from '../../../../hooks'

const theme = getTheme()

export type TaskActionButtonProps = {
	task: Task
	status: UserLoanTask['status']
	onClick: () => void
}
const TaskActionButton = ({
	task,
	status,
	...rest
}: TaskActionButtonProps) => {
	const { mobile: breakpoint } = theme.breakpoints
	const [width] = useWindowSize()
	const isMobile = width < breakpoint
	const { formatMessage } = useIntl()

	const VERIFY =
		status === 'Completed'
			? formatMessage({ id: 'global.reverify' })
			: formatMessage({ id: 'global.verify' })

	const textMap: Record<Task['type'], string> = {
		Document: formatMessage({ id: 'global.upload' }),
		Field: formatMessage({ id: 'global.answer' }),
		VerificationOfAssets: VERIFY,
		VerificationOfIncome: VERIFY,
		VerificationOfEmployment: VERIFY,
		VerificationOfIncomeAndEmployment: VERIFY, // verify income and employment done together, might not be separate
	}

	if (!textMap[task.type]) {
		// enum may exist but feature is incomplete
		return null
	}

	return (
		<Button
			text={textMap[task.type]}
			size={isMobile ? 'small' : undefined}
			style={!isMobile ? { width: 160 } : undefined}
			{...rest}
		/>
	)
}

export default TaskActionButton
