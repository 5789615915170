import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import ChangePassword from '../ChangePassword'
import { DialogTitle, DialogContent } from '@mui/material'
import { LoadingBtn } from '../Button'
import { useVenti } from 'venti'
import { getTheme } from '../../config'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'

const theme = getTheme()

export const ModalNewUser = ({ visible = false }) => {
	const ventiState = useVenti()

	const [loading, setLoading] = useState(false)
	const [userPassword, setUserPassword] = useState('')
	const [errorMessage, setErrorMessage] = useState()
	const [disabledSignUpButton, setDisabledSignUpButton] =
		useState(true)

	const handlePasswordChange = async () => {
		setLoading(true)

		try {
			await TheBigPOSApi.changePassword({
				oldPassword: ventiState.get(theme.storageKeys.tempPassword),
				newPassword: userPassword,
			})
			setLoading(false)
			ventiState.set(theme.storageKeys.newUser, false)
		} catch (e) {
			setErrorMessage(
				(e && e.ModelState && e.ModelState[''][0]) ||
					'We encountered an issue while fulfilling the request. Please try again later.'
			)
			setLoading(false)
		}
	}

	return (
		<Dialog open={visible} aria-labelledby="responsive-dialog-title">
			<DialogTitle>Set Your Password</DialogTitle>
			<DialogContent>
				<div className="mb-2">
					<p className="text-sm sm:text-base dark:text-white">
						It's important to set a secure password that you will
						remember
					</p>
				</div>
				<ChangePassword
					passwordLabel={'Create password'}
					confirmPasswordLabel={'Confirm password'}
					userPassword={userPassword}
					setUserPassword={setUserPassword}
					setConfirmButton={setDisabledSignUpButton}
					confirmButtonEnabled={!disabledSignUpButton}
					handleSubmit={handlePasswordChange}
					hideConfirmPassword={true}
				/>
			</DialogContent>
			{errorMessage && (
				<div className="pr-5 pl-5">{errorMessage}</div>
			)}
			<DialogActions className="ml-5 mr-5">
				<div className="flex justify-center items-center">
					<LoadingBtn
						className="mt-5"
						text="Sign up"
						onClick={handlePasswordChange}
						disabled={!disabledSignUpButton || loading}
						loading={loading}
					/>
				</div>
			</DialogActions>
		</Dialog>
	)
}
