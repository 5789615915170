import React from 'react'
import {
	Controller,
	Control,
	FieldErrors,
	useFormContext,
} from 'react-hook-form'
import CodeEditor, { CodeEditorProps } from '../CodeEditor'
import useFormField from '../../hooks/useFormField'

export type CodeEditorFieldProps = {
	name: string
	control?: Control
	errors?: FieldErrors | string[]
	helperText?: string
} & Omit<CodeEditorProps, 'errors'>

const CodeEditorField = ({
	name,
	control: propsControl,
	errors: propsErrors,
	...rest
}: CodeEditorFieldProps) => {
	const formContext = useFormContext()
	const control = propsControl || formContext?.control
	const errors = propsErrors || formContext?.formState.errors

	const { getError } = useFormField()
	const error = getError(name, errors)

	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<CodeEditor
					errors={error ? [error] : []}
					{...field}
					{...rest}
				/>
			)}
		/>
	)
}

export default CodeEditorField
