import React, { ReactNode } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import { Button, LoadingBtn } from '../Button'
import Dialog, { DialogProps } from './Dialog'

type ConfirmModalProps = {
	onConfirm?: () => void
	title: string | ReactNode
	subtitle?: string | ReactNode
	disclaimer?: string | ReactNode
	children?: string | ReactNode
	button: {
		title: string
		loading: boolean
		disabled?: boolean
	}
} & DialogProps

const ConfirmModal = ({
	onClose,
	open,
	title,
	subtitle,
	disclaimer,
	children,
	button,
	onConfirm,
	...rest
}: ConfirmModalProps) => {
	const theme = useTheme()
	const { formatMessage } = useIntl()
	const smallScreen = useMediaQuery(theme.breakpoints.down('md'))

	const handleCloseModal = () => {
		onClose?.()
	}

	return (
		<Dialog
			open={open}
			onClose={handleCloseModal}
			aria-labelledby="responsive-dialog-title"
			{...rest}
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<p className="pb-5 text-2xl text-center font-bold">{title}</p>
				{disclaimer}
				{subtitle && (
					<p className="text-xl mb-5 md:mx-10 text-center">
						{subtitle}
					</p>
				)}
				{children}
				<LoadingBtn
					id="ModalConfirmRemoveButton"
					text={button.title}
					onClick={onConfirm}
					style={
						smallScreen
							? { width: '90%', marginBottom: 10 }
							: { width: 290, marginBottom: 10 }
					}
					variant="contained"
					loading={button.loading}
					disabled={button.disabled || false}
				/>
				<Button
					className="dark:text-white"
					id="ModalConfirmCloseButton"
					text={formatMessage({ id: 'remove_record.cancel' })}
					onClick={handleCloseModal}
					disabled={button.loading}
					style={smallScreen ? { width: '90%' } : { width: 290 }}
					variant="outlined"
				/>
			</div>
		</Dialog>
	)
}

export default ConfirmModal
