import en from './en'
import es from './es'

export type Locale = 'en' | 'es'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type LocaleMessages = any

const flatten = (obj: LocaleMessages, prefix: string = '') => {
	const flattened: Record<string, string> = {}

	Object.keys(obj).forEach((key) => {
		const value = obj[key]
		const prefixedKey = prefix ? `${prefix}.${key}` : key

		if (typeof value === 'object' && value !== null) {
			Object.assign(flattened, flatten(value, prefixedKey))
		} else {
			flattened[prefixedKey] = value
		}
	})

	return flattened
}

const locales: Record<Locale, Record<string, LocaleMessages>> = {
	en: flatten(en),
	es: flatten(es),
}

export const defaultLocale = 'en'
export default locales
