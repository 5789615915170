import React from 'react'
import LoanDrafts from '../pipeline/components/LoanDrafts'
import Page from '../../../components/Page'

const IncompleteLoans = () => {
	return (
		<Page isFullWidth title="Incomplete Loans">
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<LoanDrafts />
			</div>
		</Page>
	)
}

export default IncompleteLoans
