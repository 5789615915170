import React, { useRef, useState, useEffect } from 'react'
import { Link, Typography } from '@mui/material'
import AuthWrapper from './AuthWrapper'
import { useAppContext } from '../../components/AppContext'
import { getTheme } from '../../config'
import { navigationLinking } from '../../services/navigation'
import { useLocation, useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import { ReportProblemSharp } from '@mui/icons-material'
import useUser from '../../hooks/useUser'
import SignInForm from '../../forms/SignInForm'
import SiteLogo from '../../components/SiteLogo'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'
import { FormattedMessage, useIntl } from 'react-intl'

const theme = getTheme()

export default function SignInView({ support = false }) {
	const ventiState = useVenti()
	const navigate = useNavigate()
	const location = useLocation()
	const { state } = useAppContext()
	const { siteConfig, invite } = state
	const { initUser } = useUser()
	const { formatMessage } = useIntl()

	const [errorMessage, setErrorMessage] = useState(null)
	const [email, setEmail] = useState('')
	const postLoanData =
		ventiState.get(theme.storageKeys.postLoanData) || {}

	const mountedRef = useRef(true)

	useEffect(() => {
		if (postLoanData.BorrowerEmail)
			setEmail(postLoanData.BorrowerEmail)
	}, [postLoanData])

	// sets user email when navigating from verify forgot password
	useEffect(() => {
		if (location.state && location.state.email) {
			setEmail(location.state.email)
		}
	}, [location])

	// sets email from invite
	useEffect(() => {
		if (invite?.emailAddress) {
			setEmail(invite.emailAddress)
		}
	}, [invite])

	const handleSignIn = async (data) => {
		const { email, password } = data
		setErrorMessage(false)

		try {
			const authResponse = await TheBigPOSApi.getToken({
				username: email,
				password,
				siteConfigurationId: siteConfig.id,
				isSupport: support,
			})

			const authResult = authResponse.data

			if (!mountedRef.current) return

			if (authResult?.challengeName) {
				navigate(`/${navigationLinking.AuthChallenge}`, {
					state: {
						email,
						sessionId: authResult?.sessionID,
						from: location.state?.from,
					},
				})
				return
			}

			if (authResult?.access_token) {
				try {
					await initUser(authResult)
				} catch (e) {
					setErrorMessage(
						formatMessage({
							id: 'errors.account_doesnt_have_access_to_env',
						})
					)
				}
			} else {
				setErrorMessage(
					formatMessage({ id: 'errors.error_retrieving_auth' })
				)
			}
		} catch (err) {
			const errorMessage =
				err?.data?.message ||
				formatMessage({ id: 'errors.invalid_credentials' })
			setErrorMessage(errorMessage)
		}
	}

	return (
		<AuthWrapper
			siteConfig={siteConfig}
			title={formatMessage({ id: 'auth.sign_in' })}
		>
			<>
				<SiteLogo className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5" />
				{!!postLoanData.BorrowerEmail && (
					<p className="text-md sm:text-1xl font-bold mb-6 text-red dark:text-white">
						<ReportProblemSharp />{' '}
						<FormattedMessage id="auth.existing_user_account_detected" />
					</p>
				)}
				<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">
					<FormattedMessage id="auth.sign_in_to_account" />
				</p>
				<div>
					<SignInForm
						onSubmit={handleSignIn}
						email={
							email ||
							postLoanData?.BorrowerEmail ||
							invite?.emailAddress
						}
						errors={errorMessage ? [{ message: errorMessage }] : []}
						emailDisabled={
							!!postLoanData.BorrowerEmail || !!invite?.emailAddress
						}
					/>
					<div className="mt-4">
						<div className="text-sm dark:text-white pb-2">
							<FormattedMessage id="auth.dont_have_an_account" />
						</div>
						<div className="text-sm dark:text-white text-wrap">
							<FormattedMessage id="auth.check_inbox_for_invite_from_loan_officer" />
						</div>
					</div>
					<div className="w-full flex justify-end">
						<Link
							className="text-blue-500 mt-4 cursor-pointer"
							onClick={() => {
								navigate(`/${navigationLinking.ForgotPassword}`)
							}}
							rel="noopener noreferrer"
							underline="hover"
							style={{
								color: theme.siteConfig.color.primary,
								marginTop: 16,
							}}
						>
							<Typography variant="link" className="text-sm">
								<FormattedMessage id="auth.forgot_my_password" />
							</Typography>
						</Link>
					</div>
				</div>
			</>
		</AuthWrapper>
	)
}
