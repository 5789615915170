import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { useQueryClient } from '@tanstack/react-query'
import { noop } from 'lodash'
import {
	EnabledServices,
	SiteConfiguration,
} from '@matech/thebigpos-sdk'
import RefreshPNG from 'assets/lordicons/Refresh.png'
import RefreshGIF from 'assets/lordicons/RefreshAnimated.gif'
import RefreshDarkPNG from 'assets/lordicons/RefreshDark.png'
import RefreshDarkGIF from 'assets/lordicons/RefreshAnimatedDark.gif'
import { useAppContext } from '../../../components/AppContext'
import { LoanInfo } from '../../../components/loanAppDetails/LoanInfo'
import { PersonalInfo } from '../../../components/loanAppDetails/PersonalInfo'
import { RealEstateOwned } from '../../../components/loanAppDetails/RealEstateOwned'
import { EmploymentIncome } from '../../../components/loanAppDetails/EmploymentIncome'
import { ModalPleaseWait } from '../../../components/modals/ModalPleaseWait'
import { Assets } from '../../../components/loanAppDetails/Assets'
import { Roles } from '../../../services/client'
import { a11yProps } from '../../../services/helper'
import TabPanel from '../../../components/TabPanel'
import LoanStatus from '../../../components/loanAppDetails/LoanStatus'
import LoanUsersTable from '../../../components/loanAppDetails/LoanUsersTable'
import LoanQueueList from '../../../components/LoanQueue/LoanQueueList'
import useUser from '../../../hooks/useUser'
import { useLoanContext } from '../../../contexts/LoanContext'
import queryKeys from '../../../services/queryKeys'

type TabKey = keyof EnabledServices

type TabRoute = {
	key: TabKey
	title: string
	index: number
}

type EnhancedRoute = Omit<TabRoute, 'key'> & {
	key: TabKey | 'people' | 'queue'
}

const bgColor = {
	'&:hover': {
		backgroundColor: 'transparent',
	},
}

enum TabsIndex {
	loanStatus = 0,
	loanAndProperty = 1,
	personalInformation = 2,
	employment = 3,
	assets = 4,
	realEstate = 5,
	people = 6,
	queue = 7,
}

const baseRoutes: TabRoute[] = [
	{
		key: 'loanStatus',
		title: 'Loan Status',
		index: TabsIndex.loanStatus,
	},
	{
		key: 'loanAndProperty',
		title: 'Loan and Property',
		index: TabsIndex.loanAndProperty,
	},
	{
		key: 'personalInformation',
		title: 'Personal Information',
		index: TabsIndex.personalInformation,
	},
	{
		key: 'employment',
		title: 'Employment',
		index: TabsIndex.employment,
	},
	{
		key: 'assets',
		title: 'Asset Accounts',
		index: TabsIndex.assets,
	},
	{
		key: 'realEstate',
		title: 'Real Estate',
		index: TabsIndex.realEstate,
	},
]

function getAvailableTabs(
	enabledServices: EnabledServices | undefined,
	isLoanAdmin: boolean,
	customFilter?: (route: EnhancedRoute) => boolean
) {
	if (!enabledServices) return []
	const tabs = baseRoutes.filter((route) => {
		return enabledServices[route.key] && customFilter?.(route)
	}) as EnhancedRoute[]
	tabs.push({
		key: 'people',
		title: 'People',
		index: TabsIndex.people,
	})
	if (isLoanAdmin) {
		tabs.push({
			key: 'queue',
			title: 'Loan Queue',
			index: TabsIndex.queue,
		})
	}

	return tabs
}

type LoanDetailsContentProps = {
	className?: string
}

export default function LoanDetailsContent({
	className = '',
}: LoanDetailsContentProps) {
	const themeMUI = useTheme()
	const { isLoanAdmin } = useUser()
	const { loanData, isLoading, isRefetching, refetch } =
		useLoanContext()
	const { state } = useAppContext()
	const { user } = state
	const siteConfig = state.siteConfig as SiteConfiguration | null
	const queryClient = useQueryClient()
	const enabledServices = siteConfig?.enabledServices
	function realtorFilter(route: EnhancedRoute) {
		const amIRealtor = user?.role === Roles.realtor
		if (
			amIRealtor &&
			['employment', 'assets', 'realEstate'].includes(route.key)
		)
			return false
		return true
	}

	const tabs = getAvailableTabs(
		enabledServices,
		isLoanAdmin,
		realtorFilter
	)

	const [activeTab, setActiveTab] = React.useState(
		tabs?.length > 0 ? tabs[0].index : 0
	)

	const handleTabChange = (
		_event: React.SyntheticEvent<Element, Event>,
		value: number
	) => {
		setActiveTab(value)
	}

	const handleChangeIndex = (index: number) => {
		setActiveTab(index)
	}

	const handleOnClick = async () => {
		await refetch?.()
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanQueue],
		})

		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanUsers],
		})
	}

	const isLoadingOrRefetching = isLoading || isRefetching

	return (
		<div className={className}>
			<div className="flex items-center">
				<p className="text-xl md:text-2xl font-rubik font-bold mr-4 dark:text-white">
					Loan Details
				</p>
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="refresh"
					onClick={handleOnClick}
					disabled={isLoadingOrRefetching}
					sx={bgColor}
				>
					<img
						className="w-14 mt-1"
						src={
							themeMUI.palette.mode === 'light'
								? RefreshPNG
								: RefreshDarkPNG
						}
						onFocus={noop}
						onBlur={noop}
						onMouseOver={(e) => {
							e.currentTarget.src =
								themeMUI.palette.mode === 'light'
									? RefreshGIF
									: RefreshDarkGIF
						}}
						onMouseOut={(e) => {
							e.currentTarget.src =
								themeMUI.palette.mode === 'light'
									? RefreshPNG
									: RefreshDarkPNG
						}}
						alt="refresh"
					/>
				</IconButton>
			</div>

			<ModalPleaseWait
				visible={
					isLoadingOrRefetching &&
					[Roles.loanOfficer, Roles.branchManager].includes(
						user?.role ?? ''
					)
				}
				message="Fetching loan information"
			/>

			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={activeTab}
				onChange={handleTabChange}
				aria-label="Tabs"
			>
				{tabs.map((route) => (
					<Tab
						value={route.index}
						label={route.title}
						key={route.key}
						{...a11yProps(route.index)}
					/>
				))}
			</Tabs>
			<SwipeableViews
				axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeTab}
				onChangeIndex={handleChangeIndex}
			>
				{siteConfig?.enabledServices.loanStatus && (
					<TabPanel
						value={activeTab}
						index={TabsIndex.loanStatus}
						dir={themeMUI.direction}
					>
						<Card className="min-h-screen overflow-auto">
							<CardContent>
								{!isLoadingOrRefetching &&
									loanData &&
									Object.keys(loanData).length > 0 && (
										<LoanStatus loan={loanData} />
									)}
							</CardContent>
						</Card>
					</TabPanel>
				)}
				<TabPanel
					value={activeTab}
					index={TabsIndex.loanAndProperty}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanInfo
								data={loanData}
								loading={isLoadingOrRefetching}
							/>
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.personalInformation}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<PersonalInfo
									data={loanData}
									loading={isLoadingOrRefetching}
								/>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.employment}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<EmploymentIncome
											key={`Employment${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.assets}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<Assets
											key={`AssetAccount${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.realEstate}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							{loanData && (
								<>
									{[0, 1, 2].map((index) => (
										<RealEstateOwned
											key={`RealEstateOwned${index}`}
											data={loanData}
											loading={isLoadingOrRefetching}
											index={index}
										/>
									))}
								</>
							)}
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.people}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanUsersTable loanId={loanData?.LoanID} />
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={TabsIndex.queue}
					dir={themeMUI.direction}
				>
					<Card className="min-h-screen overflow-auto">
						<CardContent>
							<LoanQueueList loanId={loanData?.LoanID} />
						</CardContent>
					</Card>
				</TabPanel>
			</SwipeableViews>
		</div>
	)
}
