import React, { useState } from 'react'
import { useAppContext } from '../../components/AppContext'
import AuthCodeForm from '../../forms/AuthCodeForm'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthPage from './AuthPage'
import useUser from '../../hooks/useUser'
import { Link } from '@mui/material'
import { navigationLinking } from '../../services/navigation'
import { getErrorMessage } from '../../services/helper'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'

const AuthChallenge = () => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const location = useLocation()
	const navigate = useNavigate()
	const { initUser } = useUser()
	const [errors, setErrors] = useState([])

	const onSubmit = async (data) => {
		const { code } = data
		const { email, sessionId } = location.state

		let result
		try {
			result = await TheBigPOSApi.getTokenFromChallengeCode({
				username: email,
				sessionId,
				code,
				siteConfigurationId: siteConfig.id,
			})
		} catch (e) {
			setErrors([
				{
					message: e.data.message
						? e.data.message
						: getErrorMessage(e),
				},
			])
		}

		if (!result?.data) {
			return
		}

		try {
			await initUser(result.data)
		} catch (e) {
			setErrors([
				{
					message:
						'Your account does not have access to this environment',
				},
			]) // This message is not accurate
		}
	}

	const onSignInClick = (e) => {
		e.preventDefault()
		navigate(`/${navigationLinking.SignIn}`)
	}

	return (
		<AuthPage title="Authorization Code">
			<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">
				Authorization Code
			</p>
			<p>
				Enter the authorization code that was sent to your mobile
				phone.
			</p>
			<div className="mt-5">
				<AuthCodeForm onSubmit={onSubmit} errors={errors} />
				<div className="mt-2">
					<div className="mt-2 flex items-center">
						<small>
							Didn't get the code?{' '}
							<Link onClick={onSignInClick}>
								Try logging in again
							</Link>
						</small>
					</div>
				</div>
			</div>
		</AuthPage>
	)
}

export default AuthChallenge
