import React from 'react'
import LoanDetailsContent from './LoanDetailsContent'
import LoanPage from '../../../components/LoanPage'
import withAuth from '../../../components/withAuth'

const LoanDetails = () => {
	return (
		<LoanPage
			page="app-loan-details"
			title="Loan Details"
			isFullWidth
		>
			<LoanDetailsContent />
		</LoanPage>
	)
}

export default withAuth(LoanDetails)
