import { Button } from '@mui/material'
import React from 'react'

type LeaveCommentButtonProps = {
	onClick: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void
	title: string
}
const LeaveCommentButton = ({
	title,
	onClick,
}: LeaveCommentButtonProps) => {
	return (
		<Button
			onClick={onClick}
			type="button"
			variant="outlined"
			sx={{ textTransform: 'none' }}
		>
			{title}
		</Button>
	)
}

export default LeaveCommentButton
