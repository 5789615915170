import React, { createContext, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import locales, { defaultLocale, Locale } from '../i18n'

export type LanguageContextProps = {
	locale: string
	setLocale: (locale: string) => void
}

export const LanguageContext =
	createContext<LanguageContextProps | null>(null)

export type LanguageProviderProps = {
	children: React.ReactNode
}

const LanguageProvider = ({ children }: LanguageProviderProps) => {
	const [locale, setLocale] = useState(
		navigator.language.split('-')[0] || defaultLocale
	)

	const localeMessages =
		locales[locale as Locale] ?? locales[defaultLocale]

	const contextValue = useMemo(
		() => ({
			locale,
			setLocale,
		}),
		[locale, setLocale]
	)
	return (
		<LanguageContext.Provider value={contextValue}>
			{/** @ts-ignore * */}
			<IntlProvider
				messages={localeMessages}
				locale={locale}
				defaultLocale={defaultLocale}
			>
				{/** @ts-ignore * */}
				{children}
			</IntlProvider>
		</LanguageContext.Provider>
	)
}

export default LanguageProvider
