import React, { useMemo } from 'react'
import ErrorList from '../components/ErrorList'
import Form from '../components/formControls/Form'
import { TextField, PasswordField } from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { getTheme } from '../config'
import { useIntl } from 'react-intl'

const theme = getTheme()

const SignInForm = ({
	onSubmit,
	className = null,
	email = '',
	emailDisabled = false,
	errors: apiErrors = [],
}) => {
	const { formatMessage } = useIntl()
	const defaultValues = useMemo(
		() => ({
			email,
			password: '',
		}),
		[email]
	)

	const schema = yup.object().shape({
		email: yup
			.string()
			.email(formatMessage({ id: 'validation.email.invalid' }))
			.required(formatMessage({ id: 'validation.email.required' })),
		password: yup
			.string()
			.required(formatMessage({ id: 'validation.password.required' }))
			.min(
				theme.validation.passwordLength,
				formatMessage(
					{ id: 'validation.password.minLength' },
					{ minLength: theme.validation.passwordLength }
				)
			),
	})

	const form = useForm({
		schema,
		defaultValues,
	})

	const {
		handleSubmit,
		getValues,
		formState: { errors, isSubmitting },
		control,
	} = form

	const onFormSubmit = async () => {
		const data = getValues()
		await onSubmit(data)
	}

	return (
		<Form onSubmit={handleSubmit(onFormSubmit)} className={className}>
			<ErrorList errors={apiErrors} className="mb-3" />
			<TextField
				name="email"
				label={formatMessage({ id: 'global.email' })}
				className="dark:text-white"
				disabled={emailDisabled}
				fullWidth
				control={control}
				errors={errors}
			/>
			<div className="w-full mt-2">
				<PasswordField
					name="password"
					label={formatMessage({ id: 'global.password' })}
					className="dark:text-white"
					fullWidth
					control={control}
					errors={errors}
				/>
			</div>
			<div className="w-full mt-6">
				<LoadingBtn
					type="submit"
					disabled={isSubmitting}
					loading={isSubmitting}
					fullWidth
					text={formatMessage({ id: 'auth.sign_in' })}
				/>
			</div>
		</Form>
	)
}

export default SignInForm
