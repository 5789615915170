import React, { useEffect, useState } from 'react'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { TableColumn } from 'react-data-table-component'
import {
	LoanQueue,
	LoanQueueReason,
	LoanQueueSearchCriteria,
	LoanQueueType,
	LOSStatus,
	UpdateLoanQueueRequest,
} from '@matech/thebigpos-sdk'
import { SelectChangeEvent } from '@mui/material'
import { AxiosError } from 'axios'
import { useIntl } from 'react-intl'
import { getTheme } from '../../config'
import { useAlert } from '../../hooks'
import useSearch from '../../hooks/useSearch'
import usePagination from '../../hooks/usePagination'
import { FixMeLater } from '../../types'
import queryKeys from '../../services/queryKeys'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'
import { formatDate } from '../../services/helper'
import DataTable, { ActionItem } from '../DataTable'
import LoanQueueSearchFilters from './LoanQueueSearchFilters'
import LoanQueueExpandedRow from './LoanQueueExpandedRow'
import useUser from '../../hooks/useUser'
import { ModalRemoveRestoreRecordConfirm } from '../modals/ModalRemoveRestoreRecordConfirm'
import LoanQueueTypeLabel from './LoanQueueTypeLabel'
import LoanQueueStatusBadge from './LoanQueueStatusBadge'
import Dialog from '../modals/Dialog'
import LoanQueueItemForm from '../../forms/LoanQueueItemForm'

const theme = getTheme()

type LoanQueueListProps = {
	loanId?: string
	title?: string
}

export type LoanQueueSearchCriteriaFilters = {
	status?: LOSStatus & 'all'
	type?: LoanQueueType & 'all'
	reason?: LoanQueueReason & 'all'
} & LoanQueueSearchCriteria

const LoanQueueList = ({ loanId, title }: LoanQueueListProps) => {
	const { alert } = useAlert()
	const queryClient = useQueryClient()
	const { isAdmin } = useUser()
	const { formatMessage } = useIntl()
	const { dataTable: breakpoint } = theme.breakpoints

	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})
	const [searchCriteria, setSearchCriteria] =
		useState<LoanQueueSearchCriteria>({
			searchText,
			loanID: loanId,
		} as LoanQueueSearchCriteria)

	const [filters, setFilters] = useState({
		type: undefined,
		reason: undefined,
		status: undefined,
	} as LoanQueueSearchCriteriaFilters)

	const [viewModalOpen, setViewModalOpen] = useState(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedItem, setSelectedItem] = useState<LoanQueue | null>(
		null
	)

	const { defaultSortColumn, defaultSortDirection } = theme.pagination
	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<LoanQueue>({
		defaultSortBy: defaultSortColumn,
		defaultSortDirection: defaultSortDirection as FixMeLater,
	})

	const {
		isFetching,
		isRefetching,
		isError,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.loanQueue,
			{
				loanId,
				searchCriteria,
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			},
		],
		queryFn: () =>
			TheBigPOSApi.searchLoanQueue(searchCriteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	const {
		data: selectedItemResult,
		isError: selectedItemError,
		isFetching: selectedItemIsFetching,
	} = useQuery({
		queryKey: [
			queryKeys.loanQueue,
			{
				loanId,
				id: selectedItem?.id,
			},
		],
		queryFn: () => TheBigPOSApi.getLoanQueue(selectedItem?.id || ''),
		enabled: !!selectedItem && viewModalOpen,
	})

	useEffect(() => {
		if (!isError && !selectedItemError) return

		alert(formatMessage({ id: 'errors.error_loading' }), {
			severity: 'error',
		})
	}, [isError, alert, formatMessage, selectedItemError])

	useEffect(() => {
		setSearchCriteria((prev) => ({ ...prev, searchText }))
	}, [searchText])

	const handleFilterChange = (
		e: SelectChangeEvent<string>,
		field: string
	) => {
		setFilters((prev) => ({
			...prev,
			[field]: e.target.value,
		}))
	}

	const columns: TableColumn<LoanQueue>[] = [
		{
			name: formatMessage({ id: 'global.type' }),
			selector: (row: LoanQueue) => row.type,
			cell: (row: LoanQueue) => (
				<LoanQueueTypeLabel type={row.type} />
			),
		},
		{
			name: formatMessage({ id: 'global.reason' }),
			selector: (row: LoanQueue) => row.reason,
			hide: breakpoint,
		},
		{
			name: formatMessage({ id: 'global.status' }),
			selector: (row: LoanQueue) => row.status,
			cell: (row: LoanQueue) => (
				<LoanQueueStatusBadge status={row.status} />
			),
		},
		...(isAdmin
			? [
					{
						name: formatMessage({ id: 'users.loan_officer' }),
						selector: (row: LoanQueue) =>
							row.loanOfficer
								? `${row.loanOfficer.firstName} ${row.loanOfficer.lastName}`
								: formatMessage({ id: 'loans.not_assigned' }),
						hide: breakpoint,
					},
				]
			: []),
		{
			name: formatMessage({ id: 'global.created' }),
			sortField: 'createdAt',
			selector: (row: LoanQueue) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: breakpoint,
		},
	]

	const resendMutation = useMutation({
		mutationFn: (loanQueueId: string) => {
			return TheBigPOSApi.retryLoanQueue(loanQueueId)
		},
	})

	const deleteMutation = useMutation({
		mutationFn: (loanQueueId: string) => {
			return TheBigPOSApi.deleteLoanQueue(loanQueueId)
		},
	})

	const updateMutation = useMutation({
		mutationFn: ({
			loanQueueId,
			data,
		}: {
			loanQueueId: string
			data: UpdateLoanQueueRequest
		}) => {
			return TheBigPOSApi.replaceLoanQueue(loanQueueId, { data })
		},
	})

	const handleRowResendClick = async (row: LoanQueue) => {
		try {
			await resendMutation.mutateAsync(row.id)
			alert(
				formatMessage(
					{ id: 'alerts.save_success' },
					{ name: formatMessage({ id: 'global.retry' }) }
				),
				{
					severity: 'success',
				}
			)
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanQueue, { loanId }],
			})
		} catch (err) {
			if (err instanceof AxiosError) {
				alert(
					err?.response?.data?.message ||
						theme.api_messages.server_error,
					{ severity: 'error' }
				)
			} else {
				alert(theme.api_messages.server_error, {
					severity: 'error',
				})
			}
		}
	}

	const handleRowDeleteClick = (row: LoanQueue) => {
		setSelectedItem(row)
		setDeleteModalOpen(true)
	}

	const handleRowViewClick = (row: LoanQueue) => {
		setSelectedItem(row)
		setViewModalOpen(true)
	}

	const handleLoanQueueItemFormSubmit = async (
		data: UpdateLoanQueueRequest
	) => {
		if (!selectedItem?.id) {
			return
		}
		try {
			await updateMutation.mutateAsync({
				loanQueueId: selectedItem.id,
				data,
			})
			await resendMutation.mutateAsync(selectedItem.id)
			setViewModalOpen(false)
			alert(
				formatMessage(
					{ id: 'alerts.save_success' },
					{ name: formatMessage({ id: 'global.retry' }) }
				),
				{
					severity: 'success',
				}
			)
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanQueue, { loanId }],
			})
		} catch (err) {
			if (err instanceof AxiosError) {
				alert(
					err?.response?.data?.message ||
						formatMessage({ id: 'errors.general' }),
					{ severity: 'error' }
				)
			} else {
				alert(formatMessage({ id: 'errors.general' }), {
					severity: 'error',
				})
			}
		}
	}

	const viewableTypes = ['New', 'Append', 'Update', 'FieldUpdates']

	const actionItems: ActionItem<LoanQueue>[] = [
		{
			name: formatMessage({ id: 'global.view' }),
			onClick: (
				e: React.MouseEvent<Element, MouseEvent>,
				row: LoanQueue
			) => handleRowViewClick(row),
			hideIf: (row: LoanQueue) =>
				!isAdmin || !viewableTypes.includes(row.type),
		},
		{
			name: formatMessage({ id: 'global.resend' }),
			onClick: (
				e: React.MouseEvent<Element, MouseEvent>,
				row: LoanQueue
			) => handleRowResendClick(row),
		},
		{
			name: formatMessage({ id: 'global.delete' }),
			onClick: (
				e: React.MouseEvent<Element, MouseEvent>,
				row: LoanQueue
			) => handleRowDeleteClick(row),
			hideIf: () => !isAdmin,
		},
	]

	useEffect(() => {
		let changedFilters = filters

		if (filters.type === 'all') {
			changedFilters = { ...changedFilters, type: undefined }
		}
		if (filters.reason === 'all') {
			changedFilters = { ...changedFilters, reason: undefined }
		}
		if (filters.status === 'all') {
			changedFilters = { ...changedFilters, status: undefined }
		}
		setSearchCriteria((prev) => ({ ...prev, ...changedFilters }))
	}, [filters])

	const handleViewModalClose = () => {
		setViewModalOpen(false)
	}

	const deleteItem = async () => {
		if (!selectedItem) return
		try {
			await deleteMutation.mutateAsync(selectedItem?.id)
			alert(
				formatMessage(
					{ id: 'alerts.delete_success' },
					{
						name: formatMessage({
							id: 'loan_queue.loan_queue_record',
						}),
					}
				),
				{
					severity: 'success',
				}
			)
			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanQueue, { loanId }],
			})
		} catch (err) {
			if (err instanceof AxiosError) {
				alert(
					err?.response?.data?.message ||
						formatMessage({ id: 'errors.general' }),
					{ severity: 'error' }
				)
			} else {
				alert(formatMessage({ id: 'errors.general' }), {
					severity: 'error',
				})
			}
		}
		setDeleteModalOpen(false)
		setSelectedItem(null)
	}

	return (
		<>
			<Dialog
				open={viewModalOpen}
				onClose={handleViewModalClose}
				title={`${formatMessage({ id: 'global.data' })}`}
				fullWidth
				maxWidth="md"
				scroll="body"
			>
				<LoanQueueItemForm
					loanQueueItemData={selectedItemResult?.data?.data}
					onSubmit={handleLoanQueueItemFormSubmit}
					loading={
						selectedItemIsFetching ||
						updateMutation.isPending ||
						resendMutation.isPending
					}
				/>
			</Dialog>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={deleteModalOpen}
				setRemoveModalVisible={setDeleteModalOpen}
				remove={deleteItem}
				row={selectedItem}
				loading={deleteMutation.isPending}
			/>
			<DataTable<LoanQueue>
				data={result?.data?.rows || []}
				columns={columns}
				progressPending={isFetching}
				fixedHeader
				actionItems={actionItems}
				onSearchChange={handleSearchChange}
				onSort={handleSort}
				searchText={searchText}
				onClearSearchClick={handleClearSearchClick}
				defaultSortFieldId={sortBy}
				defaultSortAsc={sortDirection === 'asc'}
				onChangePage={handlePageChange}
				onChangeRowsPerPage={handleRowsPerPageChange}
				pagination
				expandableRowsComponent={LoanQueueExpandedRow}
				expandableRows
				paginationTotalRows={result?.data?.count}
				paginationServer
				title={title}
				onRefreshClick={refetch}
				refreshing={
					isRefetching ||
					resendMutation.isPending ||
					deleteMutation.isPending
				}
				searchFilters={
					<LoanQueueSearchFilters
						handleFilterChange={handleFilterChange}
						searchCriteria={filters}
					/>
				}
			/>
		</>
	)
}

export default LoanQueueList
