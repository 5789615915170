import type {
	FieldValues,
	UseFormProps,
	UseFormReturn,
} from 'react-hook-form'
import { useForm as reactHookUseForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

export type ExtendedUseFormProps<
	T extends FieldValues = FieldValues,
> = UseFormProps<T> & {
	schema?: yup.AnyObjectSchema
}

export type ExtendedUseFormReturn<
	T extends FieldValues = FieldValues,
> = UseFormReturn<T> & {
	schema?: yup.AnyObjectSchema
}

const useForm = <T extends FieldValues>({
	schema,
	mode = 'onBlur',
	reValidateMode = 'onChange',
	...rest
}: ExtendedUseFormProps<T>): ExtendedUseFormReturn<T> => {
	return reactHookUseForm<T>({
		resolver: schema ? yupResolver(schema) : undefined,
		mode,
		reValidateMode,
		...rest,
	})
}

export default useForm
