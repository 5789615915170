import React from 'react'
import clsx from 'clsx'

export type BadgeProps = {
	label: string
	color: 'info' | 'success' | 'warning' | 'danger'
}

const Badge = ({ label, color }: BadgeProps) => {
	const colorMap: Record<BadgeProps['color'], string> = {
		info: 'bg-blue-500',
		success: 'bg-emerald-400',
		warning: 'bg-orange-400',
		danger: 'bg-red-400',
	}
	return (
		<div
			className={clsx(
				'px-2 py-1 text-white font-bold rounded',
				colorMap[color]
			)}
		>
			{label}
		</div>
	)
}

export default Badge
