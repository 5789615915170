import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
	TextField,
	InputLabel,
	MenuItem,
	FormControl,
	Select,
	Dialog,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Button } from '../Button'
import { navigationLinking } from '../../services/navigation'
import { ModalYesNo } from './ModalYesNo'
import {
	textIsValid,
	getErrorMessage,
	getValidationMessage,
} from '../../services/helper'
import { applyMask } from '../../services/utils'
import { useAlert } from '../../hooks'
import LoadingButton from '../../components/Button/LoadingButton'

export const ModalUser = ({
	questions,
	modal,
	setModal,
	save,
	modalData,
	modalTitle,
}) => {
	const theme = useTheme()
	const { alert } = useAlert()
	const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
	const navigate = useNavigate()
	const [target, setTarget] = useState('')
	const [modalQuestions, setModalQuestions] = useState([])
	const [answers, setAnswers] = useState({})
	const [defaultAnswers, setDefaultAnswers] = useState({})
	const [applicationTitle, setApplicationTitle] = useState('')
	const [validations, setValidations] = useState({ isError: true })
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: "Are you sure you want to leave? You will lose all data you've entered.",
		yesButtonName: 'Leave',
		noButtonName: 'Keep Editing',
	})
	const [isFormValid, setIsFormValid] = useState(false)
	const [loading, setLoading] = useState(false)
	const isEmpty = Object.values(answers).some(
		(v) => v === '' || v === null
	)

	useEffect(() => {
		textIsValid(answers.email, 'email') &&
		textIsValid(answers.phone, 'phone') &&
		textIsValid(answers.firstName, 'alphanumeric') &&
		textIsValid(answers.lastName, 'alphanumeric')
			? setIsFormValid(true)
			: setIsFormValid(false)
	}, [
		isEmpty,
		isFormValid,
		answers.phone,
		answers.email,
		answers.firstName,
		answers.lastName,
	])

	useEffect(() => {
		const mockedQuestions = JSON.parse(JSON.stringify(questions))
		if (modalData?.id)
			setModalQuestions(
				mockedQuestions.map((q) => {
					q.items = q.items.filter((i) => i.fieldId !== 'password')
					return q
				})
			)
		else setModalQuestions(mockedQuestions)

		const target = mockedQuestions[0].qId
		!!mockedQuestions && mockedQuestions.length && setTarget(target)
		if (modalTitle) {
			setApplicationTitle(modalTitle)
		} else if (mockedQuestions[0].title) {
			setApplicationTitle(mockedQuestions[0].title)
		}
		if (modalData) {
			setAnswers(modalData)
			setDefaultAnswers(modalData)
		}
	}, [modal, modalData, modalTitle, questions])

	const handleGroupInputChangeText = (value, question, group) => {
		let newValue = applyMask(value, question.validation)
		setAnswers((answers) => ({
			...answers,
			[question.fieldId]: newValue,
		}))
		handleGroupButtonToggle(newValue, question, group)
	}

	const handleGroupButtonToggle = (value, question, group) => {
		let valid = true

		for (let i in group.items) {
			const item = group.items[i]

			if (item.optional) continue
			let itemValue

			if (item.type === 'row') {
				for (let x in item.items) {
					const child = item.items[x]
					if (child.optional) continue
					itemValue =
						child.fieldId === question.fieldId
							? value
							: answers[child.fieldId] || ''
					if (!textIsValid(itemValue, item.validation)) {
						valid = false
						break
					}
				}
			} else {
				itemValue =
					item.fieldId === question.fieldId
						? value
						: answers[item.fieldId] || ''
				if (!textIsValid(itemValue, item.validation)) {
					valid = false
					break
				}
			}
		}
	}

	const handleGroupPickerValueChange = (value, question, group) => {
		if (value.indexOf && value.indexOf('Select a') === 0) value = null
		if (question.type === 'row') {
			const rowQuestion = question.items.find(
				(item) => item.type === 'picker'
			)
			setAnswers((answers) => ({
				...answers,
				[rowQuestion.fieldId]: value,
			}))
		} else {
			setAnswers((answers) => ({
				...answers,
				[question.fieldId]: value,
			}))
		}

		handleGroupButtonToggle(value, question, group)
	}

	const handleGroupInputBlur = async (event, question) => {
		const value = Object.entries(answers).find(
			(item) => item[0] === question.fieldId
		)
		let message = {}
		message[question.qId] = false

		if (!question.optional) {
			if (
				value === undefined ||
				(!question.validation && value[1].length === 0)
			)
				message[question.qId] = 'This field is required'
			else if (!textIsValid(value[1], question.validation))
				message[question.qId] = 'This field is required'
		}

		setValidations((validations) => ({
			...validations,
			...message,
			isError: !!message,
		}))
	}

	const saveModal = () => {
		setLoading(true)
		save(answers)
			.then(() => {
				yesCloseModals()
				alert(
					`User successfully ${!!modalData ? 'updated' : 'created'}`
				)
				navigate(`/${navigationLinking.AdminUser}`, { replace: true })
			})
			.catch((e) => {
				alert(getErrorMessage(e), { severity: 'error' })
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const closeModal = () => {
		if (_.isEqual(answers, defaultAnswers)) {
			setAnswers({})
			setDefaultAnswers({})
			setValidations({})
			setModal({ data: null, title: '', visible: false })
		} else {
			setModalConfirmation((modalConfirmation) => ({
				...modalConfirmation,
				open: true,
			}))
		}
	}

	const yesCloseModals = () => {
		setModalConfirmation((modalConfirmation) => ({
			...modalConfirmation,
			open: false,
		}))
		setAnswers({})
		setDefaultAnswers({})
		setValidations({})
		setModal({ data: null, title: '', visible: false })
	}

	const replaceVars = (str) => {
		let vars = []
		let re = /{{([^}]+)}}/g
		let text

		while ((text = re.exec(str))) {
			vars.push(text[1])
		}

		vars.forEach((v) => {
			str = str.replace(
				'{{' + v + '}}',
				typeof answers[v] === 'undefined' ? 'undefined' : answers[v]
			)
		})

		return str
	}

	return (
		<>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				modalConfirm={yesCloseModals}
				setModalConfirmation={setModalConfirmation}
			/>
			<Dialog
				open={modal?.visible}
				onClose={closeModal}
				aria-labelledby="responsive-dialog-title"
			>
				<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
					<p className="pb-5 text-xl text-center font-bold">
						{applicationTitle}
					</p>
					{modalQuestions.map((q) => {
						switch (q.type) {
							case 'group':
								return (
									<div
										key={`Group${q.qId}ModalWizardUser`}
										style={
											q.qId !== target
												? { display: 'none' }
												: {
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
													}
										}
									>
										{q?.items &&
											q.items.map((item) => {
												let questionText = replaceVars(item.question)
												switch (item.type) {
													case 'text':
														return (
															<div
																className="mt-2"
																key={item.qId}
																style={
																	q.qId !== target
																		? { display: 'none' }
																		: {}
																}
															>
																<TextField
																	onChange={(e) =>
																		handleGroupInputChangeText(
																			e.target.value,
																			item,
																			q
																		)
																	}
																	onBlur={(e) =>
																		handleGroupInputBlur(e, item, q)
																	}
																	value={answers[item.fieldId] || ''}
																	label={item.label || item.question}
																	placeholder={item.placeholder}
																	error={
																		validations[item.fieldId] &&
																		validations.isError
																	}
																	helperText={
																		validations[item.fieldId]
																	}
																	id={`WizardUser${item.fieldId}GroupTextInput`}
																	inputProps={{
																		maxLength: q.maxLength,
																	}}
																	className="w-52 md:w-72"
																	variant="standard"
																	disabled={
																		!!modalData?.id &&
																		(item.qId === 'email' ||
																			item.qId === 'phone')
																	}
																/>
															</div>
														)
													case 'picker':
														return !!modalData ? null : (
															<div
																key={item.qId}
																style={
																	q.qId !== target
																		? { display: 'none' }
																		: {}
																}
															>
																{item.qId === 'previewPicker' && (
																	<p className="text-lg mb-5 md:mx-10">
																		{answers.questionName}
																	</p>
																)}
																<FormControl
																	key={`WizardUser${item.fieldId}GroupPicker`}
																	variant="standard"
																	sx={
																		smallScreen
																			? { width: 210 }
																			: { width: 290 }
																	}
																>
																	<InputLabel>
																		{item.default || questionText}
																	</InputLabel>
																	<Select
																		value={
																			answers[item.fieldId] || ''
																		}
																		onChange={(v) =>
																			handleGroupPickerValueChange(
																				v.target.value,
																				item,
																				q
																			)
																		}
																		label={item.default}
																	>
																		<MenuItem key="default" value="">
																			{item.default}
																		</MenuItem>
																		{item.options.map(
																			(option, index) => (
																				<MenuItem
																					key={index}
																					value={option.value}
																				>
																					{option.label}
																				</MenuItem>
																			)
																		)}
																	</Select>
																</FormControl>
															</div>
														)
													default:
														return null
												}
											})}
										<div
											className="mt-4"
											style={
												smallScreen
													? {
															width: 210,
															paddingBottom: 10,
															paddingTop: 20,
														}
													: { width: 290, paddingBottom: 10 }
											}
										>
											<LoadingButton
												type="submit"
												text={modalTitle ? 'Update' : 'Create'}
												loading={loading}
												disabled={loading}
												onClick={saveModal}
											/>
										</div>
									</div>
								)

							default:
								return null
						}
					})}
				</div>
			</Dialog>
		</>
	)
}
