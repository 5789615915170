import React from 'react'
import clsx from 'clsx'
import { Card, CardContent } from '@mui/material'

export type LayoutCardProps = {
	className?: string
	children: React.ReactNode
}

const LayoutCard = ({ className, children }: LayoutCardProps) => {
	return (
		<Card className={clsx('', className)}>
			<CardContent>{children}</CardContent>
		</Card>
	)
}

LayoutCard.defaultProps = {
	className: undefined,
}

export default LayoutCard
