import { state as ventiState } from 'venti'
import { getTheme } from '../config'

const theme = getTheme()

export const host =
	process.env.REACT_APP_POS_API_HOST || 'https://api.thebigpos.com'

export const Roles = {
	borrower: 'Borrower', // 0
	loanOfficer: 'LoanOfficer', // 1
	admin: 'Admin', // 2
	superAdmin: 'SuperAdmin', // 3
	realtor: 'Realtor', // 4
	settlementAgent: 'SettlementAgent',
	branchManager: 'BranchManager', // 17
	loanProcessor: 'LoanProcessor', // 6
	loanOfficerAssistant: 'LoanOfficerAssistant', // 7
}

export const SiteTypes = {
	admin: 0,
	corporate: 1,
	branch: 2,
	loanOfficer: 3,
	realtor: 4,
}

export function downloadFormSubmissionFile(
	formSubmissionId: string,
	formSubmissionFileId: string,
	siteConfigurationId: string
) {
	return `${host}/api/form-submissions/${formSubmissionId}/files/${formSubmissionFileId}/download?&accessToken=${ventiState.get(theme.storageKeys.authToken)}&siteConfigurationId=${siteConfigurationId}`
}

export function getLoanDocumentContentUrl({
	loanId,
	documentId,
}: {
	loanId: string
	documentId: string
}) {
	return `${host}/api/los/loan/${loanId}/document/${documentId}/content?contentType=binary&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

export function getLoanDocumentUrl(
	loanId: string,
	documentId: string
) {
	return `${host}/api/loans/${loanId}/documents/${documentId}/download?&accessToken=${ventiState.get(theme.storageKeys.authToken)}`
}

export function getListingOpenHouseFlyerUrl(id: string) {
	return `${host}/api/listings/${id}/open-house-flyer`
}
