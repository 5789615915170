import { useEffect, useState } from 'react'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 172,
		},
	},
}

const remoteFields = [
	'managed_status',
	'device_id',
	'is_supervised',
	'os_version',
	'is_lost_mode_enabled',
	'serial_number',
	'device_type',
	'owned_by',
	'is_removed',
	'product_name',
	'device_name',
	'platform_type',
	'model',
	'customer_name',
	'customer_id',
	'udid',
	'last_contact_time',
	'platform_type_id',
	'device_capacity',
]

export const RemoteInfo = ({ formData, width }) => {
	const [selectedActionName, setSelectedActionName] = useState('')
	const [remoteDeviceDetails, setRemoteDeviceDetails] = useState({})
	const [modalActionConfirmation, setModalActionConfirmation] =
		useState({
			text: 'Are you sure you want to send this action?',
			open: false,
			canBeSaved: true,
		})

	const fetchRemoteData = async () => {
		const deviceDetails = await TheBigPOSApi.getDeviceBySerialNumber(
			formData.serialNumber
		)
		setRemoteDeviceDetails(deviceDetails.data)
	}

	useEffect(() => {
		if (formData?.serialNumber) fetchRemoteData()
	}, [])

	async function handleActionSend() {
		setModalActionConfirmation((current) => ({
			...current,
			open: true,
		}))
	}

	async function sendAction() {
		setModalActionConfirmation((current) => ({
			...current,
			open: false,
		}))
		try {
			await TheBigPOSApi.createDeviceActionBySerialNumber(
				formData.serialNumber,
				selectedActionName
			)

			await fetchRemoteData()
		} catch (error) {
			console.error(error)
		}
	}

	function handleActionChange(event) {
		event.preventDefault()
		setSelectedActionName(event.target.value)
	}

	return (
		<div className="flex w-full">
			<ModalYesNo
				modalConfirm={sendAction}
				setModalConfirmation={setModalActionConfirmation}
				modalConfirmation={modalActionConfirmation}
			/>
			<div className="flex flex-col md:flex-row">
				<div
					className={`${width >= 468 ? 'flex-row' : 'flex-col'} flex w-full items-end flex-wrap`}
				>
					{remoteFields.map((field) => {
						let value
						switch (true) {
							case typeof remoteDeviceDetails[field] === 'boolean':
								value = remoteDeviceDetails[field] ? 'true' : 'false'
								break
							case field.includes('time'):
								value = new Date(
									remoteDeviceDetails[field]
								).toLocaleDateString()
								break
							default:
								value = remoteDeviceDetails[field]
						}
						return (
							<div className={`w-1/4 mt-10`} key={field}>
								<p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm font-bold">
									{field
										.replace('_', ' ')
										.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
											letter.toUpperCase()
										)}
								</p>
								{value}
							</div>
						)
					})}
				</div>
			</div>
			{/*<div className="w-1/2 pr-20 flex">*/}
			{/*  <FormControl className="w-1/2" variant="outlined">*/}
			{/*    <InputLabel id="category-select-label">Actions</InputLabel>*/}
			{/*    <Select*/}
			{/*      id="action-select"*/}
			{/*      label="Action"*/}
			{/*      MenuProps={MenuProps}*/}
			{/*      onChange={handleActionChange}*/}
			{/*      value={selectedActionName}*/}
			{/*    >*/}
			{/*      {remoteDeviceDetails.actions?.map((action) => (*/}
			{/*        <MenuItem*/}
			{/*          name={action.name}*/}
			{/*          value={action.name}*/}
			{/*          key={action.localized_name}*/}
			{/*        >*/}
			{/*          {action.localized_name}*/}
			{/*        </MenuItem>*/}
			{/*      ))}*/}
			{/*    </Select>*/}
			{/*  </FormControl>*/}
			{/*  <div className={`w-1/4 pt-6 pl-20`}>*/}
			{/*    <Button fullWidth variant={"outlined"} onClick={handleActionSend} text={`Send`}>*/}
			{/*      Send*/}
			{/*    </Button>*/}
			{/*  </div>*/}
			{/*</div>*/}
		</div>
	)
	//
	// return (
	//   <div className="flex flex-col md:flex-row">
	//     <div className={`${width >= 468 ? "flex-row" : "flex-col"} flex w-full items-end`}>
	//       <div className={`w-1/4`}>
	//         <p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Device ID</p>
	//         { remoteInfo.deviceId }
	//       </div>
	//       <div className={`w-1/4`}>
	//         <p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Serial Number</p>
	//         { formData.serialNumber }
	//       </div>
	//       <div className={`w-1/4`}>
	//         <p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Status</p>
	//         { formData.status }
	//       </div>
	//       <div className={`w-1/4`}>
	//         <p className="mt-5 md:mt-0 mb-2.5 font-rubik text-sm">Comments</p>
	//         { formData.comments }
	//       </div>
	//     </div>
	//   </div>
	// )
}
