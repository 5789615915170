import React from 'react'
import Autocomplete, {
	AutocompleteProps,
} from '../../../../components/formControls/Autocomplete'
import { LabelValue } from '../../../../types/LabelValue'

type AutocompleteSearchFilterProps = {
	setFilters: (value: string | null) => void
} & Omit<
	AutocompleteProps<LabelValue>,
	'helperText' | 'required' | 'onChange' | 'error'
>

const AutocompleteSearchFilter = ({
	options,
	setFilters,
	isLoading,
	onInputValueChange,
	label,
	id,
}: AutocompleteSearchFilterProps) => {
	return (
		<Autocomplete<LabelValue>
			id={id}
			label={label}
			options={options || []}
			initialValue={undefined}
			value={undefined}
			getOptionLabel={(option: LabelValue) => option.label}
			renderOption={(props, option: LabelValue) => {
				return (
					<li {...props} key={option.value}>
						{option?.label}
					</li>
				)
			}}
			isLoading={isLoading}
			disabled={false}
			error={false}
			onChange={(response: LabelValue | LabelValue[] | null) => {
				if (Array.isArray(response)) return
				setFilters(response ? response.value : null)
			}}
			onInputValueChange={onInputValueChange}
		/>
	)
}

export default AutocompleteSearchFilter
