export default {
	global: {
		yes: 'Yes',
		no: 'No',
		email: 'Email',
		password: 'Password',
		account: 'Account',
		phone_number: 'Phone number',
		go_back: 'Go back',
		resend: 'Resend',
		view: 'View',
		delete: 'Delete',
		created: 'Created',
		status: 'Status',
		reason: 'Reason',
		type: 'Type',
		details: 'Details',
		username: 'Username',
		retry: 'Retry',
		data: 'Data',
		save: 'Save',
		verify: 'Verify',
		reverify: 'Reverify',
		upload: 'Upload',
		answer: 'Answer',
		close: 'Close',
		publish: 'Publish',
		unpublish: 'Unpublish',
	},
	auth: {
		sign_in: 'Sign In',
		dont_have_an_account: "Don't have an account?",
		check_inbox_for_invite_from_loan_officer:
			'Please check your email inbox for an invite from your Loan Officer',
		forgot_my_password: 'I Forgot My Password',
		existing_user_account_detected: 'Existing user account detected',
		sign_in_to_account: 'Sign in to your account',
		already_have_an_account: 'Already have an account?',
	},
	loans: {
		loan_id: 'Loan ID',
		not_assigned: 'Not Assigned',
	},
	loan_queue: {
		loan_queue: 'Loan Queue',
		loan_queue_record: 'Loan Queue Record',
		type: {
			new_loan: 'New Loan',
			new_cob_loan: 'New Co-Borrower Loan',
			loan_update: 'Update Application',
			field_update: 'Field Update',
			document_upload: 'Document Upload',
			new_buckets: 'New Buckets',
		},
		job_id: 'Job ID',
	},
	users: {
		loan_officer: 'Loan Officer',
	},
	validation: {
		email: {
			required: 'Email is required',
			invalid: 'Email is invalid',
		},
		password: {
			required: 'Password is required',
			minLength: 'Password must be at least {minLength} characters',
			create: 'Create password',
			confirm: 'Confirm password',
		},
		phone: {
			invalid: 'Phone number is invalid',
		},
		name: {
			first_name_invalid: 'First name is invalid',
			last_name_invalid: 'Last name is invalid,',
		},
	},
	errors: {
		general: 'There was an error processing the request',
		error_loading:
			'There was a problem retrieving the information requested',
		invalid_credentials: 'Invalid username and/or password',
		account_doesnt_have_access_to_env:
			'Your account does not have access to this environment',
		error_retrieving_auth: 'Error retrieving authentication info',
		account_already_exists:
			'An account with this email address already exists',
		unexpected_error: 'An unexpected error has occured.',
	},
	landing: {
		mortgage_rates: 'Mortgage rates',
		how_to_apply: 'How would you like to apply?',
		apply_now: 'Apply Now',
		what_would_you_like_today: 'What would you like to do today?',
		check_out_todays_rates: "Check out today's rates",
		get_your_rate: 'Get your rate',
		customer_testimonials: 'Customer Testimonials',
	},
	legal: {
		website: 'Website',
		contact_us: 'Contact us',
		privacy_policy: 'Privacy Policy',
		terms: 'Terms of Use',
		license_info: 'License Information',
	},
	create_account: {
		title: 'Create Account',
		create_account: 'Create an account',
		please_wait: 'Please wait while we fetch your invitation',
		invite_expired: 'The invite has expired',
		request_new: 'Please request a new invite from your Loan Officer',
		first_name: 'First name',
		last_name: 'Last name',
		sign_up: 'Sign up',
	},
	mortgage: {
		fixed_15_years: 'Fixed 15 Years',
		conventional_30_years: 'Conventional 30 Years',
		todays_rates: "Today's rates",
		fixed: 'Fixed',
		conventional: 'Conventional',
	},
	alerts: {
		save_success: 'The {name} successfully updated',
		sent_to_los: 'The loan draft was successfully sent to LOS',
		delete_success: 'The {name} successfully deleted',
		pop_up_blocker_enabled:
			'Pop-up Blocker is enabled! Please add this site to your exception list and try again.',
	},
	task_verification: {
		default_button_title: 'Verify Me',
		manual_upload_button_title: 'Manual Upload',
		loan_locked_notice:
			'Your loan is currently in use. Once it is updated, your verification messages will be sent.',
		success:
			'Success! Please check your SMS or email inbox for next steps.',
		task_verification_copy_by_type: {
			VerificationOfAssets: {
				heading: 'Verify Your Financial Assets',
				body: `Click "Start Verification" to receive a text message and email that will guide you through verifying your assets. These messages will help you sign into your online banking account to select the accounts you'd like to share. Alternatively, you can upload your bank statements manually.`,
				button_title: `Start Verification`,
			},
			VerificationOfIncome: {
				heading: 'Verify Your Income',
				body: `Click "Start Verification" to receive a text message and email that will guide you through verifying your INCOME. These messages will help you sign into your online banking account to select the accounts you'd like to share. Alternatively, you can upload your bank statements manually.`,
				button_title: `Start Verification`,
			},
			VerificationOfEmployment: {
				heading: 'Verify Your Employment',
				body: `Click "Start Verification" to receive a text message and email that will guide you through verifying your EMPLOYMENT. These messages will help you sign into your online banking account to select the accounts you'd like to share. Alternatively, you can upload your bank statements manually.`,
				button_title: `Start Verification`,
			},
			VerificationOfIncomeAndEmployment: {
				heading: 'Verify Your Income & Employment',
				body: `Click "Start Verification" to receive a text message and email that will guide you through verifying your INCOME & EMPLOYMENT. These messages will help you sign into your online banking account to select the accounts you'd like to share. Alternatively, you can upload your bank statements manually.`,
				button_title: `Start Verification`,
			},
		},
	},
	task_form: {
		weight_helper_text:
			'Weight is used for sorting. The lower the number, the higher it will display in the list.',
		weight_label: 'Weight',
	},
	templates: {
		delete_version_prompt:
			'Are you sure you want to delete this version?',
		delete_version: 'Delete Version',
		delete_selected_version: 'Delete selected version',
		save_as_new_version: 'Save as new version',
	},
	remove_record: {
		permanent_deletion: 'Permanent Deletion',
		permanent_delete:
			'Permanently Delete "{value}" & All Associated Data?',
		delete: 'Delete',
		cancel: 'Cancel',
		confirm_delete: 'Confirm Delete',
		confirm_permanent_deletion: 'Confirm Permanent Deletion',
		confirm_reactivate: 'Confirm Reactivation',
		remove: 'Remove',
		restore: 'Restore',
		confirm_deletion: 'Yes, Remove It',
		confirm_restore: 'Yes, Restore It',
	},
	warnings: {
		no_revert_possible: 'WARNING: THIS CAN NOT BE UNDONE',
		accept_no_revert_possible:
			'By checking this box I confirm that I understand that this action can not be undone.',
	},
	modals: {
		confirm_send_to_los:
			'Are you sure you want to send this to the LOS?',
	},
	drafts: {
		send_to_los: 'Send to LOS',
	},
	sso: {
		sso_integrations: 'SSO Integrations',
		download_cc_metadata_file: 'Download Consumer Connect Metadata',
		download_bpos_metadata_file: 'Download POS Metadata',
	},
}
