import {
	Button as ButtonMUI,
	ButtonProps as MuiButtonProps,
} from '@mui/material'
import React from 'react'
import clsx from 'clsx'

export type ButtonProps = {
	text: string
} & MuiButtonProps

export const Button = ({
	text,
	variant = 'contained',
	style,
	...rest
}: ButtonProps) => (
	<ButtonMUI
		variant={variant}
		style={{
			textTransform: 'none',
			fontSize: '1rem',
			paddingBottom: '0.25rem',
			...style,
		}}
		{...rest}
		className={clsx(
			variant === 'outlined' ? 'dark:!text-white' : '',
			rest.className
		)}
	>
		{text}
	</ButtonMUI>
)
