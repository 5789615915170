import React, { useMemo, useState } from 'react'

import {
	Autocomplete as MUIAutocomplete,
	CircularProgress,
	TextField,
	AutocompleteProps as MUIAutocompleteProps,
} from '@mui/material'

export type AutocompleteProps<T> = {
	label: string
	error?: boolean
	helperText?: string
	required?: boolean
	isLoading: boolean
	initialValue?: T | null
	value?: string
	onChange: (value: T | T[] | null) => void
	onInputValueChange: (value: string | null) => void
} & Omit<
	MUIAutocompleteProps<T, boolean, undefined, undefined>,
	'onChange' | 'value' | 'renderInput'
>

const Autocomplete = <T,>({
	onChange,
	error,
	helperText,
	required = false,
	label,
	initialValue,
	isLoading,
	onInputValueChange,
	value: inputInitialValue,
	...rest
}: AutocompleteProps<T>) => {
	const [value, setValue] = useState<T | T[] | null>(
		initialValue || null
	)
	const [inputValue, setInputValue] = useState<string | undefined>(
		inputInitialValue
	)

	const labelTxt = useMemo(
		() => (required ? `${label} *` : label),
		[required, label]
	)

	const handleChange = (
		event: React.SyntheticEvent<Element, Event>,
		newValue: T | T[] | null
	) => {
		setValue(newValue)
		if (onChange && value !== newValue) {
			onChange(newValue)
		}
	}

	const handleInputChange = (
		event: React.SyntheticEvent,
		newValue: string | null
	) => {
		setInputValue(newValue || '')
		if (onInputValueChange && value !== newValue) {
			onInputValueChange(newValue)
		}
	}

	return (
		<MUIAutocomplete
			{...rest}
			value={value}
			loading={isLoading}
			onChange={handleChange}
			onInputChange={handleInputChange}
			inputValue={inputValue}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="standard"
					label={labelTxt}
					error={error}
					helperText={error ? 'Error loading the data' : helperText}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{isLoading ? (
									<CircularProgress color="inherit" size={18} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	)
}

export default Autocomplete
