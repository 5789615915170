import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { UserLoanTask } from '@matech/thebigpos-sdk'
import { Button, LoadingBtn } from '../../../../components/Button'
import LoanTaskDocumentForm, {
	LoanTaskDocumentFormFieldValues,
} from '../forms/LoanTaskDocumentForm'
import { eventTypes } from '../../../../services/constants'
import { getErrorMessage } from '../../../../services/helper'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { useAlert } from '../../../../hooks'
import useUser from '../../../../hooks/useUser'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { isTaskTypeVerification } from '../helpers'
import { useLoanContext } from '../../../../contexts/LoanContext'

export type VerificationProps = {
	onSubmit: (data?: LoanTaskDocumentFormFieldValues) => void
	onCancel?: () => void
	loanTask: UserLoanTask
}
export const Verification = ({
	onSubmit,
	onCancel,
	loanTask,
}: VerificationProps) => {
	const { task } = loanTask
	const { loan } = useLoanContext()

	const mixpanel = useMixpanel()
	const { alert } = useAlert()
	const { isLoanOfficer } = useUser()
	const { formatMessage } = useIntl()

	const [isSuccess, setIsSuccess] = useState(false)

	const isVerificationAndLoanOfficer =
		isLoanOfficer && isTaskTypeVerification(task.type)
	const [showUploadForm, setShowUploadForm] = useState(
		isVerificationAndLoanOfficer
	)

	const verifyMutation = useMutation({
		mutationFn: async (data: {
			loanID: string
			loanTaskId: string
		}) =>
			TheBigPOSApi.createLoanTaskVerification(
				data.loanID,
				data.loanTaskId
			),
	})

	const handleManualUploadClick = () => {
		setShowUploadForm(true)
	}

	const handleConfirmationModalOpen = () => {
		setIsSuccess(true)
	}

	const handleVerificationClick = async () => {
		try {
			await verifyMutation.mutateAsync({
				loanTaskId: loanTask.id,
				loanID: loanTask.loanID,
			})

			mixpanel.trackEvent(eventTypes.TASK_VERIFICATION_REQUEST, {
				loanId: loanTask.loanID,
			})

			handleConfirmationModalOpen()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const successMessage = formatMessage({
		id: loan?.isLocked
			? 'task_verification.loan_locked_notice'
			: 'task_verification.success',
	})

	const handleCloseVerificationClick = () => {
		onSubmit()
		if (onCancel) {
			onCancel()
		}
	}

	return (
		<div>
			<div className="flex flex-row py-5 pr-5 justify-center items-center">
				<div>
					{isSuccess ? (
						<div className="text-center">
							<p>
								{formatMessage({
									id: successMessage,
								})}
							</p>
						</div>
					) : (
						<div>
							<h3 className="text-center font-bold text-lg mb-2">
								{!!task.type &&
									formatMessage({
										id: `task_verification.task_verification_copy_by_type.${task.type}.heading`,
									})}
							</h3>
							<p>
								{!!task.type &&
									formatMessage({
										id: `task_verification.task_verification_copy_by_type.${task.type}.body`,
									})}
							</p>
						</div>
					)}
				</div>
			</div>
			{showUploadForm && !isSuccess && (
				<div className="mt-3">
					<LoanTaskDocumentForm
						onSubmit={onSubmit}
						onCancel={onCancel}
					/>
				</div>
			)}
			{!showUploadForm && !isSuccess && (
				<div className="grid grid-cols-2 gap-2">
					<Button
						text={formatMessage({
							id: 'task_verification.manual_upload_button_title',
						})}
						onClick={handleManualUploadClick}
						variant="outlined"
					/>
					<LoadingBtn
						text={formatMessage({
							id: task.type
								? `task_verification.task_verification_copy_by_type.${task.type}.button_title`
								: 'task_verification.default_button_title',
						})}
						onClick={handleVerificationClick}
						loading={verifyMutation.isPending}
					/>
				</div>
			)}
			{isSuccess && (
				<div className="flex flex-row justify-center items-center">
					<Button
						text={formatMessage({ id: 'global.close' })}
						onClick={handleCloseVerificationClick}
					/>
				</div>
			)}
		</div>
	)
}
