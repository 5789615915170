import React from 'react'
import LoanPage from '../../../components/LoanPage'
import LoanCalculatorContent from './LoanCalculatorContent'
import withAuth from '../../../components/withAuth'

const LoanCalculator = () => {
	return (
		<LoanPage
			page="loan-calculator"
			title="Loan Calculator"
			isFullWidth
		>
			<LoanCalculatorContent />
		</LoanPage>
	)
}

export default withAuth(LoanCalculator)
