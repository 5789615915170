import React from 'react'
import LoanPage from '../../../components/LoanPage'
import withAuth from '../../../components/withAuth'
import TasksContent from './TasksContent'

const LoanDocuments = () => {
	return (
		<LoanPage page="tasks" title="Tasks" isFullWidth>
			<TasksContent className="overflow-auto" />
		</LoanPage>
	)
}

export default withAuth(LoanDocuments)
