import React from 'react'
import TextField, { TextFieldProps } from './TextField'

export type NumberFieldProps = Omit<TextFieldProps, 'type'>

const NumberField = (props: TextFieldProps) => {
	return <TextField type="number" {...props} />
}

export default NumberField
