import { Dialog, DialogContent } from '@mui/material'
import { SiteConfigurationByUrl } from '@matech/thebigpos-sdk'
import React from 'react'
import { LoadingDots } from '../LoadingDots'
import { useAppContext } from '../AppContext'
import { isDarkMode } from '../../services/helper'

export type ModalPleaseWaitProps = {
	visible?: boolean
	message?: string
}

export const ModalPleaseWait = ({
	visible = false,
	message = '',
}: ModalPleaseWaitProps) => {
	const {
		state: { siteConfig },
	} = useAppContext() as {
		state: { siteConfig: SiteConfigurationByUrl | null }
	}

	return (
		<Dialog open={visible} aria-labelledby="responsive-dialog-title">
			{siteConfig?.logoUrl && (
				<img
					src={
						isDarkMode() && siteConfig.darkModeLogoUrl
							? siteConfig.darkModeLogoUrl
							: siteConfig.logoUrl
					}
					alt="Company Logo"
					className="px-5 sm:h-2/5 sm:px-10 pt-10"
				/>
			)}
			<DialogContent>
				{!!message && (
					<div className="flex justify-center w-full mt-2">
						<p className="text-sm sm:text-base dark:text-white">
							{message}
						</p>
					</div>
				)}
				<div className="flex justify-center w-full">
					<LoadingDots style={{ height: 70 }} />
				</div>
			</DialogContent>
		</Dialog>
	)
}
