import React, { LabelHTMLAttributes } from 'react'
import clsx from 'clsx'

export type FieldLabelProps = {
	label: string
	required?: boolean
} & LabelHTMLAttributes<HTMLLabelElement>

const FieldLabel = ({
	label,
	required = false,
	className,
	children,
	...rest
}: FieldLabelProps) => {
	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label
			className={clsx('text-xs font-rubik', className)}
			{...rest}
		>
			{label}
			{required ? '*' : ''}
			{children}
		</label>
	)
}

export default FieldLabel
