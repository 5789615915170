import React from 'react'
// @ts-ignore
import loadingDots from 'assets/loadingDots.gif'

type LoadingDotsProps = {
	style?: React.CSSProperties
	className?: string
}

const LoadingDots = ({ style, className }: LoadingDotsProps) => (
	<img
		src={loadingDots}
		alt="Loading dots"
		style={style}
		className={className}
	/>
)

/**
 * @deprecated use the default export instead
 */
export { LoadingDots }

export default LoadingDots
