import React from 'react'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import queryKeys from '../../../../services/queryKeys'
import { Button } from '../../../../components/Button'
import ModalImportDiffTasks from './ModalImportDiffTasks'
import { eventTypes } from '../../../../services/constants'
import { useAlert } from '../../../../hooks'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { getErrorMessage } from '../../../../services/helper'
import { InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import AlertBox from '../../../../components/AlertBox'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'

const LoanTaskDiffAlert = ({
	className = undefined,
	loanId,
	borrowers,
	onSubmit,
}) => {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()
	const queryClient = useQueryClient()

	const [modalOpen, setModalOpen] = React.useState(false)

	const { data: tasks, refetch } = useQuery({
		queryKey: [queryKeys.loanTasksDiff, loanId],
		queryFn: () => TheBigPOSApi.getLoanTaskDifference(loanId),
	})

	const importLoanTaskMutation = useMutation({
		mutationFn: async (data) =>
			TheBigPOSApi.importLoanTask(loanId, data),
	})

	const handleImportClick = () => {
		setModalOpen(true)
	}

	const handleSubmit = async (importedTasks) => {
		try {
			const request = []
			importedTasks.forEach((t) => {
				if (t.targetUserRole === 'Borrowers') {
					borrowers.forEach((u) => {
						request.push({
							taskId: t.id,
							userId: u.id,
						})
					})
				} else {
					const user = borrowers.find(
						(b) => b.role === t.targetUserRole
					)
					if (user) {
						request.push({
							taskId: t.id,
							userId: user.id,
						})
					}
				}
			})

			await importLoanTaskMutation.mutateAsync(request)
			alert('Your tasks have been imported!', { severity: 'success' })
			mixpanel.trackEvent(eventTypes.LOAN_TASKS_IMPORTED)

			await queryClient.invalidateQueries({
				queryKey: [queryKeys.loanTasks, loanId],
			})
			await refetch()
			onSubmit()
			handleClose()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleClose = () => {
		setModalOpen(false)
	}

	if (!tasks?.data || tasks?.data.length === 0) {
		return null
	}

	return (
		<>
			<ModalImportDiffTasks
				open={modalOpen}
				tasks={tasks?.data || []}
				loanId={loanId}
				onSubmit={handleSubmit}
				onClose={handleClose}
			/>
			<AlertBox className={className} severity="warning">
				<div className="md:flex md:flex-row md:items-center md:justify-between">
					<h4 className="flex flex-row items-center">
						You have {tasks.data.length} tasks matching business rules
						that aren't on this loan.
						<Tooltip title="This can happen if your loan changes and matches different business rules or your administrator updates business rules after the loan is created.">
							<InfoOutlined className="ml-2" />
						</Tooltip>
					</h4>
					<div className="mt-2 md:mt-0">
						<Button
							text="View Tasks"
							color="warning"
							variant="outlined"
							onClick={handleImportClick}
						/>
					</div>
				</div>
			</AlertBox>
		</>
	)
}

export default LoanTaskDiffAlert
