import React from 'react'
import Form from '../components/formControls/Form'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { Switch, TextField } from '../components/formControls'
import { LoadingBtn } from '../components/Button'
import { Button } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'

const NewVersionForm = ({
	onSubmit,
	onCancel = null,
	loading = false,
	codeEditorName,
	templateType,
}) => {
	const schema = yup.object().shape({
		name: yup.string().required('Name is required'),
	})

	const { control: templateFormControl } = useFormContext()

	let plainBody,
		textBody = ''
	const code = useWatch({
		templateFormControl,
		name: codeEditorName,
	})

	if (templateType === 'notification') {
		plainBody = useWatch({
			templateFormControl,
			name: 'plainBody',
		})
		textBody = useWatch({
			templateFormControl,
			name: 'textBody',
		})
	}

	const form = useForm({
		schema,
		values: {
			name: '',
			isActive: false,
			code: codeEditorName ? code : '',
			plainBody,
			textBody,
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const {
		handleSubmit,
		formState: { errors },
		control,
	} = form

	const submitForm = async (data) => {
		await onSubmit(data)
	}

	return (
		<Form onSubmit={handleSubmit(submitForm)}>
			<div className="mb-3">
				<TextField
					name="name"
					label="Name"
					className="w-full"
					placeholder="Enter name"
					control={control}
					errors={errors}
					fullWidth
				/>
			</div>
			<div className="mb-3">
				<Switch
					name="isActive"
					label="Set Active"
					control={control}
					errors={errors}
				/>
			</div>
			<div className="flex flex-row justify-between mt-5">
				{onCancel && (
					<div className="mr-2">
						<Button onClick={onCancel} variant="outlined">
							Cancel
						</Button>
					</div>
				)}
				<LoadingBtn
					disabled={loading}
					loading={loading}
					fullWidth={false}
					type="submit"
					text="Save"
				/>
			</div>
		</Form>
	)
}

export default NewVersionForm
