import React from 'react'
import { isDarkMode } from '../../services/helper'
import GenerateLoanDocumentForm, {
	GenerateLoanDocumentFormFieldValues,
} from '../../forms/GenerateLoanDocumentForm'
import Dialog, { type DialogProps } from './Dialog'
import { useSiteConfig } from '../../hooks'

export type ModalGenerateLoanDocumentSelectorProps = {
	onSubmit: (id: string) => void
	onClose: () => void
	type: string
} & Omit<DialogProps, 'onSubmit'>

const ModalGenerateLoanDocumentSelector = ({
	onSubmit,
	onClose,
	type,
	...rest
}: ModalGenerateLoanDocumentSelectorProps) => {
	const { siteConfig } = useSiteConfig()

	const handleSubmit = (
		data: GenerateLoanDocumentFormFieldValues
	) => {
		if (!data?.customDocumentTemplateId) {
			return
		}

		onSubmit(data.customDocumentTemplateId)
	}

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
			{...rest}
		>
			<div className="max-w-96 mx-auto">
				{siteConfig?.logoUrl && (
					<img
						alt={`${siteConfig.companyName} logo`}
						className="w-full"
						src={
							isDarkMode() && siteConfig.darkModeLogoUrl
								? siteConfig.darkModeLogoUrl
								: siteConfig.logoUrl
						}
					/>
				)}
			</div>
			<div className="text-center mt-5 mb-4 font-bold text-lg">
				Which Document Template would you like to apply?
			</div>
			<div className="w-full">
				<GenerateLoanDocumentForm
					type={type}
					onCancel={onClose}
					onSubmit={handleSubmit}
				/>
			</div>
		</Dialog>
	)
}

export default ModalGenerateLoanDocumentSelector
