import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from 'react'
import {
	BranchUser,
	LoanDraftSearchCriteria,
	SiteConfigurationSummary,
} from '@matech/thebigpos-sdk'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../../services/queryKeys'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import AutocompleteSearchFilter from './AutocompleteSearchFilter'
import { LabelValue } from '../../../../types/LabelValue'

type SearchFiltersLoanDraftsTabProps = {
	setFilters: Dispatch<SetStateAction<LoanDraftSearchCriteria>>
}

const SearchFiltersLoanDraftsTab = ({
	setFilters,
}: SearchFiltersLoanDraftsTabProps) => {
	const [loanOfficerSearch, setLoanOfficerSearch] = useState('')
	const [siteConfigSearch, setSiteConfigSearch] = useState('')
	const paginationParams = { pageSize: 25, pageNumber: 1 }

	const siteConfigSearchCriteria = {
		searchText: siteConfigSearch,
		isActive: true,
	}
	const loanOfficerSearchCriteria = {
		searchText: loanOfficerSearch,
		isActive: true,
	}

	const { data: siteConfigResult, isLoading: siteConfigIsLoading } =
		useQuery({
			queryKey: [
				queryKeys.siteConfigurations,
				siteConfigSearchCriteria,
			],
			queryFn: () =>
				TheBigPOSApi.searchSiteConfigurations(
					siteConfigSearchCriteria,
					paginationParams
				),
		})

	const { data: loanOfficersResult, isLoading: loIsLoanding } =
		useQuery({
			queryKey: [queryKeys.loanOfficers, loanOfficerSearchCriteria],
			queryFn: () =>
				TheBigPOSApi.searchLoanOfficers(
					loanOfficerSearchCriteria,
					paginationParams
				),
		})

	const onLOInputValueChange = (value: string | null) =>
		setLoanOfficerSearch(value || '')

	const onSiteConfigInputValueChange = (value: string | null) =>
		setSiteConfigSearch(value || '')

	const [loResult, setLOResult] = useState<LabelValue[] | null>(null)
	const [siteConfigModelResult, setSiteConfigResult] = useState<
		LabelValue[] | null
	>(null)

	useEffect(() => {
		if (!siteConfigResult?.data) setSiteConfigResult(null)
		else
			setSiteConfigResult(
				siteConfigResult?.data?.rows.map(
					(item: SiteConfigurationSummary) =>
						({
							value: item.id,
							label: item.name,
						}) as LabelValue
				)
			)
	}, [siteConfigResult])

	useEffect(() => {
		if (!loanOfficersResult?.data) setLOResult(null)
		else
			setLOResult(
				loanOfficersResult?.data?.rows.map(
					(item: BranchUser) =>
						({
							value: item.id,
							label: `${item.firstName} ${item.lastName}`,
						}) as LabelValue
				)
			)
	}, [loanOfficersResult])

	const loanOfficerOptions = [
		{ label: 'All', value: 'all' },
		{ label: 'Unassigned', value: 'unassigned' },
		...(loResult || []),
	]

	const siteConfigurationOptions = [
		{ label: 'All', value: 'all' },
		...(siteConfigModelResult || []),
	]

	return (
		<>
			<AutocompleteSearchFilter
				onInputValueChange={onSiteConfigInputValueChange}
				id="site-configuration-autocomplete"
				label="Site"
				options={siteConfigurationOptions || []}
				isLoading={siteConfigIsLoading}
				setFilters={(value: string | null) =>
					setFilters((prev) => ({
						...prev,
						SiteConfigurationSummaryId: value,
					}))
				}
			/>
			<AutocompleteSearchFilter
				onInputValueChange={onLOInputValueChange}
				id="loan-officer-autocomplete"
				label="Loan officer"
				options={loanOfficerOptions || []}
				isLoading={loIsLoanding}
				setFilters={(value: string | null) =>
					setFilters((prev) => ({ ...prev, loanOfficerId: value }))
				}
			/>
		</>
	)
}

export default SearchFiltersLoanDraftsTab
