import React from 'react'

export type InnerPageProps = {
	children: React.ReactNode
}

const InnerPage = ({ children }: InnerPageProps) => {
	return <div className="pl-5 pr-5 pb-20 pt-5">{children}</div>
}
export default InnerPage
