import React from 'react'
import { LoanQueue } from '@matech/thebigpos-sdk'
import { useIntl } from 'react-intl'

export type LoanQueueTypeLabelProps = {
	type: LoanQueue['type']
}

const LoanQueueTypeLabel = ({ type }: LoanQueueTypeLabelProps) => {
	const { formatMessage } = useIntl()

	const typeMap: Record<string, string> = {
		New: formatMessage({ id: 'loan_queue.type.new_loan' }),
		Append: formatMessage({ id: 'loan_queue.type.new_cob_loan' }),
		Update: formatMessage({ id: 'loan_queue.type.loan_update' }),
		FieldUpdates: formatMessage({
			id: 'loan_queue.type.field_update',
		}),
		Document: formatMessage({
			id: 'loan_queue.type.document_upload',
		}),
		Buckets: formatMessage({ id: 'loan_queue.type.new_buckets' }),
	}
	return <span>{typeMap[type] || 'Unknown'}</span>
}

export default LoanQueueTypeLabel
