// NPM packages
import React from 'react'

// Components
import { Form, FormRow } from '../../../../components/formControls'
import { LoadingBtn as LoadingButton } from '../../../../components/Button'

// Services
import useForm from '../../../../hooks/useForm'
import { ErrorListItemProps } from '../../../../components/ErrorList'

export type TextOptInFormProps = {
	textOptInStatus: boolean
	onSubmit: () => Promise<void>
	apiErrors?: ErrorListItemProps[]
}

const TextOptInForm = ({
	textOptInStatus,
	onSubmit,
	apiErrors,
}: TextOptInFormProps) => {
	const {
		handleSubmit,
		formState: { isSubmitting },
	} = useForm({})

	const handleFormSubmit = async () => {
		await onSubmit()
	}
	return (
		<Form
			onSubmit={handleSubmit(handleFormSubmit)}
			errors={apiErrors}
		>
			<FormRow>
				{textOptInStatus ? (
					<p>You are opting out of text message notifications.</p>
				) : (
					<p>
						By clicking the &quot;I agree&quot; button below, I give
						MTG Alerts my express written consent to send me text
						message notifications. Message and data rates may apply. I
						understand that my consent is voluntary and not a
						condition of purchase. I may text STOP to any message to
						cancel further text communications. Carriers are not
						liable for delayed or undelivered messages.
						<br />
						<br />
						For more information, please read our privacy policy (
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.thebigpos.com/privacy-policy/"
						>
							https://www.thebigpos.com/privacy-policy/
						</a>
						) and terms of use (
						<a
							target="_blank"
							rel="noreferrer"
							href="https://www.thebigpos.com/terms-of-use/"
						>
							https://www.thebigpos.com/terms-of-use/
						</a>
						)
					</p>
				)}
			</FormRow>
			<FormRow className="mt-10">
				<LoadingButton
					type="submit"
					text="I agree"
					loading={isSubmitting}
					disabled={isSubmitting}
				/>
			</FormRow>
		</Form>
	)
}

export default TextOptInForm
