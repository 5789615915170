import React from 'react'
// import type { KeyboardEvent } from 'react'
import ErrorList, { ErrorListItemProps } from '../ErrorList'

export type FormProps = {
	errors?: ErrorListItemProps[]
	children: React.ReactNode
	onSubmit?: () => void
} & React.HTMLProps<HTMLFormElement>

const Form = ({ errors, children, onSubmit, ...rest }: FormProps) => {
	// @todo check if this fires automatically. if so remove
	// const onKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
	// 	if (onSubmit && event.key === 'Enter') {
	// 		onSubmit()
	// 	}
	// }

	return (
		<form
			{...rest}
			onSubmit={onSubmit}
			// onKeyDown={onKeyDown}
			noValidate
		>
			<ErrorList className="my-3" errors={errors} />
			{children}
		</form>
	)
}

export default Form
