import React, { useState } from 'react'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import {
	Corporate,
	CorporateSearchCriteria,
} from '@matech/thebigpos-sdk'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import queryKeys from '../../../services/queryKeys'
import Autocomplete from '../../../components/formControls/Autocomplete'

type BranchesSearchFiltersProps = {
	handleFilterChange: (
		value: string | undefined,
		field: string
	) => void
	filters: {
		brand: null | string
		type: null | string
	}
}

const BranchesSearchFilters = ({
	handleFilterChange,
	filters,
}: BranchesSearchFiltersProps) => {
	const [corporateSearch, setCorporateSearch] = useState('')
	const corporateSearchCriteria: CorporateSearchCriteria = {
		searchText: corporateSearch,
		isActive: true,
	}

	const {
		error: errorFetchingCorporations,
		data: corporationsResult,
		isFetching,
		isRefetching,
	} = useQuery({
		queryKey: [queryKeys.corporations, corporateSearchCriteria],
		queryFn: () =>
			TheBigPOSApi.searchCorporate(corporateSearchCriteria),
	})

	const onCorporateInputValueChange = (value: string | null) =>
		setCorporateSearch(value || '')

	return (
		<>
			<FormControl id="filterType" variant="standard">
				<InputLabel>Type</InputLabel>
				<Select
					value={filters.type || 'all'}
					onChange={(e) => handleFilterChange(e.target.value, 'type')}
				>
					<MenuItem value="all">All</MenuItem>
					<MenuItem value="Mortgage">Mortgage</MenuItem>
					<MenuItem value="RealEstate">Real Estate</MenuItem>
				</Select>
			</FormControl>
			<Autocomplete<Corporate>
				options={corporationsResult?.data?.rows || []}
				initialValue={undefined}
				value={undefined}
				getOptionLabel={(option: Corporate) => option?.name}
				renderOption={(props, option: Corporate) => {
					return (
						<li {...props} key={option.id}>
							{option?.name}
						</li>
					)
				}}
				isLoading={isFetching || isRefetching}
				disabled={false}
				error={errorFetchingCorporations != null}
				onChange={(response: Corporate | Corporate[] | null) => {
					if (Array.isArray(response)) return
					handleFilterChange(response?.id, 'brand')
				}}
				onInputValueChange={onCorporateInputValueChange}
				id="corporation-autocomplete"
				label="Brand"
			/>
		</>
	)
}

export default BranchesSearchFilters
