import React from 'react'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'
import { TheBigPOSApi } from '../lib/TheBigPOSClient'
import { Button } from '../components/Button'
import { Form, FormRow, Select } from '../components/formControls'
import useForm from '../hooks/useForm'

export type GenerateLoanDocumentFormFieldValues = {
	customDocumentTemplateId: string
}

export type GenerateLoanDocumentFormProps = {
	type: string
	onCancel: () => void
	onSubmit: (data: GenerateLoanDocumentFormFieldValues) => void
}

const schema = yup.object().shape({
	customDocumentTemplateId: yup
		.string()
		.required('Please select a Document Template'),
})

const GenerateLoanDocumentForm = ({
	type,
	onCancel,
	onSubmit,
}: GenerateLoanDocumentFormProps) => {
	const { data: customDocumentTemplates } = useQuery({
		queryKey: ['customDocumentTemplates', type],
		queryFn: () => TheBigPOSApi.getCustomDocumentTemplates(type),
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<GenerateLoanDocumentFormFieldValues>({
		defaultValues: {
			customDocumentTemplateId: '',
		},
		schema,
	})

	return (
		<div>
			{customDocumentTemplates?.data &&
				customDocumentTemplates.data.length > 0 && (
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormRow>
							<Select
								label="Please select a Document Template"
								name="customDocumentTemplateId"
								menuItems={customDocumentTemplates.data.map(
									(option) => ({
										value: option.id,
										label: option.name,
									})
								)}
								fullWidth
								control={control}
								errors={errors}
							/>
						</FormRow>
						<FormRow className="mt-6 flex flex-col items-center gap-3">
							<Button
								className="!mx-auto"
								fullWidth
								type="submit"
								text="Generate Document"
								style={{ width: 280 }}
							/>
							<Button
								className="!mx-auto"
								fullWidth
								onClick={onCancel}
								text="Cancel"
								style={{ width: 280 }}
								variant="outlined"
							/>
						</FormRow>
					</Form>
				)}
		</div>
	)
}

export default GenerateLoanDocumentForm
