import React, { useEffect } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import { Tooltip } from '@mui/material'
import { Loan } from '@matech/thebigpos-sdk'
import clsx from 'clsx'
import useUser from '../../hooks/useUser'
import { useLoanSignalRContext } from '../../contexts/LoanSignalRContext'
import { useLoanContext } from '../../contexts/LoanContext'

export type LoanLockedAlertProps = {
	className?: string
}

const LoanLockedAlert = ({ className }: LoanLockedAlertProps) => {
	const { loan } = useLoanContext()
	const { isLoanAdmin } = useUser()
	const { useSignalREffect } = useLoanSignalRContext()
	const [locked, setLocked] = React.useState(loan?.isLocked || false)

	useEffect(() => {
		setLocked(loan?.isLocked || false)
	}, [loan])

	const handleLoanSave = (updatedLoan: Loan) => {
		setLocked(updatedLoan.isLocked)
	}

	// @ts-ignore
	useSignalREffect<string, Loan>('LoanSave', handleLoanSave)

	if (!locked || !isLoanAdmin || !loan?.isLocked) return null

	return (
		<div className={clsx('text-gray-700', className)} data-cy="root">
			<Tooltip title="The loan is currently in use in Encompass. Loan documents, tasks, and other actions will be queued, and some features may be disabled until user exits the loan.">
				<LockIcon fontSize="medium" />
			</Tooltip>
		</div>
	)
}

export default LoanLockedAlert
