import React, { useMemo } from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { Controller } from 'react-hook-form'
import useFormField from '../../hooks/useFormField'

const DatePicker = ({
	variant = 'standard',
	label,
	control,
	required,
	name,
	errors,
	clearable = false,
	minDate,
	...rest
}) => {
	const { getError } = useFormField()
	const error = getError(name, errors)
	const labelTxt = useMemo(
		() => (required && label ? `${label} *` : label),
		[required, label]
	)
	const slotProps = useMemo(
		() => ({
			textField: {
				variant,
				error: !!error,
				helperText: error ? error.message : undefined,
			},
			field: { clearable },
		}),
		[variant, error, clearable]
	)

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			{control && (
				<Controller
					name={name}
					control={control}
					render={({ field }) => (
						<MuiDatePicker
							label={labelTxt}
							onChange={field.onChange}
							slotProps={slotProps}
							minDate={minDate}
							{...field}
							{...rest}
						/>
					)}
				/>
			)}
			{!control && (
				<MuiDatePicker
					label={labelTxt}
					slotProps={slotProps}
					minDate={minDate}
					{...rest}
				/>
			)}
		</LocalizationProvider>
	)
}

export default DatePicker
