import React, { useEffect, useState } from 'react'
import { Link, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { getTheme } from '../config'
import { TheBigPOSApi } from '../lib/TheBigPOSClient'

const theme = getTheme()
const ResendVerificationCodeLink = () => {
	const [sendingCode, setSendingCode] = useState(false)
	const [message, setMessage] = useState(null)

	useEffect(() => {
		let messageTimeout
		if (message) {
			messageTimeout = setTimeout(() => {
				setMessage(null)
			}, 5000)
		}

		return () => {
			if (messageTimeout) {
				clearTimeout(messageTimeout)
			}
		}
	}, [message])

	const onResendCodeClick = async (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (sendingCode) return
		try {
			setSendingCode(true)
			setMessage(null)
			await TheBigPOSApi.sendMobilePhoneVerificationCode()
			setMessage({
				text: 'Code sent!',
				color: 'success',
			})
		} catch (e) {
			setMessage({
				text: 'Failed to send code. Please try again.',
				color: 'error',
			})
		} finally {
			setSendingCode(false)
		}
	}

	return (
		<>
			<Link
				className="text-blue-500 mt-4 cursor-pointer"
				onClick={onResendCodeClick}
				rel="noopener noreferrer"
				underline="hover"
				style={{ color: theme.siteConfig.color.primary }}
			>
				<Typography variant="link" className="text-sm">
					{sendingCode
						? 'Sending...please wait'
						: 'Re-send your auth code'}
				</Typography>
			</Link>
			{message && (
				<Typography
					variant="body2"
					className="text-sm flex items-center"
				>
					<span className="ml-5">
						{message.color === 'success' ? (
							<CheckIcon color="success" className="pr-1" />
						) : (
							<CloseIcon color="error" className="pr-1" />
						)}
						{message.text}
					</span>
				</Typography>
			)}
		</>
	)
}

export default ResendVerificationCodeLink
