import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { cloneDeep, omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import DataTable from '../../../components/DataTable'
import { ModalRemoveRestoreRecordConfirm } from '../../../components/modals/ModalRemoveRestoreRecordConfirm'
import { useAlert, useWindowSize } from '../../../hooks'
import { useAppContext } from '../../../components/AppContext'
import { applyMask } from '../../../services/utils'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { getTheme } from '../../../config'
import { ModalWizard } from '../../../components/modals/ModalWizard'
import { ModalYesNo } from '../../../components/modals/ModalYesNo'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

const theme = getTheme()

export default function Prospects() {
	const breakpoint = 768
	const [width] = useWindowSize()
	const navigate = useNavigate()
	const { alert } = useAlert()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { user, siteConfig } = state

	const domainParts = siteConfig.url.split('.')

	const questions = [
		{
			heading: 'Partner Subdomain',
			question: 'Set the Subdomain for your partner',
			type: 'text',
			placeholder: 'Enter subdomain',
			qId: 'subdomain',
			suffix: `.${domainParts[1]}.${domainParts[2]}`,
			fieldId: 'subdomain',
			name: 'Subdomain',
		},
	]

	const [fetching, setFetching] = useState(true)
	const [data, setData] = useState([])
	const [filteredData, setFilteredData] = useState([])
	const [searchText, setSearchText] = useState('')
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [selectedProspect, setSelectedProspect] = useState(null)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: '',
		leavePage: false,
		canBeSaved: false,
		isSave: false,
		event: null,
	})
	const [modalWizard, setModalWizard] = useState({
		visible: false,
		data: {},
		title: 'Create Partner Site',
	})

	const columns = [
		{
			name: 'Company Name',
			selector: (row) => row.companyName,
			sortable: true,
		},
		{
			name: 'First Name',
			selector: (row) => row.firstName,
			sortable: true,
		},
		{
			name: 'Last Name',
			selector: (row) => row.lastName,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: (row) => row.contactPhone,
			cell: (row) => applyMask(row.contactPhone, 'phone'),
			hide: breakpoint,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
			hide: breakpoint,
		},
		{
			name: 'Role',
			selector: (row) => row.role,
			sortable: true,
			hide: breakpoint,
		},
		{
			name: 'Created',
			selector: (row) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
			hide: breakpoint,
		},
	]

	const loadProspects = useCallback(async () => {
		try {
			setFetching(true)

			const prospects =
				await TheBigPOSApi.getMyRelationshipProspects()

			setData(prospects.data)
			setFilteredData(prospects?.data)
			setFetching(false)
		} catch (err) {
			alert(getErrorMessage(err), { severity: 'error' })
			setFetching(false)
			ventiState.set(
				theme.storageKeys.errorMessage,
				'There was an error fetching the data'
			)
		}
	}, [alert])

	useEffect(() => {
		if (!user?.isLoggedIn) {
			navigate(`/${navigationLinking.SignIn}`)
		} else {
			;(async () => {
				await loadProspects()
			})()
		}
	}, [loadProspects, navigate, user])

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	useEffect(() => {
		if (searchText !== '') {
			const newFilteredData = data.filter(
				(d) =>
					d.firstName
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase()) ||
					d.lastName
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase()) ||
					d.email
						?.toLowerCase()
						.toString()
						.includes(searchText.toLowerCase())
			)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(data)
		}
	}, [data, searchText])

	const exportData = useMemo(() => {
		const d = []
		filteredData.forEach((item) => {
			d.push({ ...omit(item, 'data'), ...item.data })
		})
		return d
	}, [filteredData])

	const onActivateClick = async (row) => {
		setSelectedProspect(row)
		setModalWizard((current) => ({
			...current,
			visible: true,
			data: {
				subdomain: `${row.firstName.substring(0, 1)}${row.lastName}`
					.replace(/\s/g, '')
					.toLowerCase(),
			},
		}))
	}

	const onDeleteClick = async (row) => {
		setSelectedProspect(row)
		setDeleteModalOpen(true)
	}

	const handleDeleteConfirm = async () => {
		try {
			await TheBigPOSApi.deleteRelationshipProspect(
				selectedProspect.id
			)
			const newProspects = cloneDeep(data)
			const prospectIndex = newProspects.findIndex(
				(l) => l.id === selectedProspect.id
			)
			newProspects.splice(prospectIndex, 1)
			setData(newProspects)
			alert('The prospect was successfully deleted', {
				severity: 'success',
			})
		} catch (e) {
			const errorMessage = 'There was a problem deleting the prospect'
			alert(errorMessage, { severity: 'error' })
			ventiState.set(theme.storageKeys.errorMessage, errorMessage)
		} finally {
			setSelectedProspect(null)
			setDeleteModalOpen(false)
		}
	}

	const handleCheckSubdomainAvailability = async ({ subdomain }) => {
		const url = `${subdomain}.${domainParts[1]}.${domainParts[2]}`
		try {
			await TheBigPOSApi.getSiteConfigurationByUrl({ url })
			ventiState.set(theme.storageKeys.errorObject, {
				message:
					'The domain you have entered is already in use. Please try again.',
			})
		} catch (e) {
			setSelectedProspect((current) => ({ ...current, url }))
			setModalWizard((current) => ({ ...current, visible: false }))
			setModalConfirmation((current) => ({
				...current,
				open: true,
				text: (
					<div>
						<p className="text-sm mb-3">{url} is available</p>
						<p className="text-sm">Create {url}?</p>
					</div>
				),
			}))
		}
	}

	const handleCreatPartnerSite = async () => {
		setFetching(true)
		try {
			const createUserResponse = await TheBigPOSApi.createUser({
				email: selectedProspect.email,
				phone: `+1${selectedProspect.contactPhone.replace(/\D/g, '')}`,
				firstName: selectedProspect.firstName,
				lastName: selectedProspect.lastName,
				title: selectedProspect.title || 'Realtor',
				branchId: selectedProspect.branchID,
				userRole: selectedProspect.role,
			})

			const partner = createUserResponse.data

			const getBranchResponse = await TheBigPOSApi.getBranch(
				selectedProspect.branchID
			)

			const branch = getBranchResponse.data

			const getBranchSiteConfigurationResponse =
				await TheBigPOSApi.getBranchSiteConfiguration(
					selectedProspect.branchID,
					branch.siteConfigurations[0].id
				)

			const branchSiteConfiguration =
				getBranchSiteConfigurationResponse.data

			const { siteConfiguration: siteConfig } =
				branchSiteConfiguration

			const {
				id,
				entityID,
				entityType,
				url,
				email,
				name,
				phone,
				fax,
				tollFree,
				licenses,
				nmlsid,
				introduction,
				introductionTitle,
				losUserID,
				eppsUserName,
				enabledServicesID,
				...inheritedSiteConfiguration
			} = siteConfig

			const formData = {
				...inheritedSiteConfiguration,
				...selectedProspect,
				name: `${selectedProspect.firstName} ${selectedProspect.lastName}`,
				nmlsid: selectedProspect.licenseNumber,
			}
			await TheBigPOSApi.createUserRelation(user.id, {
				userId1: user.id,
				userId2: partner.id,
				relationType: 'RealtorLoanOfficer',
			})
			await TheBigPOSApi.createPartnerSiteConfiguration(
				partner.id,
				formData
			)
			await TheBigPOSApi.deleteRelationshipProspect(
				selectedProspect.id
			)
			alert(
				`Partner ${selectedProspect.url} was created and User ${selectedProspect.email} was invited`
			)
			setData((current) =>
				current.filter((p) => p.id !== selectedProspect.id)
			)
			setFilteredData((current) =>
				current.filter((p) => p.id !== selectedProspect.id)
			)
		} catch (e) {
			alert(`Failed to create partner: ${getErrorMessage(e)}`, {
				severity: 'error',
			})
			ventiState.set(
				theme.storageKeys.errorMessage,
				e?.data?.message || theme.api_messages.server_error
			)
		} finally {
			setModalConfirmation((current) => ({
				...current,
				open: false,
				text: '',
			}))
			setFetching(false)
		}
	}

	return (
		<>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={deleteModalOpen}
				setRemoveModalVisible={setDeleteModalOpen}
				remove={handleDeleteConfirm}
				row={selectedProspect}
			/>
			<ModalWizard
				modal={modalWizard.visible}
				modalData={modalWizard.data}
				setModal={() =>
					setModalWizard((current) => ({
						...current,
						visible: false,
						data: {},
					}))
				}
				questions={questions}
				save={handleCheckSubdomainAvailability}
				finalButtonText="Check Availability"
				modalTitle={modalWizard.title}
				closeOnSave={false}
			/>
			<ModalYesNo
				modalConfirmation={modalConfirmation}
				setModalConfirmation={setModalConfirmation}
				modalConfirm={handleCreatPartnerSite}
				loading={fetching}
			/>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<DataTable
					data={filteredData}
					exportData={exportData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="createdAt"
					pagination
					progressPending={fetching}
					title="Prospects"
					onRefreshClick={loadProspects}
					keyField="id"
					onRowClicked={() => false}
					onSearchChange={onSearchChange}
					exportEnabled
					expandableRows={width < breakpoint}
					fixedHeader
					actionItems={[
						{
							name: 'Activate',
							onClick: (e, row) => onActivateClick(row),
						},
						{
							name: 'Delete',
							onClick: (e, row) => onDeleteClick(row),
						},
					]}
				/>
			</div>
		</>
	)
}
