import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import Page from '../../../components/Page'
import DataTable from '../../../components/DataTable'
import { useAppContext } from '../../../components/AppContext'
import withAuth from '../../../components/withAuth'
import DeviceRowExpanded from './DeviceRowExpanded'

import { navigationLinking } from '../../../services/navigation'
import queryKeys from '../../../services/queryKeys'
import { useAlert } from '../../../hooks'
import { getTheme } from '../../../config'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'

const theme = getTheme()

const AdminDevicesView = () => {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user } = state
	const navigate = useNavigate()

	const [filteredData, setFilteredData] = useState([])
	const [searchText, setSearchText] = useState('')

	const columns = [
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Serial #',
			selector: (row) => row.serialNumber,
			sortable: true,
			hide: theme.breakpoints.dataTable,
		},
		{
			name: 'Device Type',
			selector: (row) => row.type,
			sortable: true,
		},
		{
			name: 'Status',
			selector: (row) => row.status,
			hide: theme.breakpoints.dataTable,
		},
	]

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e, row) => onRowClicked(row),
		},
	]
	const {
		isFetching,
		isRefetching,
		error,
		data: devicesResult,
		refetch,
	} = useQuery({
		queryKey: [queryKeys.kiosks],
		queryFn: () =>
			TheBigPOSApi.getDevices({
				posAccountId: user.accountID,
			}),
		select: (data) => {
			return data.data.rows
		},
		retry: 2,
	})

	useEffect(() => {
		if (error) {
			alert('There was a problem loading the kiosks', {
				severity: 'error',
			})
		}
	}, [error])

	useEffect(() => {
		if (searchText !== '') {
			const lowercasedSearchText = searchText.toLowerCase()
			let newFilteredData = !searchText
				? devicesResult
				: devicesResult.filter(
						(d) =>
							d.name?.toLowerCase().includes(lowercasedSearchText) ||
							d.serialNumber
								?.toLowerCase()
								.includes(lowercasedSearchText)
					)
			setFilteredData(newFilteredData)
		} else {
			setFilteredData(devicesResult)
		}
	}, [devicesResult, searchText])

	const onRowClicked = (row) => {
		navigate(
			generatePath(`/${navigationLinking.AdminDeviceEdit}`, {
				id: row.id,
			})
		)
	}

	const onSearchChange = (e) => {
		setSearchText(e.target.value)
	}

	const onClearSearchClick = () => {
		setSearchText('')
	}

	return (
		<Page title="Loan Officers" isFullWidth={true}>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<DataTable
					data={filteredData}
					columns={columns}
					defaultSortAsc={false}
					defaultSortFieldId="name"
					pagination={true}
					progressPending={isFetching || isRefetching}
					title="Kiosks"
					onRefreshClick={refetch}
					keyField="id"
					onRowClicked={onRowClicked}
					onSearchChange={onSearchChange}
					onClearSearchClick={onClearSearchClick}
					searchText={searchText}
					exportEnabled={false}
					fixedHeader={true}
					expandableRowsComponent={DeviceRowExpanded}
					actionItems={actionItems}
				/>
			</div>
		</Page>
	)
}
export default withAuth(AdminDevicesView)
