import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import type {
	LoanQueueWithData,
	UpdateLoanQueueRequest,
} from '@matech/thebigpos-sdk'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import useForm from '../hooks/useForm'
import { Form, FormRow } from '../components/formControls'
import CodeEditorField from '../components/formControls/CodeEditorField'
import { LoadingBtn } from '../components/Button'
import { isJsonString } from '../services/utils'

export type LoanQueueItemFormProps = {
	loanQueueItemData: LoanQueueWithData['data']
	onSubmit: (data: UpdateLoanQueueRequest) => void
	loading?: boolean
}

const LoanQueueItemForm = ({
	loanQueueItemData,
	onSubmit,
	loading = false,
}: LoanQueueItemFormProps) => {
	const { formatMessage } = useIntl()
	const methods = useForm<UpdateLoanQueueRequest>({
		defaultValues: {
			data: JSON.stringify(loanQueueItemData, null, 2),
		},
		schema: yup
			.object()
			.shape({
				data: yup.string().required(),
			})
			.required(),
	})

	const { handleSubmit, watch, reset } = methods

	const isCurrentJsonValid = isJsonString(watch('data'))

	const handleSaveClick = async (
		formData: UpdateLoanQueueRequest
	) => {
		const isValid = isJsonString(formData.data)
		if (!isValid) {
			return
		}
		onSubmit(JSON.parse(formData.data))
	}

	useEffect(() => {
		reset({
			data: JSON.stringify(loanQueueItemData, null, 2),
		})
	}, [loanQueueItemData, reset])

	return (
		<FormProvider {...methods}>
			<Form
				onSubmit={handleSubmit(handleSaveClick)}
				className="w-full"
			>
				<FormRow>
					<CodeEditorField
						name="data"
						height="70vh"
						language="json"
						loading={loading}
					/>
				</FormRow>
				<FormRow className="flex justify-end mt-6">
					<LoadingBtn
						text={`${formatMessage({ id: 'global.save' })} & ${formatMessage({ id: 'global.resend' })}`}
						type="submit"
						loading={loading}
						fullWidth={false}
						disabled={!isCurrentJsonValid}
					/>
				</FormRow>
			</Form>
		</FormProvider>
	)
}

export default LoanQueueItemForm
