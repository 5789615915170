import React from 'react'
import { useMutation } from '@tanstack/react-query'
import {
	UserLoanTask,
	File as FileProps,
	Task,
	UserLoanTaskUpdateRequest,
} from '@matech/thebigpos-sdk'
import { AxiosResponse } from 'axios'
import Dialog from '../../../../components/modals/Dialog'
import type { DialogProps } from '../../../../components/modals/Dialog'
import LoanTaskFieldForm, {
	LoanTaskFieldFormFieldValues,
} from '../forms/LoanTaskFieldForm'
import LoanTaskDocumentForm, {
	LoanTaskDocumentFormFieldValues,
} from '../forms/LoanTaskDocumentForm'
import { useAlert } from '../../../../hooks'
import { Verification } from './Verification'
import { eventTypes } from '../../../../services/constants'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { isTaskTypeVerification } from '../helpers'
import { FixMeLater } from '../../../../types'

export type VerificationFormMapItemProps = {
	type: 'Verification'
	onSubmit: (data: LoanTaskDocumentFormFieldValues) => void
	component: React.ReactNode
}

export type DocumentFormMapItemProps = {
	type: 'Document'
	onSubmit: (data: LoanTaskDocumentFormFieldValues) => void
	component: React.ReactNode
}

export type FieldFormMapItemProps = {
	type: 'Field'
	onSubmit: (data: LoanTaskFieldFormFieldValues) => void
	component: React.ReactNode
}

export type FormMapItemProps =
	| DocumentFormMapItemProps
	| FieldFormMapItemProps
	| VerificationFormMapItemProps

export type ModalLoanTaskProps = {
	loanTask: UserLoanTask
	onSubmit: () => void
	onCancel?: () => void
} & DialogProps

const ModalLoanTask = ({
	loanTask,
	onSubmit,
	onCancel = undefined,
	...rest
}: ModalLoanTaskProps) => {
	const { task } = loanTask

	const { alert } = useAlert()
	const mixpanel = useMixpanel()

	const updateLoanTaskFieldMutation = useMutation({
		mutationFn: async (data: UserLoanTaskUpdateRequest) =>
			TheBigPOSApi.replaceLoanTask(
				loanTask.loanID,
				loanTask.id,
				data
			),
	})

	const createLoanTaskDocumentMutation = useMutation({
		mutationFn: async (data: FileProps) =>
			TheBigPOSApi.createLoanTaskDocument(
				loanTask.loanID,
				loanTask.id,
				{
					name: data.name,
					file: data,
				}
			),
	})

	const handleSubmit = async (
		data?:
			| LoanTaskDocumentFormFieldValues
			| LoanTaskFieldFormFieldValues
	) => {
		try {
			const { id, name, type } = task

			if (data) {
				// @todo refactor to prevent circular definition
				// @todo remove casting
				// eslint-disable-next-line no-use-before-define
				await formMap[type]?.onSubmit(
					data as LoanTaskDocumentFormFieldValues &
						LoanTaskFieldFormFieldValues
				)
			}

			onSubmit()

			alert('The task was successfully updated', {
				severity: 'success',
			})
			mixpanel.trackEvent(eventTypes.LOAN_TASK_SUBMITTED, {
				id,
				name,
				type,
			})
		} catch {
			alert('There was a problem updating the task', {
				severity: 'error',
			})
		}
	}

	const handleFieldFormSubmit = async (
		data: LoanTaskFieldFormFieldValues
	) => {
		await updateLoanTaskFieldMutation.mutateAsync(data)
	}

	const handleDocumentFormSubmit = async (
		data: LoanTaskDocumentFormFieldValues
	) => {
		const { documents } = data
		const mutations: Promise<
			AxiosResponse<UserLoanTask, FixMeLater>
		>[] = []
		documents.forEach((file) => {
			mutations.push(createLoanTaskDocumentMutation.mutateAsync(file))
		})
		await Promise.all(mutations)
	}

	const verificationFormMap: VerificationFormMapItemProps = {
		type: 'Verification',
		onSubmit: handleDocumentFormSubmit,
		component: (
			<Verification
				loanTask={loanTask}
				onSubmit={handleSubmit}
				onCancel={onCancel}
			/>
		),
	}

	const formMap: Record<Task['type'], FormMapItemProps> = {
		Document: {
			type: 'Document',
			onSubmit: handleDocumentFormSubmit,
			component: (
				<LoanTaskDocumentForm
					onSubmit={handleSubmit}
					onCancel={onCancel}
				/>
			),
		},
		Field: {
			type: 'Field',
			onSubmit: handleFieldFormSubmit,
			component: (
				<LoanTaskFieldForm
					loanTask={loanTask}
					onSubmit={handleSubmit}
					onCancel={onCancel}
				/>
			),
		},
		VerificationOfAssets: verificationFormMap,
		VerificationOfIncome: verificationFormMap,
		VerificationOfEmployment: verificationFormMap,
		VerificationOfCredit: verificationFormMap,
		VerificationOfIncomeAndEmployment: verificationFormMap,
	}

	return (
		<Dialog
			title={task.name}
			onClose={onCancel}
			fullWidth
			maxWidth="sm"
			{...rest}
		>
			{task.description && !isTaskTypeVerification(task.type) && (
				<p className="mb-2">{task.description}</p>
			)}
			{formMap[task.type]?.component}
		</Dialog>
	)
}

export default ModalLoanTask
