import React from 'react'
import {
	FormControlLabel,
	Switch as MuiSwitch,
	SwitchProps as MuiSwitchProps,
} from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { RFHControl } from '../../types'

export type SwitchProps = {
	name?: string
	label?: string
	control?: RFHControl
	className?: string
} & Omit<MuiSwitchProps, 'className'>

const Switch = ({
	name,
	label,
	control: propsControl,
	className,
	sx,
	defaultValue,
	...rest
}: SwitchProps) => {
	const formContext = useFormContext()
	const control = propsControl || formContext?.control

	return (
		<div className={className}>
			{name && control && (
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field }) => (
						<FormControlLabel
							label={label}
							control={
								<MuiSwitch
									{...field}
									{...rest}
									sx={sx}
									checked={field.value}
									onChange={(e) => field.onChange(e.target.checked)}
								/>
							}
						/>
					)}
				/>
			)}
			{!control && (
				<FormControlLabel
					label={label}
					sx={sx}
					control={
						<MuiSwitch
							name={name}
							defaultValue={defaultValue}
							{...rest}
						/>
					}
				/>
			)}
		</div>
	)
}

export default Switch
