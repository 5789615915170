import React from 'react'
import {
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material'
import { LoanQueueSearchCriteria } from '@matech/thebigpos-sdk'
import { FormControl } from '../formControls'

type LoanQueueSearchFiltersProps = {
	handleFilterChange: (
		e: SelectChangeEvent<string>,
		filterName: string
	) => void
	searchCriteria: LoanQueueSearchCriteria
}

const LoanQueueSearchFilters = ({
	handleFilterChange,
	searchCriteria,
}: LoanQueueSearchFiltersProps) => {
	const statuses: Record<string, string> = {
		Pending: 'Pending',
		Retrying: 'Retrying',
		Successful: 'Successful',
		Failed: 'Failed',
		FailedPermanently: 'Failed Permanently',
		Unknown: 'Unknown',
	}

	const types = [
		'New',
		'Append',
		'Update',
		'Document',
		'Unknown',
	] as const

	const reasons: Record<string, string> = {
		Locked: 'Locked',
		LOSError: 'Encompass Error',
		Exception: 'Exception',
		Unknown: 'Unknown',
	}

	return (
		<>
			<FormControl id="filterType" variant="standard">
				<InputLabel>Type</InputLabel>
				<Select<string>
					value={searchCriteria.type || 'all'}
					onChange={(e) => handleFilterChange(e, 'type')}
					name="Type"
				>
					<MenuItem value="all">Show All</MenuItem>
					{Object.values(types).map((key) => (
						<MenuItem value={key} key={key}>
							{key}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl id="filterStatus" variant="standard">
				<InputLabel>Status</InputLabel>
				<Select<string>
					value={searchCriteria.status || 'all'}
					onChange={(e) => handleFilterChange(e, 'status')}
					name="Status"
				>
					<MenuItem value="all">Show All</MenuItem>
					{Object.entries(statuses).map(([key, value]) => (
						<MenuItem value={key} key={key}>
							{value}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl id="filterReason" variant="standard">
				<InputLabel>Reason</InputLabel>
				<Select<string>
					value={searchCriteria.reason || 'all'}
					onChange={(e) => handleFilterChange(e, 'reason')}
					name="Reason"
					label="Reason"
				>
					<MenuItem value="all">Show All</MenuItem>
					{Object.entries(reasons).map(([key, value]) => (
						<MenuItem value={key} key={key}>
							{value}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default LoanQueueSearchFilters
