import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useVenti } from 'venti'
import { SiteConfigurationSummary } from '@matech/thebigpos-sdk'
import { TableColumn } from 'react-data-table-component'

import withAuth from '../../../components/withAuth'
import BranchExpandedRow from './BranchExpandedRow'

import { formatDate, getErrorMessage } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { eventTypes } from '../../../services/constants'

import { useMixpanel } from '../../../hooks/useMixpanel'
import { useAlert } from '../../../hooks'
import { getTheme } from '../../../config'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import SiteConfigurationPage, {
	SiteConfigurationEntityType,
} from '../SiteConfigurationPage'
import BranchesSearchFilters from './BranchesSearchFilters'

const theme = getTheme()

const columns: TableColumn<SiteConfigurationSummary>[] = [
	{
		name: 'Name',
		selector: (row: SiteConfigurationSummary) => row.name,
		sortable: true,
		sortField: 'name',
	},
	{
		name: 'NMLSID',
		selector: (row: SiteConfigurationSummary) => row.nmlsid,
		sortable: true,
		sortField: 'nMLSID',
	},
	{
		name: 'Email',
		selector: (row: SiteConfigurationSummary) => row.email || '',
		sortable: true,
		sortField: 'email',
	},
	{
		name: 'Type',
		selector: (row: SiteConfigurationSummary) =>
			row.branch?.type || '',
	},
	{
		name: 'Brand',
		selector: (row: SiteConfigurationSummary) =>
			row.companyName || '',
	},
	{
		name: 'Domain',
		selector: (row: SiteConfigurationSummary) => row.url || '',
		sortable: true,
		sortField: 'url',
	},
	{
		name: 'Created',
		selector: (row: SiteConfigurationSummary) =>
			row.branch?.createdAt || '',
		cell: (row: SiteConfigurationSummary) =>
			formatDate(row.branch?.createdAt, false),
		hide: theme.breakpoints.dataTable,
	},
]

const Branches = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()
	const [filters, setFilters] = useState({
		brand: null,
		type: null,
		branch: null,
	})
	const [removeModalVisible, setRemoveModalVisible] = useState(false)

	const [activeRow, setActiveRow] =
		useState<SiteConfigurationSummary | null>(null)

	const handleMutationSuccess = (
		eventType: string,
		modalSetter: React.Dispatch<React.SetStateAction<boolean>>,
		successMessage: string
	) => {
		modalSetter(false)

		if (!activeRow) return
		const { branch, name } = activeRow

		mixpanel.trackEvent(eventType, {
			name,
			id: branch?.id,
		})
		alert(`Branch "${name}" successfully ${successMessage}`)
		setActiveRow(null)
	}

	const onRowClicked = async (row: SiteConfigurationSummary) => {
		await ventiState.set(
			theme.storageKeys.editingBranchSiteConfigurationId,
			row.id
		)
		navigate(
			generatePath(`/${navigationLinking.AdminBranchEdit}`, {
				id: row.branch?.id,
			})
		)
	}

	const handleActiveRowChange = (row: SiteConfigurationSummary) => {
		setActiveRow(row)
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminBranchEdit}`, {
				id: 'new',
			})
		)
	}

	const removeMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteBranch(id),
		onSuccess: () => {
			handleMutationSuccess(
				eventTypes.BRANCH_DELETED,
				setRemoveModalVisible,
				'removed'
			)
			setActiveRow(null)
		},
		onError: (e) => {
			alert(getErrorMessage(e), { severity: 'error' })
		},
	})

	const removeBranch = async () => {
		if (!activeRow?.branch?.id) return
		await removeMutation.mutateAsync(activeRow?.branch.id)
	}

	const handleFilterChange = (
		value: string | undefined,
		field: string
	) => {
		setFilters((prev) => ({
			...prev,
			[field]: value,
		}))
	}

	return (
		<SiteConfigurationPage
			entityType={SiteConfigurationEntityType.Branch}
			title="Branches"
			onRemove={removeBranch}
			isRemovePending={removeMutation.isPending}
			filters={filters}
			activeRow={activeRow}
			onActiveRowChange={handleActiveRowChange}
			columns={columns}
			onRowClicked={onRowClicked}
			removeModalVisible={removeModalVisible}
			onRemoveModalVisible={setRemoveModalVisible}
			searchFilters={
				<BranchesSearchFilters
					filters={filters}
					handleFilterChange={handleFilterChange}
				/>
			}
			expandableRowsComponent={BranchExpandedRow}
			addNewBtn={{ text: 'Add Branch', onClick: onAddClick }}
		/>
	)
}

export default withAuth(Branches)
