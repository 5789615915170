import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useVenti } from 'venti'
import { TableColumn } from 'react-data-table-component'
import { SiteConfigurationSummary } from '@matech/thebigpos-sdk'
import withAuth from '../../../components/withAuth'
import LoanOfficerExpandedRow from './LoanOfficerExpandedRow'

import { navigationLinking } from '../../../services/navigation'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { eventTypes } from '../../../services/constants'

import { getTheme } from '../../../config'
import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import SiteConfigurationPage, {
	SiteConfigurationEntityType,
} from '../SiteConfigurationPage'
import UserSiteConfigurationSearchFilters from '../Partners/UserSiteConfigurationSearchFilters'

const theme = getTheme()

const columns: TableColumn<SiteConfigurationSummary>[] = [
	{
		name: 'First Name',
		selector: (row: SiteConfigurationSummary) =>
			row.loanOfficer?.firstName || '',
	},
	{
		name: 'Last Name',
		selector: (row: SiteConfigurationSummary) =>
			row?.loanOfficer?.lastName || '',
	},
	{
		name: 'Title',
		selector: (row: SiteConfigurationSummary) =>
			row.loanOfficer?.title || '',
		hide: theme.breakpoints.dataTable,
	},
	{
		name: 'Email',
		selector: (row: SiteConfigurationSummary) => row.email || '',
		sortable: true,
		sortField: 'email',
		hide: theme.breakpoints.dataTable,
	},
	{
		name: 'Brand',
		selector: (row: SiteConfigurationSummary) =>
			row.companyName || '',
	},
	{
		name: 'Branch',
		selector: (row: SiteConfigurationSummary) => row.branchName || '',
	},
	{
		name: 'URL',
		selector: (row: SiteConfigurationSummary) => row.url || '',
		sortable: true,
		sortField: 'url',
		hide: theme.breakpoints.dataTable,
	},
	{
		name: 'Role',
		selector: (row: SiteConfigurationSummary) =>
			row.loanOfficer?.role || '',
		hide: theme.breakpoints.dataTable,
	},
	{
		name: 'Created On',
		selector: (row: SiteConfigurationSummary) =>
			row.loanOfficer?.createdAt || '',
		cell: (row: SiteConfigurationSummary) =>
			formatDate(row.loanOfficer?.createdAt, false),
		hide: theme.breakpoints.dataTable,
	},
]

const LoanOfficers = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()

	const [removeModalVisible, setRemoveModalVisible] = useState(false)

	const [activeRow, setActiveRow] =
		useState<SiteConfigurationSummary | null>(null)
	const [filters, setFilters] = useState({
		brand: 'all',
		branch: 'all',
		type: null,
	})

	const handleMutationSuccess = (
		eventType: string,
		modalSetter: React.Dispatch<React.SetStateAction<boolean>>,
		successMessage: string,
		activeRow: SiteConfigurationSummary | null
	) => {
		if (!activeRow) return

		const { id, loanOfficer } = activeRow
		const name = `${loanOfficer?.firstName || ''} ${loanOfficer?.lastName || ''}`
		mixpanel.trackEvent(eventType, {
			id,
			name,
		})
		modalSetter(false)
		alert(`Loan Officer "${name}" successfully ${successMessage}`)
	}

	const removeLoanOfficerMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteUser(id),
		onSuccess: () => {
			handleMutationSuccess(
				eventTypes.LOAN_OFFICER_DELETED,
				setRemoveModalVisible,
				'removed',
				activeRow
			)
			setActiveRow(null)
		},
	})

	const removeLoanOfficer = async () => {
		try {
			if (activeRow?.loanOfficer?.id)
				await removeLoanOfficerMutation.mutateAsync(
					activeRow?.loanOfficer?.id
				)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const handleFilterChange = (
		value: string | undefined,
		field: string
	) => {
		setFilters((prev) => ({
			...prev,
			[field]: value,
		}))
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminLoanOfficerEdit}`, {
				id: 'new',
			})
		)
	}

	const onRowClicked = async (row: SiteConfigurationSummary) => {
		await ventiState.set(
			theme.storageKeys.editingLoanOfficerSiteConfigurationId,
			row.id
		)
		navigate(
			generatePath(`/${navigationLinking.AdminLoanOfficerEdit}`, {
				id: row.loanOfficer?.id,
			})
		)
	}

	const handleActiveRowChange = (row: SiteConfigurationSummary) => {
		setActiveRow(row)
	}

	return (
		<SiteConfigurationPage
			entityType={SiteConfigurationEntityType.LoanOfficer}
			title="Loan Officers"
			onRemove={removeLoanOfficer}
			isRemovePending={removeLoanOfficerMutation.isPending}
			filters={filters}
			activeRow={activeRow}
			onActiveRowChange={handleActiveRowChange}
			columns={columns}
			onRowClicked={onRowClicked}
			removeModalVisible={removeModalVisible}
			onRemoveModalVisible={setRemoveModalVisible}
			searchFilters={
				<UserSiteConfigurationSearchFilters
					filters={filters}
					handleFilterChange={handleFilterChange}
				/>
			}
			expandableRowsComponent={LoanOfficerExpandedRow}
			addNewBtn={{ text: 'Add Loan Officer', onClick: onAddClick }}
		/>
	)
}

export default withAuth(LoanOfficers)
