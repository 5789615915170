import React from 'react'
import clsx from 'clsx'
import { useLoanContext } from '../../contexts/LoanContext'

export type LoanSummaryProps = {
	className?: string
}

const LoanSummary = ({ className }: LoanSummaryProps) => {
	const { loanData, isLoading } = useLoanContext()

	if (isLoading || !loanData) return null

	return (
		<div className={clsx(className, 'text-sm flex items-center')}>
			{loanData?.LoanNumber && (
				<div className="font-bold mr-2" data-cy="loan-number">
					#{loanData?.LoanNumber}
				</div>
			)}
			<address className="not-italic">
				{loanData?.SubjectPropertyAddress && (
					<div>{loanData?.SubjectPropertyAddress}</div>
				)}
				{loanData?.SubjectPropertyAddressCity}{' '}
				{loanData?.SubjectPropertyAddressState}{' '}
				{loanData?.SubjectPropertyAddressZip}
			</address>
		</div>
	)
}

export default LoanSummary
