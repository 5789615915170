import React from 'react'
import { useIntl } from 'react-intl'

import { DialogProps } from './Dialog'
import ConfirmModal from './ConfirmModal'

type ConfirmDeleteModalProps = {
	onRemove?: () => void
	loading: boolean
	name?: string
} & DialogProps

const ConfirmDeleteModal = ({
	open,
	loading,
	name,
	onClose,
	onRemove,
	...rest
}: ConfirmDeleteModalProps) => {
	const { formatMessage } = useIntl()

	const handleCloseModal = () => {
		onClose?.()
	}

	return (
		<ConfirmModal
			open={open}
			button={{
				loading,
				title: formatMessage({
					id: 'remove_record.confirm_deletion',
				}),
			}}
			onConfirm={onRemove}
			onClose={handleCloseModal}
			title={formatMessage({ id: 'remove_record.confirm_delete' })}
			subtitle={`${formatMessage({ id: 'remove_record.delete' })} "${name}"`}
			{...rest}
		/>
	)
}

export default ConfirmDeleteModal
