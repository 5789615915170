import React, { useState } from 'react'
import MobilePhoneVerificationStepper from './MobilePhoneVerificationStepper'
import { LoadingBtn } from './Button'
import { applyMask } from '../services/utils'
import { getErrorMessage } from '../services/helper'
import { useAlert } from '../hooks'
import withAuth from './withAuth'
import useUser from '../hooks/useUser'
import { useNavigate } from 'react-router-dom'
import { useAppContextActions } from './AppContext/AppHooks'

const MobilePhoneVerification = ({
	className,
	onComplete,
	canSkip = false,
	skipsRemaining = 0,
	homeButtonEnabled = false,
}) => {
	const { alert } = useAlert()
	const { user, updateMe, getSignInRedirectURL, setUser } = useUser()
	const navigate = useNavigate()
	const { logOut } = useAppContextActions()

	const [phoneVerificationVisible, setPhoneVerificationVisible] =
		useState(!user.phoneVerified)
	const [loading, setLoading] = useState(false)

	const onUseExistingPhoneClick = async () => {
		try {
			if (!user.mfaEnabled) {
				setLoading(true)
				await updateMe({ ...user, mfaEnabled: true })
			}

			onComplete()
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		} finally {
			setLoading(false)
		}
	}

	const onUseNewPhoneClick = () => {
		setPhoneVerificationVisible(true)
	}

	const onSkipClick = async () => {
		setUser({ ...user, skippedMFAConfig: true })
		const redirectUrl = getSignInRedirectURL()
		navigate(redirectUrl)
	}

	const onBackToHomeClick = () => {
		logOut()
	}

	return (
		<div className={className}>
			{!phoneVerificationVisible && user.phone && (
				<div>
					<p>
						Would you like to use{' '}
						<strong>{applyMask(user.phone, 'phone')}</strong> for MFA?
					</p>
					<div className="grid grid-cols-2 gap-2 mt-8">
						<LoadingBtn
							onClick={onUseExistingPhoneClick}
							loading={loading}
							disabled={loading}
							text="Yes"
						/>
						<LoadingBtn
							onClick={onUseNewPhoneClick}
							variant="outlined"
							disabled={loading}
							text="No"
						/>
					</div>
				</div>
			)}
			{phoneVerificationVisible && (
				<div className="mt-4">
					<MobilePhoneVerificationStepper onComplete={onComplete} />
					<div className="pt-4">
						{canSkip && (
							<>
								<LoadingBtn
									text="Skip"
									variant="outlined"
									className="w-full"
									onClick={onSkipClick}
									fullWidth
								/>
								{skipsRemaining > 0 && (
									<small>
										You can skip MFA setup {skipsRemaining} more time
										{skipsRemaining > 1 ? 's' : ''}
									</small>
								)}
							</>
						)}
						{!canSkip && homeButtonEnabled && (
							<LoadingBtn
								text="Back to Home Page"
								variant="outlined"
								className="w-full"
								onClick={onBackToHomeClick}
								fullWidth
							/>
						)}
					</div>
				</div>
			)}
		</div>
	)
}

export default withAuth(MobilePhoneVerification)
