import React from 'react'
import LoanLockedAlert from './LoanLockedAlert'
import LoanSummary from './LoanSummary'

const LoanHeader = () => {
	return (
		<div className="border-b px-4 pt-3 pb-2">
			<div className="flex flex-row items-center">
				<LoanLockedAlert className="flex flex-row items-center mr-2" />
				<LoanSummary />
			</div>
		</div>
	)
}

export default LoanHeader
