import { useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { tableRowsPerPageOptions } from '../services/utils'

type UsePaginationProps = {
	defaultSortBy: string
	defaultSortDirection: 'asc' | 'desc'
}
const usePagination = <T>({
	defaultSortBy,
	defaultSortDirection,
}: UsePaginationProps) => {
	const [pageNumber, setPageNumber] = useState(1)
	const [pageSize, setPageSize] = useState(tableRowsPerPageOptions[0])
	const [sortBy, setSortBy] = useState(defaultSortBy)
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
		defaultSortDirection
	)

	const handlePageChange = (pageNumber: number) => {
		setPageNumber(pageNumber)
	}

	const handleRowsPerPageChange = (
		newPageSize: number,
		pageNumber: number
	) => {
		setPageSize(newPageSize)
		setPageNumber(pageNumber)
	}

	const handleSort = (
		column: TableColumn<T>,
		direction: 'asc' | 'desc'
	) => {
		setSortBy(column?.sortField || defaultSortBy)
		setSortDirection(direction)
	}

	return {
		pageNumber,
		sortBy,
		sortDirection,
		pageSize,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
	}
}

export default usePagination
