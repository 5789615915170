import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { getTheme } from '../config'

type UseSearchProps = {
	onFetch?: () => void | undefined
}

const theme = getTheme()

const useSearch = ({ onFetch }: UseSearchProps) => {
	const [searchText, setSearchText] = useState('')
	const { debounce: debounceWait } = theme.search

	const handleSearchChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => setSearchText(e.target.value)

	const handleClearSearchClick = () => setSearchText('')

	const searchRefetch = debounce(() => {
		if (onFetch) {
			onFetch()
		}
	}, debounceWait)

	useEffect(() => {
		searchRefetch()
	}, [searchText, onFetch, searchRefetch])

	return {
		searchText,
		handleSearchChange,
		handleClearSearchClick,
	}
}

export default useSearch
