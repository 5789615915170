const queryKeys = {
	lead: 'lead',
	user: 'user',
	users: 'users',
	kiosk: 'kiosk',
	leads: 'leads',
	kiosks: 'kiosks',
	branch: 'branch',
	account: 'account',
	partner: 'partner',
	surveys: 'surveys',
	listing: 'listing',
	listings: 'listings',
	allowImpersonation: 'allowImpersonation',
	partners: 'partners',
	loanUsers: 'loanUsers',
	businessRules: 'businessRules',
	businessRule: 'businessRule',
	documentBuckets: 'documentBuckets',
	loanTasks: 'loanTasks',
	loanTasksDiff: 'loanTasksDiff',
	loanDocuments: 'loanDocuments',
	loanDrafts: 'loanDrafts',
	loanDraft: 'loanDraft',
	loanDocumentBuckets: 'loanDocumentBuckets',
	loan: 'loan',
	loanData: 'loanData',
	loans: 'loans',
	loanPreliminaryConditions: 'loanPreliminaryConditions',
	loanUnderwritingConditions: 'loanUnderwritingConditions',
	tasks: 'tasks',
	workflow: 'workflow',
	branches: 'branches',
	workflows: 'workflows',
	loanOfficer: 'loanOfficer',
	corporations: 'corporations',
	loanOfficers: 'loanOfficers',
	userRelations: 'userRelations',
	listingOffers: 'listingOffers',
	requestQueues: 'requestQueues',
	surveysByEmail: 'surveysByEmail',
	openHouseLeads: 'openHouseLeads',
	documentTemplate: 'documentTemplate',
	documentTemplates: 'documentTemplates',
	notificationTemplate: 'notificationTemplate',
	realtorApplications: 'realtorApplications',
	borrowerApplications: 'borrowerApplications',
	notificationTemplates: 'notificationTemplates',
	corporateLoanOfficers: 'corporateLoanOfficers',
	branchSiteConfiguration: 'branchSiteConfiguration',
	milestoneConfigurations: 'milestoneConfigurations',
	loanOfficerApplications: 'loanOfficerApplications',
	partnerSiteConfiguration: 'partnerSiteConfiguration',
	customerSiteConfigurations: 'customerSiteConfigurations',
	settlementAgentApplications: 'settlementAgentApplications',
	corporationSiteConfiguration: 'corporationSiteConfiguration',
	siteConfigurations: 'siteConfigurations',
	loanOfficerSiteConfiguration: 'loanOfficerSiteConfiguration',
	documentTemplateSiteConfiguration:
		'documentTemplateSiteConfiguration',
	notificationTemplateSiteConfiguration:
		'notificationTemplateSiteConfiguration',
	loanDocumentContentUrl: 'loanDocumentContentUrl',
	loanDocumentContent: 'loanDocumentContent',
	userLoanTaskComments: 'userLoanTaskComments',
	loanQueue: 'loanQueue',
}
export default queryKeys
