import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVenti } from 'venti'
import {
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query'
import { TableColumn } from 'react-data-table-component'
import {
	BranchUser,
	DraftContent,
	DraftLoanOfficerReassignRequest,
	LoanDraftSearchCriteria,
} from '@matech/thebigpos-sdk'
import { AxiosError } from 'axios'
import { useIntl } from 'react-intl'
import queryKeys from '../../../../services/queryKeys'
import DataTable, {
	ActionItem,
} from '../../../../components/DataTable'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { useAlert } from '../../../../hooks'
import { formatDate } from '../../../../services/helper'
import { getTheme } from '../../../../config'
import { navigationLinking } from '../../../../services/navigation'
import useSearch from '../../../../hooks/useSearch'
import usePagination from '../../../../hooks/usePagination'
import { ApiError, FixMeLater, LoanData } from '../../../../types'
import AssignLoanOfficerModal from './AssignLoanOfficerModal'
import { ModalRemoveRestoreRecordConfirm } from '../../../../components/modals/ModalRemoveRestoreRecordConfirm'
import SearchFiltersLoanDraftsTab from './SearchFiltersLoanDraftsTab'
import { ModalPleaseWait } from '../../../../components/modals/ModalPleaseWait'
import useUser from '../../../../hooks/useUser'
import ConfirmModal from '../../../../components/modals/ConfirmModal'

const theme = getTheme()

const LoanDrafts = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const { isAdmin } = useUser()
	const { formatMessage } = useIntl()

	const [reassignLOModalOpen, setReassignLOModalOpen] =
		useState(false)
	const [selectedRow, setSelectedRow] = useState<DraftContent | null>(
		null
	)
	const [filters, setFilters] = useState<LoanDraftSearchCriteria>({
		siteConfigurationId: 'all',
		loanOfficerId: 'all',
	})
	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})
	const [searchCriteria, setSearchCriteria] =
		useState<LoanDraftSearchCriteria>({
			searchText,
		})

	const [removeModalVisible, setRemoveModalVisible] = useState(false)
	const { defaultSortColumn, defaultSortDirection } = theme.pagination
	const [fetching, setFetching] = useState(false)
	const [isSendToLos, setIsSendToLos] = useState(false)
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<DraftContent>({
		defaultSortBy: defaultSortColumn,
		defaultSortDirection: defaultSortDirection as FixMeLater,
	})

	const {
		isFetching,
		isRefetching,
		isError,
		isLoading,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.loanDrafts,
			searchCriteria,
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		],
		queryFn: () =>
			TheBigPOSApi.searchLoanDrafts(searchCriteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	useEffect(() => {
		if (reassignLOModalOpen) return
		setSelectedRow(null)
	}, [reassignLOModalOpen])

	useEffect(() => {
		setSearchCriteria((prev) => ({ ...prev, searchText }))
	}, [searchText])

	useEffect(() => {
		let formattedFilters = {
			...filters,
			isUnassigned: null,
		} as LoanDraftSearchCriteria
		if (filters.siteConfigurationId === 'all') {
			formattedFilters = {
				...formattedFilters,
				siteConfigurationId: null,
			}
		}
		if (filters.loanOfficerId !== formattedFilters.loanOfficerId) {
			formattedFilters = { ...formattedFilters, isUnassigned: false }
		}
		if (filters.loanOfficerId === 'unassigned') {
			formattedFilters = {
				...formattedFilters,
				isUnassigned: true,
				loanOfficerId: null,
			}
		}
		if (filters.loanOfficerId === 'all') {
			formattedFilters = { ...formattedFilters, loanOfficerId: null }
		}
		setSearchCriteria((prev) => ({ ...prev, ...formattedFilters }))
	}, [filters])

	useEffect(() => {
		if (!isError) return

		alert('There was a problem loading loan drafts', {
			severity: 'error',
		})
	}, [isError, alert])

	const columns: TableColumn<DraftContent>[] = [
		{
			name: 'First Name',
			selector: (row: DraftContent) => row.user.firstName,
		},
		{
			name: 'Last Name',
			selector: (row: DraftContent) => row.user.lastName,
		},
		{
			name: 'Email',
			selector: (row: DraftContent) => row.user.email,
		},
		{
			name: 'Created At',
			sortField: 'createdAt',
			selector: (row: DraftContent) => row.createdAt,
			cell: (row) => formatDate(row.createdAt, false),
			sortable: true,
		},
	]

	const adminColumns = [
		...columns,
		{
			name: 'Site',
			selector: (row: DraftContent) => row.siteConfiguration?.name,
			minWidth: '20%',
		},
		{
			name: 'Loan Officer',
			selector: (row: DraftContent) =>
				row.loanOfficer
					? `${row.loanOfficer?.firstName} ${row.loanOfficer?.lastName}`
					: 'Unassigned',
		},
	]

	const getRouteName = (route: string) => {
		if (!route) return 'LOApply'

		const routes = [
			'Apply',
			'ApplyCoBorrower',
			'ApplySpanish',
			'Prequal',
			'PrequalCoBorrower',
		]
		return routes.includes(route) ? `LO${route}` : route
	}

	const showErrorMessage = (err: AxiosError<ApiError>) => {
		alert(
			err?.response?.data?.message || theme.api_messages.server_error,
			{
				severity: 'error',
			}
		)
	}

	const getLoanDraftMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.getLoanDraft(id),
		onSuccess: async (result) => {
			const draft = result?.data
			if (!draft) return
			if (draft.customData) {
				draft.customData = {
					targetHistory: [],
					routeName: getRouteName(draft.customData.routeName),
				}
			}
			ventiState.set(theme.storageKeys.loanDraft, draft)

			navigate(
				`/${navigationLinking[draft?.customData?.routeName as keyof typeof navigationLinking]}`
			)
		},
		onError: (err: AxiosError<ApiError>) => {
			showErrorMessage(err)
			setFetching(false)
		},
	})

	const handleRowCompleteClick = async (row: DraftContent) => {
		setFetching(true)
		await getLoanDraftMutation.mutateAsync(row.id)
	}

	const successMutation = async (message: string) => {
		alert(message, {
			severity: 'success',
		})
		await queryClient.invalidateQueries({
			queryKey: [queryKeys.loanDrafts],
		})
	}

	const deleteDraftMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteLoanDraft(id),
		onSuccess: async () => {
			await successMutation('The loan draft was successfully deleted')
		},
		onError: (err: AxiosError<ApiError>) => {
			setRemoveModalVisible(false)
			showErrorMessage(err)
		},
		onSettled: () => {
			setRemoveModalVisible(false)
			setSelectedRow(null)
			setRemoveModalVisible(false)
		},
	})

	const reassignLoanOfficerMutation = useMutation({
		mutationFn: (request: DraftLoanOfficerReassignRequest) =>
			TheBigPOSApi.reassignLoanOfficer(selectedRow!.id, request),
		onSuccess: async () => {
			await successMutation('The loan draft was successfully updated')
		},
		onError: (err: AxiosError<ApiError>) => {
			showErrorMessage(err)
		},
		onSettled: () => {
			setRemoveModalVisible(false)
			setSelectedRow(null)
			setRemoveModalVisible(false)
		},
	})

	const handleDeleteClick = async () => {
		if (!selectedRow) return
		await deleteDraftMutation.mutateAsync(selectedRow.id)
	}

	const handleRowDeleteClick = (row: DraftContent) => {
		setSelectedRow(row)
		setRemoveModalVisible(true)
	}

	const handleReassignSubmit = async (
		loanOfficer: BranchUser | null | undefined
	) => {
		if (!selectedRow) return
		const request = {
			loanOfficerID: loanOfficer?.id,
		} as DraftLoanOfficerReassignRequest
		await reassignLoanOfficerMutation.mutateAsync(request)
	}

	const handleRowReassignClick = async (row: DraftContent) => {
		setReassignLOModalOpen(true)
		setSelectedRow(row)
	}

	const handleRowSendToEncompassClick = async (row: DraftContent) => {
		setIsConfirmModalOpen(true)
		setSelectedRow(row)
	}

	const sendToEncompassMutation = useMutation({
		mutationFn: (request: LoanData) =>
			TheBigPOSApi.createLoan(request),
		onSuccess: async () => {
			await successMutation(
				formatMessage({ id: 'alerts.sent_to_los' })
			)
		},
		onError: (err: AxiosError<ApiError>) => {
			showErrorMessage(err)
		},
		onSettled: () => {
			setSelectedRow(null)
			setIsSendToLos(false)
			setIsConfirmModalOpen(false)
		},
	})

	let actionItems: ActionItem<DraftContent>[] = [
		{
			name: formatMessage({ id: 'drafts.send_to_los' }),
			onClick: (
				_e: React.MouseEvent<Element, MouseEvent>,
				row: DraftContent
			) => handleRowSendToEncompassClick(row),
		},
		{
			name: formatMessage({ id: 'global.delete' }),
			onClick: (
				_e: React.MouseEvent<Element, MouseEvent>,
				row: DraftContent
			) => handleRowDeleteClick(row),
		},
	]

	if (isAdmin) {
		actionItems = [
			{
				name: 'Reassign Loan Officer',
				onClick: (
					_e: React.MouseEvent<Element, MouseEvent>,
					row: DraftContent
				) => handleRowReassignClick(row),
			},
			...actionItems,
		]
	} else {
		actionItems = [
			{
				name: 'Complete',
				onClick: (
					e: React.MouseEvent<Element, MouseEvent>,
					row: DraftContent
				) => handleRowCompleteClick(row),
			},
			...actionItems,
		]
	}

	const handleConfirm = async () => {
		setIsSendToLos(true)
		if (!selectedRow) return

		const result = await TheBigPOSApi.getLoanDraft(
			selectedRow?.id || ''
		)

		if (!result.data) return

		sendToEncompassMutation.mutateAsync({
			...result.data.applicationPayload,
			BorrowerEmail: selectedRow.user.email,
			BorrowerFirstName: selectedRow.user.firstName,
			BorrowerLastName: selectedRow.user.lastName,
			loanOfficerEmail: selectedRow.loanOfficer?.email,
			draftId: selectedRow.id,
			siteConfigurationId: selectedRow.siteConfiguration?.id,
		})
	}

	return (
		<>
			<ModalRemoveRestoreRecordConfirm
				removeModalVisible={removeModalVisible}
				setRemoveModalVisible={setRemoveModalVisible}
				remove={handleDeleteClick}
				row={selectedRow}
				loading={deleteDraftMutation.isPending}
			/>
			<AssignLoanOfficerModal
				open={reassignLOModalOpen}
				onClose={() => setReassignLOModalOpen(false)}
				onSubmit={handleReassignSubmit}
				item={selectedRow?.loanOfficer as BranchUser}
			/>
			<ModalPleaseWait
				visible={fetching}
				message="Fetching loan information"
			/>
			<ConfirmModal
				open={isConfirmModalOpen}
				button={{
					loading: isSendToLos || sendToEncompassMutation.isPending,
					title: formatMessage({
						id: 'global.yes',
					}),
				}}
				onConfirm={handleConfirm}
				onClose={() => setIsConfirmModalOpen(false)}
				title={formatMessage({ id: 'modals.confirm_send_to_los' })}
			/>
			<DataTable
				data={result?.data?.rows || []}
				columns={isAdmin ? adminColumns : columns}
				title="Incomplete loans"
				progressPending={
					isFetching ||
					isRefetching ||
					isLoading ||
					isSendToLos ||
					sendToEncompassMutation.isPending
				}
				onRefreshClick={refetch}
				fixedHeader
				actionItems={actionItems}
				onSearchChange={handleSearchChange}
				onSort={handleSort}
				searchText={searchText}
				onClearSearchClick={handleClearSearchClick}
				defaultSortFieldId={sortBy}
				defaultSortAsc={sortDirection === 'asc'}
				onChangePage={handlePageChange}
				onChangeRowsPerPage={handleRowsPerPageChange}
				pagination
				paginationTotalRows={result?.data?.count}
				paginationServer
				searchFilters={
					isAdmin ? (
						<SearchFiltersLoanDraftsTab setFilters={setFilters} />
					) : null
				}
			/>
		</>
	)
}

export default LoanDrafts
