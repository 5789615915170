import React from 'react'
import LoanHeader from './loan/LoanHeader'
import { LoanContextProvider } from '../contexts/LoanContext'
import Page, { PageProps } from './Page'

const LoanPage = ({ children, ...rest }: PageProps) => {
	return (
		<LoanContextProvider>
			<Page {...rest}>
				<LoanHeader />
				<div className="px-5 overflow-auto">
					<main>{children}</main>
				</div>
			</Page>
		</LoanContextProvider>
	)
}

export default LoanPage
