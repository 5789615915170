import React, { ReactNode } from 'react'
import clsx from 'clsx'

type HeadingProps = {
	children: ReactNode
	size?: 'lg' | '2xl' | 'xl' | 'md' | 'sm'
	className?: string
	button?: ReactNode
}

const Heading = ({
	children,
	size = '2xl',
	className = undefined,
	button = null,
}: HeadingProps) => {
	return (
		<div className={clsx(button ? 'flex items-center' : undefined)}>
			<p
				className={clsx(
					size === 'lg' ? undefined : `text-${size}`,
					'font-rubik font-bold mr-4 dark:text-white',
					className
				)}
			>
				{children}
			</p>
			{button}
		</div>
	)
}

export default Heading
