export type mobileOsType =
	| 'android'
	| 'ios'
	| 'windows-phone'
	| 'unknown'

const useUserAgent = () => {
	const { userAgent } = navigator

	function getMobileOperatingSystem(): mobileOsType {
		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return 'windows-phone'
		}

		if (/android/i.test(userAgent) || /linux/i.test(userAgent)) {
			return 'android'
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent)) {
			return 'ios'
		}

		return 'unknown'
	}

	const mobileOs = getMobileOperatingSystem()

	return { mobileOs }
}

export default useUserAgent
