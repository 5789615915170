import { Api } from '@matech/thebigpos-sdk'
import { FixMeLater } from '../types'

const securityWorker = (data: FixMeLater) => {
	return { headers: data }
}

const TheBigPOSClient = new Api({
	baseURL:
		process.env.REACT_APP_POS_API_HOST || 'https://api.thebigpos.com',
	securityWorker,
})

export const setBearerToken = (token: string) => {
	TheBigPOSClient.instance.defaults.headers.common.Authorization = `Bearer ${token}`
}

export const setSiteConfiguration = (siteConfigurationId: string) => {
	TheBigPOSClient.instance.defaults.headers.common[
		'Site-Configuration-ID'
	] = siteConfigurationId
}

export const TheBigPOSApi = TheBigPOSClient.api

export default TheBigPOSClient
