import React from 'react'
import { LoadingBtn } from '../../../../components/Button'
import { useAlert } from '../../../../hooks'
import AlertBox from '../../../../components/AlertBox'
import { useMutation } from '@tanstack/react-query'
import { eventTypes } from '../../../../services/constants'
import { useMixpanel } from '../../../../hooks/useMixpanel'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'

const LoanTaskReminderAlert = ({
	loanId,
	outstandingTasksCount,
	className = undefined,
}) => {
	const { alert } = useAlert()
	const mixpanel = useMixpanel()

	const sendReminderMutation = useMutation({
		mutationFn: () =>
			TheBigPOSApi.sendOutstandingLoanTaskNotification(loanId),
	})

	const handleSendReminderNotificationClick = async () => {
		try {
			await sendReminderMutation.mutateAsync()
			alert('Task reminders sent')

			mixpanel.trackEvent(eventTypes.LOAN_TASK_REMINDER_SENT, {
				loanId,
			})
		} catch (e) {
			alert('There was a problem sending the reminder', {
				severity: 'error',
			})
		}
	}

	if (outstandingTasksCount === 0) {
		return null
	}

	return (
		<AlertBox severity="info" className={className}>
			<div className="md:flex md:flex-row md:items-center md:justify-between">
				<div>
					There are {outstandingTasksCount} outstanding tasks.
				</div>
				<div className="mt-2 md:mt-0">
					<LoadingBtn
						text="Send reminder"
						onClick={handleSendReminderNotificationClick}
						variant="outlined"
						loading={sendReminderMutation.isPending}
						disabled={sendReminderMutation.isPending}
						fullWidth={false}
					/>
				</div>
			</div>
		</AlertBox>
	)
}

export default LoanTaskReminderAlert
