import React, { SyntheticEvent, useState } from 'react'
import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import Page from '../../../components/Page'
import { a11yProps } from '../../../services/helper'
import withAuth from '../../../components/withAuth'
import TabPanel from '../../../components/TabPanel'
import RulesList from './RulesList'
import GlobalTasks from './GlobalTasks'

const Rules = () => {
	const themeMUI = useTheme()
	const [activeTab, setActiveTab] = useState(0)

	const handleTabChange = (
		event: SyntheticEvent,
		newValue: number
	) => {
		setActiveTab(newValue)
	}

	const handleChangeIndex = (index: number) => {
		setActiveTab(index)
	}

	return (
		<Page title="Business Rules" isFullWidth>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				aria-label="Tabs"
				onChange={handleTabChange}
				value={activeTab}
			>
				<Tab label="Business Rules" {...a11yProps(0)} />
				<Tab label="Global Tasks" {...a11yProps(1)} />
			</Tabs>
			<SwipeableViews
				axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={activeTab}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel
					value={activeTab}
					index={0}
					dir={themeMUI.direction}
				>
					<Card>
						<CardContent>
							<RulesList />
						</CardContent>
					</Card>
				</TabPanel>
				<TabPanel
					value={activeTab}
					index={1}
					dir={themeMUI.direction}
				>
					<Card>
						<CardContent>
							<GlobalTasks />
						</CardContent>
					</Card>
				</TabPanel>
			</SwipeableViews>
		</Page>
	)
}

export default withAuth(Rules)
