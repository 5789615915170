import React from 'react'
import {
	FormControl as MuiFormControl,
	FormControlProps as MuiFormControlProps,
} from '@mui/material'

export type FormControlProps = MuiFormControlProps

const FormControl = ({
	variant = 'standard',
	...rest
}: FormControlProps) => <MuiFormControl variant={variant} {...rest} />

export default FormControl
