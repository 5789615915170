import React, { useMemo, useState } from 'react'
import {
	DialogTitle,
	DialogContent,
	Dialog,
	DialogActions,
} from '@mui/material'
import { LoadingBtn } from '../Button'
import { useAlert } from '../../hooks'
import { getErrorMessage } from '../../services/helper'
import { automatedServices } from '../../services/helper'
import { useMutation } from '@tanstack/react-query'
import ASOTriggersForm from '../../forms/ASOTriggersForm'
import { useAppContext } from '../AppContext'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'

export const ModalAutomatedServices = ({
	visible,
	setVisible,
	loanData,
}) => {
	const { state } = useAppContext()
	const { siteConfig } = state
	const [selected, setSelected] = useState([])
	const [confirmationVisible, setConfirmationVisible] =
		useState(false)
	const [sending, setSending] = useState(false)
	const { alert } = useAlert()

	const updateLoanApplicationMutation = useMutation({
		mutationFn: async (data) =>
			TheBigPOSApi.updateLoan(loanData.LoanID, data),
	})

	const handleServiceCheckboxChange = (id) => {
		setSelected((current) => {
			if (current.includes(id))
				return current.filter((item) => item !== id)

			return [...current, id]
		})
	}

	const handleRunServices = async () => {
		try {
			const numServices = selected.length
			const additionalFields = {}
			selected.forEach((id) => {
				additionalFields[id] = 'Y'
			})

			setSending(true)
			await updateLoanApplicationMutation.mutateAsync({
				LoanID: loanData.LoanID,
				SiteConfigurationID: siteConfig.id,
				BorrowerEmail: loanData.BorrowerEmail,
				additionalFields,
			})
			setConfirmationVisible(true)
			alert(
				`Successfully triggered ${numServices} services. Please allow 2 to 3 minutes for each service to run.`
			)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		} finally {
			setSending(false)
		}
	}

	const handleCloseModal = () => {
		setVisible(false)
		setTimeout(() => {
			setSelected([])
			setConfirmationVisible(false)
		}, 300)
	}

	let runButtonText =
		selected.length === 0
			? `Select at Least 1 Service`
			: `Run ${selected.length} Selected Service${selected.length > 1 ? 's' : ''}`
	const closeButtonText = 'Close Window'

	const services = useMemo(() => {
		return automatedServices?.map((group) => ({
			...group,
			items: group.items.filter(
				(service) => siteConfig.asoSettings?.[service.settingId]
			),
		}))
	}, [automatedServices])

	if (!siteConfig.asoSettings?.enabled) return null

	return (
		<Dialog open={visible} aria-labelledby="responsive-dialog-title">
			<DialogTitle>
				{!confirmationVisible
					? `Which services would you like to run?`
					: `Successfully triggered ${selected.length} service${selected.length > 1 ? 's' : ''}`}
			</DialogTitle>
			<DialogContent>
				<div
					className={`absolute right-2 top-1 text-xl cursor-pointer `}
					role={'button'}
					title={`Close`}
					onClick={() => setVisible(false)}
				>
					x
				</div>
				<div className="w-full">
					{!confirmationVisible ? (
						<ASOTriggersForm
							selected={selected}
							setSelected={setSelected}
						/>
					) : (
						<div className={`w-full`}>
							<p className={`pb-6`}>
								<span className={`font-bold`}>All Services:</span>{' '}
								Please allow 2 to 3 minutes for each service to run.
							</p>
							<p className={`pb-6`}>
								<span className={`font-bold`}>
									Credit Determination & MI:
								</span>{' '}
								Check the documents module in the POS Portal to view
								all services have run successfully. MI values may need
								to be manually updated in the loan calculator
							</p>
							<p>
								<span className={`font-bold`}>Verifications:</span>{' '}
								Have your client check their email account for next
								steps
							</p>
						</div>
					)}
				</div>
			</DialogContent>
			<DialogActions className={`mr-5 mb-2`}>
				{sending && `Updating trigger fields... `}
				<LoadingBtn
					fullWidth={false}
					loading={sending}
					indicatorOnlyOnLoading
					onClick={
						!confirmationVisible
							? handleRunServices
							: handleCloseModal
					}
					disabled={selected.length === 0}
					text={
						!confirmationVisible ? runButtonText : closeButtonText
					}
				/>
			</DialogActions>
		</Dialog>
	)
}
