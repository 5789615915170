import React, { useMemo } from 'react'
import clsx from 'clsx'
import { Tooltip } from '@mui/material'
import { UserLoanTask } from '@matech/thebigpos-sdk'
import { formatDate } from '../../../../services/helper'

export type TaskStatusBadgeProps = {
	status: UserLoanTask['status']
	statusChangedDate?: string
	labelPrefix?: string
}
const TaskStatusBadge = ({
	status,
	statusChangedDate,
	labelPrefix = undefined,
}: TaskStatusBadgeProps) => {
	const getStatusName = (type: UserLoanTask['status']) => {
		const statusNames: Record<UserLoanTask['status'], string> = {
			Unknown: 'Unknown',
			Outstanding: 'Incomplete',
			Pending: 'Under Review',
			Completed: 'Completed',
			Rejected: 'Rejected',
		}

		return statusNames[type] || 'Unknown'
	}

	const taskStatusColors: Record<UserLoanTask['status'], string> = {
		Unknown: 'bg-red-400',
		Outstanding: 'bg-orange-400',
		Pending: 'bg-sky-400',
		Completed: 'bg-emerald-400',
		Rejected: 'bg-red-400',
	}

	const tooltipTitle = useMemo(() => {
		if (!statusChangedDate) {
			return null
		}

		const formattedDate = formatDate(statusChangedDate)

		return `${
			status === 'Completed' ? status : 'Updated'
		} on ${formattedDate}`
	}, [status, statusChangedDate])

	return (
		<Tooltip title={tooltipTitle} placement="right-end">
			<div
				className={clsx(
					taskStatusColors[status] || 'bg-gray-200',
					'px-2 py-1 text-white font-bold rounded'
				)}
			>
				{labelPrefix && `${labelPrefix} `}
				{getStatusName(status)}
			</div>
		</Tooltip>
	)
}

export default TaskStatusBadge
