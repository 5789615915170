import React, { useCallback, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
	BranchType,
	SiteConfigurationSearchCriteria,
	SiteConfigurationSummary,
} from '@matech/thebigpos-sdk'

import useSearch from '../../hooks/useSearch'
import usePagination from '../../hooks/usePagination'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'
import queryKeys from '../../services/queryKeys'
import DataTable, { DataTableProps } from '../../components/DataTable'
import Page from '../../components/Page'
import ConfirmDeleteModal from '../../components/modals/ConfirmDeleteModal'

export enum SiteConfigurationEntityType {
	Branch = 'Branch',
	LoanOfficer = 'LoanOfficer',
	Realtor = 'Realtor',
	Corporate = 'Corporate',
}

type SiteConfigurationPageProps = {
	entityType: SiteConfigurationEntityType
	onRemove: () => void
	removeModalVisible: boolean
	filters: {
		type: string | null
		brand: string | null
		branch: string | null
	}
	onRemoveModalVisible: (value: boolean) => void
	isRemovePending: boolean
	activeRow: SiteConfigurationSummary | null
	onActiveRowChange: (row: SiteConfigurationSummary) => void
	title: string
	onRowClicked: (row: SiteConfigurationSummary) => void
} & Omit<DataTableProps<SiteConfigurationSummary>, 'data'>

const SiteConfigurationPage = ({
	entityType,
	onRemove,
	isRemovePending,
	activeRow,
	title,
	filters,
	removeModalVisible,
	onRemoveModalVisible,
	onRowClicked,
	onActiveRowChange,
	...rest
}: SiteConfigurationPageProps) => {
	const { searchText, handleSearchChange, handleClearSearchClick } =
		useSearch({})
	const criteria = (function getCriteria() {
		let formattedFilters = {
			...filters,
			searchText,
			entityType,
			isActive: true,
		} as SiteConfigurationSearchCriteria
		if (filters.type === 'all') {
			formattedFilters = {
				...formattedFilters,
				branchType: null,
			}
		} else {
			formattedFilters = {
				...formattedFilters,
				branchType: filters.type as BranchType,
			}
		}
		if (filters.brand === 'all') {
			formattedFilters = { ...formattedFilters, brand: null }
		}
		if (filters.branch === 'all') {
			formattedFilters = { ...formattedFilters, branch: null }
		}

		return formattedFilters
	})()

	const {
		pageNumber,
		pageSize,
		sortBy,
		sortDirection,
		handleSort,
		handleRowsPerPageChange,
		handlePageChange,
	} = usePagination<SiteConfigurationSummary>({
		defaultSortBy: 'createdAt',
		defaultSortDirection: 'desc',
	})

	const {
		isFetching,
		isRefetching,
		data: result,
		refetch,
	} = useQuery({
		queryKey: [
			queryKeys.siteConfigurations,
			criteria,
			pageNumber,
			pageSize,
			sortBy,
			sortDirection,
		],
		queryFn: () =>
			TheBigPOSApi.searchSiteConfigurations(criteria, {
				pageNumber,
				pageSize,
				sortBy,
				sortDirection,
			}),
	})

	useEffect(() => {
		if (isRemovePending) {
			refetch()
		}
	}, [isRemovePending, refetch])

	const showRemoveModal = (row: SiteConfigurationSummary) => {
		onRemoveModalVisible(true)
		onActiveRowChange(row)
	}

	const handleHideIfDelete = (row: SiteConfigurationSummary) => {
		if (entityType === SiteConfigurationEntityType.LoanOfficer) {
			return row.loanOfficer?.deletedAt !== null
		}
		if (entityType === SiteConfigurationEntityType.Realtor) {
			return row.realtor?.deletedAt !== null
		}
		if (entityType === SiteConfigurationEntityType.Branch) {
			return row.branch?.deletedAt !== null
		}
		if (entityType === SiteConfigurationEntityType.Corporate) {
			return row.corporate?.deletedAt !== null
		}
		return false
	}

	const actionItems = [
		{
			name: 'Edit',
			onClick: (e: React.MouseEvent, row: SiteConfigurationSummary) =>
				onRowClicked(row),
		},
		{
			name: 'Delete',
			onClick: (e: React.MouseEvent, row: SiteConfigurationSummary) =>
				showRemoveModal(row),
			hideIf: (row: SiteConfigurationSummary) =>
				handleHideIfDelete(row),
		},
	]

	const getName = useCallback(
		(row: SiteConfigurationSummary | null) => {
			if (!row) return ''
			switch (entityType) {
				case SiteConfigurationEntityType.Branch:
					return row.branch?.name || ''
				case SiteConfigurationEntityType.LoanOfficer:
					return row.loanOfficer
						? `${row.loanOfficer.firstName || ''} ${row.loanOfficer.lastName || ''}`.trim()
						: ''
				case SiteConfigurationEntityType.Realtor:
					return row.realtor
						? `${row.realtor.firstName || ''} ${row.realtor.lastName || ''}`.trim()
						: ''
				case SiteConfigurationEntityType.Corporate:
					return row.corporate?.name || ''
				default:
					return undefined
			}
		},
		[entityType]
	)

	return (
		<Page title={title} isFullWidth>
			<div className="pl-5 pr-5 pb-10 h-full overflow-auto">
				<ConfirmDeleteModal
					open={removeModalVisible}
					onClose={() => onRemoveModalVisible(false)}
					onRemove={onRemove}
					name={getName(activeRow)}
					loading={isRemovePending}
				/>
				<DataTable<SiteConfigurationSummary>
					data={result?.data?.rows || []}
					{...rest}
					pagination
					defaultSortAsc={sortDirection === 'asc'}
					defaultSortFieldId={sortBy}
					progressPending={isFetching || isRefetching}
					title={title}
					onRefreshClick={refetch}
					actionItems={actionItems}
					keyField="id"
					onSort={handleSort}
					onSearchChange={handleSearchChange}
					searchText={searchText}
					onClearSearchClick={handleClearSearchClick}
					fixedHeader
					onChangePage={handlePageChange}
					onChangeRowsPerPage={handleRowsPerPageChange}
					onRowClicked={onRowClicked}
					paginationTotalRows={result?.data?.count}
					paginationServer
				/>
			</div>
		</Page>
	)
}

export default SiteConfigurationPage
