import { LoanQueue } from '@matech/thebigpos-sdk'
import React from 'react'
import { ExpanderComponentProps } from 'react-data-table-component'
import { useIntl } from 'react-intl'
import RowExpandedProperty from '../RowExpandedProperty'
import config from '../../config'
import { formatDate } from '../../services/helper'
import useUser from '../../hooks/useUser'

const LoanQueueExpandedRow = ({
	data: loanQueueEntry,
}: ExpanderComponentProps<LoanQueue>) => {
	const { isSuperAdmin } = useUser()
	const { formatMessage } = useIntl()
	const { loan, user, details, createdAt, reason, status, jobID } =
		loanQueueEntry
	const { firstName, lastName, email } = user
	const fullName = `${firstName} ${lastName}`

	return (
		<div className="p-5 md:grid md:grid-cols-2">
			{loan && (
				<RowExpandedProperty
					label={formatMessage({ id: 'loans.loan_id' })}
					value={loan.id}
				/>
			)}
			<RowExpandedProperty
				label={formatMessage({ id: 'global.details' })}
				value={details}
			/>
			<RowExpandedProperty
				label={formatMessage({ id: 'global.username' })}
				value={`${fullName} (${email})`}
			/>
			{isSuperAdmin && (
				<RowExpandedProperty
					label={formatMessage({ id: 'loan_queue.job_id' })}
					value={
						<a
							href={`${config.theme.api.host.production}/hangfire/jobs/details/${jobID}`}
							target="_blank"
							rel="noreferrer"
						>
							{jobID}
						</a>
					}
				/>
			)}
			<RowExpandedProperty
				label={formatMessage({ id: 'global.reason' })}
				value={reason}
			/>
			<RowExpandedProperty
				label={formatMessage({ id: 'global.status' })}
				value={status}
			/>
			<RowExpandedProperty
				label={formatMessage({ id: 'global.created' })}
				value={formatDate(createdAt, true)}
			/>
		</div>
	)
}

export default LoanQueueExpandedRow
