import { useState, useEffect, useMemo } from 'react'
import {
	generatePath,
	useNavigate,
	useParams,
} from 'react-router-dom'
import { useVenti } from 'venti'
import { fromAddress } from 'react-geocode'
import { Icon, Divider, Tabs, Tab } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useTheme } from '@mui/material/styles'
import SwipeableViews from 'react-swipeable-views'
import { useAppContext } from '../../../components/AppContext'
import { applyMask } from '../../../services/utils'
import { getErrorMessage, a11yProps } from '../../../services/helper'
import { navigationLinking } from '../../../services/navigation'
import { Button, LoadingBtn } from '../../../components/Button'
import { LOCalculatorForm } from '../../../components/lo/LOCalculatorForm'
import { LOLockedCalculatorForm } from '../../../components/lo/LOLockedCalculatorForm'
import { Roles } from '../../../services/client'
import { getTheme } from '../../../config'
import { useAlert, useWindowSize } from '../../../hooks'
import TabPanel from '../../../components/TabPanel'
import LOCalculatorResults from '../../../components/lo/LOCalculatorResults'
import LOLoanComparisonForm from '../../../components/lo/LOLoanComparisonForm'
import { clone } from 'lodash'
import ModalGenerateLoanDocumentSelector from '../../../components/modals/ModalGenerateLoanDocumentSelector'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'
import { ModalAutomatedServices } from '../../../components/modals/ModalAutomatedServices'
import useUser from '../../../hooks/useUser'
import ErrorList from '../../../components/ErrorList'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import { useLoanContext } from '../../../contexts/LoanContext'
import { useLoanSignalRContext } from '../../../contexts/LoanSignalRContext'
import AlertBox from '../../../components/AlertBox'
import Heading from '../../../components/Heading'

const theme = getTheme()

const initialFormState = {
	loanID: '',
	loanAmount: '',
	totalMortgageAmount: '',
	propertyValue: '',
	downPaymentAmount: '',
	downPaymentPercent: '',
	propertyType: '',
	loanType: '',
	zipCode: '',
	city: '',
	county: '',
	state: '',
	loanPurpose: '',
	propertyOccupancy: '',
	escrow: '',
	loanTerm1: '',
	loanTerm2: '',
	creditScore: '',
	taxes: '',
	insurance: '',
	borrowerIncome: '',
	loanProgram: '',
	rate: '',
	monthlyPayment: '',
	principleAndInterestPITIField: '',
	dtiFront: '',
	dtiBack: '',
	ltvFront: '',
	ltvBack: null,
	totalCashtoClose: '',
	apr: '',
	flood: '',
	hoa: '',
	miFactor: '',
	mi: '', // read only
	totalAssets: '', // read only
	loanLocked: false, // read only
	canGeneratePreQual: false, // read only
	canGeneratePreApproval: false, // read only
	lienType: '',
	preApprovalNotes: '',
	additionalPreApprovalNotes: '',
}

export default function LoanCalculatorContent({ className = '' }) {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const navigate = useNavigate()
	const [width] = useWindowSize()
	const themeMUI = useTheme()
	const mixpanel = useMixpanel()
	const { loanData, loan, refetch, isLoading } = useLoanContext()

	const { siteConfig, user } = state
	const { isLoanAdmin } = useUser()
	const ventiState = useVenti()

	const { loanId } = useParams()
	const [pleaseWaitText, setPleaseWaitText] = useState('')
	const [counties, setCounties] = useState([])
	const [zipcodeResults, setZipcodeResults] = useState([])
	const [selectedTab, setSelectedTab] = useState(0)
	const [
		automatedServicesModalVisible,
		setAutomatedServicesModalVisible,
	] = useState(false)
	const [
		documentTemplateSelectorVisible,
		setDocumentTemplateSelectorVisible,
	] = useState(false)
	const [documentTemplates, setDocumentTemplates] = useState([])
	const [apiErrors, setApiErrors] = useState([])
	const [formData, setFormData] = useState(clone(initialFormState))
	const { useSignalREffect } = useLoanSignalRContext()
	const [isLocked, setIsLocked] = useState(
		loan?.isLocked || formData?.loanLocked || false
	)
	const [selectedDocumentType, setSelectedDocumentType] = useState('')

	useEffect(() => {
		setIsLocked(loan?.isLocked)
	}, [loan])

	const handleLoanSave = (updatedLoan) => {
		setIsLocked(updatedLoan.isLocked)
	}

	useSignalREffect('LoanSave', handleLoanSave)

	const tabRoutes = [
		{
			key: 'loanCalculatorAdjustments',
			title: 'Adjustments',
			index: 0,
		},
		{
			key: 'loanComparison',
			title: 'Loan Comparison',
			index: 1,
			disabled: isLocked,
		},
	]

	const isLoOrBM = useMemo(() => {
		return [Roles.loanOfficer, Roles.branchManager].includes(
			user.role
		)
	}, [user])

	const pricingButtonDisabled =
		!formData.loanAmount ||
		!formData.propertyValue ||
		!formData.propertyType ||
		!formData.loanPurpose ||
		!formData.loanType ||
		(!formData.loanTerm2 && !formData.loanTerm1) ||
		!formData.propertyOccupancy

	useEffect(() => {
		if (loanId) {
			;(async () => {
				try {
					setPleaseWaitText(
						`Retrieving Calculations from ${siteConfig.los?.name || 'Encompass'}`
					)
					const getLoanCalculatorResponse =
						await TheBigPOSApi.getLoanCalculator(loanId)

					const data = getLoanCalculatorResponse.data

					if (
						data.zipCode &&
						(!data.county || !data.city || !data.state)
					) {
						try {
							const { results } = await fromAddress(data.zipCode)
							const addressComponents = results[0].address_components
							if (results.length > 1) {
								setCounties(
									results.map((result) =>
										result.address_components.filter((c) =>
											c.types.includes('administrative_area_level_2')
										)
									)
								)
							}
							if (!data.county)
								data.county =
									addressComponents
										.find((comp) =>
											comp.types.includes(
												'administrative_area_level_2'
											)
										)
										?.long_name.replace(' County', '') || ''
							if (!data.city)
								data.city =
									addressComponents.find(
										(comp) =>
											comp.types.includes('locality') ||
											comp.types.includes('political')
									)?.long_name || ''
							if (!data.state)
								data.state =
									addressComponents.find((comp) =>
										comp.types.includes('administrative_area_level_1')
									).short_name || ''
						} catch (e) {
							alert(
								`Geocode failed for zipcode ${data.zipCode}. Check console for error.`,
								{ severity: 'warning' }
							)
						}
					}

					setFormData({
						...formData,
						...{
							...data,
							downPaymentAmount: applyMask(
								data.downPaymentAmount.replace('.00', ''),
								'currency'
							),
							downPaymentPercent: applyMask(
								data.downPaymentPercent,
								'percent'
							),
							loanAmount: applyMask(
								data.loanAmount.replace('.00', ''),
								'currency'
							),
							propertyValue: applyMask(
								data.propertyValue.replace('.00', ''),
								'currency'
							),
							taxes: applyMask(data.taxes, 'fullCurrency'),
							insurance: applyMask(data.insurance, 'fullCurrency'),
							borrowerIncome: applyMask(
								data.borrowerIncome,
								'fullCurrency'
							),
							totalAssets: applyMask(
								data.totalAssets,
								'fullCurrency'
							),
							lienType: data.lienType || 'First Lien',
						},
					})
				} catch (e) {
					ventiState.set(theme.storageKeys.errorObject, {
						message: getErrorMessage(e),
					})
				} finally {
					setPleaseWaitText('')
				}
			})()
		}
	}, [loanId])

	const handleUpdateSubmit = async () => {
		try {
			setApiErrors([])
			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				`Updating & Retrieving calculations from ${siteConfig.los?.name || 'Encompass'}`
			)
			const runLoanCalculatorResponse =
				await TheBigPOSApi.runLoanCalculator(loanId, formData)
			const data = runLoanCalculatorResponse.data
			mixpanel.trackEvent(eventTypes.CALCULATE_LOAN_PAYMENT, {
				loanId,
			})
			setFormData((current) => ({
				...current,
				...{
					...data,
					downPaymentAmount: applyMask(
						data.downPaymentAmount.replace('.00', ''),
						'currency'
					),
					downPaymentPercent: applyMask(
						data.downPaymentPercent,
						'percent'
					),
					loanAmount: applyMask(
						data.loanAmount.replace('.00', ''),
						'currency'
					),
					propertyValue: applyMask(
						data.propertyValue.replace('.00', ''),
						'currency'
					),
					taxes: applyMask(data.taxes, 'fullCurrency'),
					insurance: applyMask(data.insurance, 'fullCurrency'),
					borrowerIncome: applyMask(
						data.borrowerIncome,
						'fullCurrency'
					),
					totalAssets: applyMask(data.totalAssets, 'fullCurrency'),
				},
			}))

			await refetch()
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, '')

			if (e.status === 422) {
				setApiErrors(e.data?.errors)
			} else {
				ventiState.set(theme.storageKeys.errorObject, {
					message: getErrorMessage(e),
				})
			}
		}
	}

	const handleZipCodeBlur = async (event) => {
		const val = event.target.value
		fromAddress(val)
			.then(({ results }) => {
				if (results.length > 1) {
					const data = []
					results.forEach((result) => {
						data.push({
							city:
								result.address_components.find(
									(comp) =>
										comp.types.includes('locality') ||
										comp.types.includes('political')
								)?.long_name || '',
							county:
								result.address_components
									.find((comp) =>
										comp.types.includes('administrative_area_level_2')
									)
									?.long_name.replace(' County', '') || '',
							state:
								result.address_components.find((comp) =>
									comp.types.includes('administrative_area_level_1')
								)?.short_name || '',
						})
					})
					setZipcodeResults(data)
					setCounties(
						results.map((result) =>
							result.address_components
								.find((comp) =>
									comp.types.includes('administrative_area_level_2')
								)
								?.long_name.replace(' County', '')
						)
					)
				} else {
					setCounties([])
				}

				const addressComponents = results[0].address_components
				setFormData((current) => ({
					...current,
					city:
						addressComponents.find(
							(comp) =>
								comp.types.includes('locality') ||
								comp.types.includes('political')
						)?.long_name || '',
					county:
						addressComponents
							.find((comp) =>
								comp.types.includes('administrative_area_level_2')
							)
							?.long_name.replace(' County', '') || '',
					state:
						addressComponents.find((comp) =>
							comp.types.includes('administrative_area_level_1')
						)?.short_name || '',
				}))
			})
			.catch(() => {
				alert('Invalid Zipcode', { severity: 'error' })
			})
	}

	const handleGenerateLetter = async (id) => {
		setDocumentTemplateSelectorVisible(false)
		ventiState.set(
			theme.storageKeys.pleaseWaitMessage,
			`Please be patient while the document is generated`
		)
		try {
			await handleUpdateSubmit()
			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				`Please be patient while the document is generated`
			)
			const createLegacyLoanDocumentResponse =
				await TheBigPOSApi.createLegacyLoanDocument({
					templateID: id,
					loanID: loanId,
					siteConfigurationID: siteConfig.id,
					preview: false,
				})

			const letter = createLegacyLoanDocumentResponse.data

			ventiState.set(theme.storageKeys.document, {
				...letter,
				extension: letter.extension.replace('.', ''),
			})

			ventiState.set(
				theme.storageKeys.pleaseWaitMessage,
				`Fetching loan documents`
			)

			const getTaskDocumentByLoanResponse =
				await TheBigPOSApi.getTaskDocumentsByLoan(loanId, {
					includeBase64: false,
				})

			await ventiState.set(
				theme.storageKeys.loanDocs,
				getTaskDocumentByLoanResponse.data
			)
			ventiState.set(theme.storageKeys.pleaseWaitMessage, ``)

			const navigatePath = generatePath(
				`/${navigationLinking.Documents}`,
				{ loanId }
			)
			navigate(navigatePath)
		} catch (e) {
			ventiState.set(theme.storageKeys.pleaseWaitMessage, ``)
			ventiState.set(theme.storageKeys.errorObject, {
				message: getErrorMessage(e),
			})
		}
	}

	const handleOpenLOConnectServices = () => {
		window.open(
			`https://encompassloconnect.com/pipeline/${loanId.slice(1, -1)}/services`,
			'_blank',
			'noopener,noreferrer'
		)
		mixpanel.trackEvent(eventTypes.CREDIT_AUS_PRICING, {
			loanId,
		})
	}

	const handleTabChange = (event, value) => {
		setSelectedTab(value)
	}

	const handleChangeIndex = (index) => {
		setSelectedTab(index)
	}

	const handleSelectDocumentTemplateType = async (type) => {
		setSelectedDocumentType(type)
		setDocumentTemplateSelectorVisible(true)
	}

	const handleCountyChange = (val) => {
		const data = zipcodeResults.find((r) => r.county === val)
		setFormData((current) => ({ ...current, ...data }))
	}

	const handleCloseModalGenerateLoanDocumentSelector = () => {
		setDocumentTemplateSelectorVisible(false)
	}

	return (
		<>
			<div className={className}>
				<Heading className="my-2">Loan Calculator</Heading>

				{isLocked && (
					<AlertBox severity="warning" className="mb-4">
						The loan is currently in use in Encompass. The calculator
						cannot be used until the user exits the loan.
					</AlertBox>
				)}

				{isLoOrBM && (
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						value={selectedTab}
						onChange={handleTabChange}
						aria-label="Tabs"
					>
						{tabRoutes.map((route) => (
							<Tab
								value={route.index}
								label={route.title}
								key={route.key}
								disabled={route.disabled}
								{...a11yProps(route.index)}
							/>
						))}
					</Tabs>
				)}
				<SwipeableViews
					axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
					index={selectedTab}
					onChangeIndex={handleChangeIndex}
				>
					<TabPanel
						value={selectedTab}
						index={0}
						dir={themeMUI.direction}
					>
						<div className="flex flex-col md:flex-row">
							<div className="flex flex-col grow">
								<div className="flex flex-row items-center mb-4">
									{!isLoOrBM && (
										<p className="text-xl font-rubik font-bold mr-2 dark:text-white">
											Adjustments
										</p>
									)}
									{formData.loanLocked && (
										<Icon
											aria-label="upload"
											sx={{ height: 40, width: 24 }}
										>
											<LockOutlinedIcon />
										</Icon>
									)}
								</div>
								<ErrorList errors={apiErrors} className="mb-4 mr-3" />
								{isLoOrBM ? (
									<LOCalculatorForm
										loanLocked={formData.loanLocked}
										submitAction={handleUpdateSubmit}
										handleZipCodeBlur={handleZipCodeBlur}
										counties={counties}
										handleCountyChange={handleCountyChange}
										formData={formData}
										setFormData={setFormData}
										width={width}
									/>
								) : (
									<LOLockedCalculatorForm
										loanLocked={formData.loanLocked}
										submitAction={handleUpdateSubmit}
										handleZipCodeBlur={handleZipCodeBlur}
										counties={counties}
										handleCountyChange={handleCountyChange}
										formData={formData}
										setFormData={setFormData}
										width={width}
									/>
								)}
							</div>
							<Divider orientation="vertical" flexItem />
							<div className="flex flex-col md:ml-2 grow items-center">
								<LOCalculatorResults
									width={width}
									formData={formData}
								/>
								<LoadingBtn
									id={`LOCalculatorFormGetRateButton`}
									text={
										formData.loanLocked
											? `Loan is Locked`
											: `Update Loan & Calculate Payment`
									}
									onClick={handleUpdateSubmit}
									loading={!!pleaseWaitText}
									disabled={
										!!pleaseWaitText ||
										pricingButtonDisabled ||
										isLocked
									}
									variant="contained"
									style={{
										marginBottom: 12,
										marginTop: 12,
										width: 280,
									}}
								/>
								{isLoanAdmin &&
									siteConfig.enabledServices.encompassWeb && (
										<LoadingBtn
											id={`LOCalculatorFormVOCButton`}
											text="Encompass Web Services"
											onClick={handleOpenLOConnectServices}
											loading={!!pleaseWaitText}
											disabled={!!pleaseWaitText || isLocked}
											variant="contained"
											style={{ marginBottom: 12, width: 280 }}
										/>
									)}
								{formData.loanPurpose === 'Purchase' && (
									<>
										<Button
											id={`LOCalculatorFormGeneratePrequalLetterButton`}
											text="Generate Prequal Letter"
											onClick={() =>
												handleSelectDocumentTemplateType(
													'PrequalLetter'
												)
											}
											disabled={
												!formData.canGeneratePreQual || isLocked
											}
											variant="contained"
											style={{ marginBottom: 12, width: 280 }}
										/>
										<Button
											id={`LOCalculatorFormGeneratePreapprovalLetterButton`}
											text="Generate Preapproval Letter"
											onClick={() =>
												handleSelectDocumentTemplateType(
													'PreapprovalLetter'
												)
											}
											disabled={
												!formData.canGeneratePreApproval || isLocked
											}
											variant="contained"
											style={{ marginBottom: 12, width: 280 }}
										/>
									</>
								)}
								{siteConfig.asoSettings?.enabled && isLoanAdmin && (
									<LoadingBtn
										id={`LOCalculatorAutomatedServicesButton`}
										text="Mobile Service Orders"
										onClick={() =>
											setAutomatedServicesModalVisible(true)
										}
										loading={!!pleaseWaitText || isLoading}
										disabled={!loanData?.LoanID || isLocked}
										variant="contained"
										style={{ marginBottom: 12, width: 280 }}
									/>
								)}
							</div>
						</div>
					</TabPanel>
					<TabPanel
						value={selectedTab}
						index={1}
						dir={themeMUI.direction}
					>
						<ErrorList errors={apiErrors} className="mb-4 mr-3" />
						<LOLoanComparisonForm
							loanLocked={formData.loanLocked}
							submitAction={handleUpdateSubmit}
							handleZipCodeBlur={handleZipCodeBlur}
							handleCountyChange={handleCountyChange}
							counties={counties}
							formData={formData}
							setFormData={setFormData}
							buttonDisabled={pricingButtonDisabled}
							loading={!!pleaseWaitText}
							width={width}
						/>
					</TabPanel>
				</SwipeableViews>
			</div>
			<ModalGenerateLoanDocumentSelector
				open={documentTemplateSelectorVisible}
				onClose={handleCloseModalGenerateLoanDocumentSelector}
				onSubmit={handleGenerateLetter}
				type={selectedDocumentType}
			/>
			<ModalAutomatedServices
				visible={automatedServicesModalVisible}
				setVisible={setAutomatedServicesModalVisible}
				loanData={loanData}
			/>
		</>
	)
}

export { initialFormState }
