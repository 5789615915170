export default {
	global: {
		yes: 'Sí',
		no: 'No',
		email: 'Correo electrónico',
		password: 'Contraseña',
		go_back: 'Volver',
		resend: 'Reenviar',
		view: 'Ver',
		delete: 'Eliminar',
		created: 'Creado',
		status: 'Estado',
		reason: 'Razón',
		type: 'Tipo',
		details: 'Detalles',
		username: 'Nombre de usuario',
		retry: 'Rever',
		data: 'Datos',
		save: 'Guardar',
		verify: 'Verificar',
		reverify: 'Reverificar',
		upload: 'Subir',
		answer: 'Responder',
		close: 'Cerrar',
		publish: 'Publicar',
		unpublish: 'Despublicar',
	},
	auth: {
		sign_in: 'Iniciar sesión',
		dont_have_an_account: '¿No tienes una cuenta?',
		check_inbox_for_invite_from_loan_officer:
			'Por favor, revisa tu bandeja de entrada para una invitación de tu Oficial de Préstamos',
		forgot_my_password: 'Olvidé mi contraseña',
		existing_user_account_detected:
			'Se detectó una cuenta de usuario existente',
		sign_in_to_account: 'Inicia sesión en tu cuenta',
		already_have_an_account: '¿Ya tienes una cuenta?',
	},
	loans: {
		loan_id: 'ID del préstamo',
		not_assigned: 'No asignado',
	},
	loan_queue: {
		loan_queue: 'Cola de préstamos',
		loan_queue_record: 'Registro de cola de préstamos',
		type: {
			new_loan: 'Nuevo préstamo',
			new_cob_loan: 'Nuevo préstamo con co-prestatario',
			loan_update: 'Actualizar solicitud',
			field_update: 'Actualizar campo',
			document_upload: 'Subir documento',
			new_buckets: 'Nuevos contenedores',
		},
		job_id: 'ID de trabajo',
	},
	users: {
		loan_officer: 'Oficial de préstamos',
	},
	validation: {
		email: {
			required: 'El correo electrónico es obligatorio',
			invalid: 'El correo electrónico no es válido',
		},
		password: {
			required: 'La contraseña es obligatoria',
			minLength:
				'La contraseña debe tener al menos {minLength} caracteres',
			create: 'Crear contraseña',
			confirm: 'Confirmar contraseña',
		},
		phone: {
			invalid: 'El número de teléfono no es válido.',
		},
	},
	errors: {
		general: 'Hubo un error al procesar la solicitud',
		error_loading:
			'Hubo un problema al recuperar la información solicitada',
		invalid_credentials: 'Usuario y/o contraseña inválidos',
		account_doesnt_have_access_to_env:
			'Tu cuenta no tiene acceso a este entorno',
		error_retrieving_auth:
			'Error al recuperar la información de autenticación',
		account_already_exists:
			'Ya existe una cuenta con esta dirección de correo electrónico',
		unexpected_error: 'Ha ocurrido un error inesperado.',
	},
	landing: {
		mortgage_rates: 'Tasas hipotecarias',
		how_to_apply: '¿Cómo te gustaría aplicar?',
		apply_now: 'Aplicar ahora',
		what_would_you_like_today: '¿Qué te gustaría hacer hoy?',
		check_out_todays_rates: 'Consulta las tarifas de hoy',
		get_your_rate: 'Consigue tu tarifa',
		customer_testimonials: 'Testimonios de clientes',
	},
	legal: {
		website: 'Sitio web',
		contact_us: 'Contacta con nosotros',
		privacy_policy: 'Política de privacidad',
		terms: 'Condiciones de Uso',
		license_info: 'Información de licencia',
	},
	create_account: {
		title: 'Crear Cuenta',
		create_account: 'Crear una cuenta',
		please_wait: 'Espere mientras recuperamos su invitación.',
		invite_expired: 'La invitación ha caducado',
		request_new:
			'Solicite una nueva invitación a su oficial de préstamos',
		first_name: 'El primer nombre',
		last_name: 'El apellido',
		sign_up: 'Inscribirse',
	},
	mortgage: {
		fixed_fifteen_years: 'Fija a 15 Años',
		conventional_30_years: 'Convencional a 30 años',
		todays_rates: 'Tarifas de Hoy',
		fixed: 'Tijas',
		conventional: 'Convencional',
	},
	alerts: {
		save_success: 'El {name} se actualizó correctamente',
		delete_success: 'El {name} se eliminó correctamente',
		sent_to_los:
			'El borrador del préstamo se ha enviado con éxito al LOS',
		pop_up_blocker_enabled:
			'¡El bloqueador de elementos emergentes está habilitado! Agregue este sitio a su lista de excepciones e intente nuevamente.',
	},
	task_verification: {
		default_button_title: 'Verifícame',
		manual_upload_button_title: 'Carga Manual',
		loan_locked_notice:
			'Tu préstamo está actualmente en uso. Una vez que se actualice, se enviarán tus mensajes de verificación.',
		sucess:
			'¡Éxito! Por favor, revisa tu bandeja de SMS o correo electrónico para los próximos pasos.',
		task_verification_copy_by_type: {
			VerificationOfAssets: {
				heading: 'Verifica tus activos financieros',
				body: `Haz clic en "Iniciar verificación" para recibir un mensaje de texto y un correo electrónico que te guiarán a través de la verificación de tus activos. Estos mensajes te ayudarán a iniciar sesión en tu cuenta bancaria en línea y seleccionar las cuentas que deseas compartir. Alternativamente, puedes subir tus estados de cuenta bancarios manualmente.`,
				button_title: `Iniciar verificación`,
			},
			VerificationOfIncome: {
				heading: 'Verifica tus ingresos',
				body: `Haz clic en "Iniciar verificación" para recibir un mensaje de texto y un correo electrónico que te guiarán a través de la verificación de tus INGRESOS. Estos mensajes te ayudarán a iniciar sesión en tu cuenta bancaria en línea y seleccionar las cuentas que deseas compartir. Alternativamente, puedes subir tus estados de cuenta bancarios manualmente.`,
				button_title: `Iniciar verificación`,
			},
			VerificationOfEmployment: {
				heading: 'Verifica tu empleo',
				body: `Haz clic en "Iniciar verificación" para recibir un mensaje de texto y un correo electrónico que te guiarán a través de la verificación de tu EMPLEO. Estos mensajes te ayudarán a iniciar sesión en tu cuenta bancaria en línea y seleccionar las cuentas que deseas compartir. Alternativamente, puedes subir tus estados de cuenta bancarios manualmente.`,
				button_title: `Iniciar verificación`,
			},
			VerificationOfIncomeAndEmployment: {
				heading: 'Verifica tus ingresos y empleo',
				body: `Haz clic en "Iniciar verificación" para recibir un mensaje de texto y un correo electrónico que te guiarán a través de la verificación de tus INGRESOS Y EMPLEO. Estos mensajes te ayudarán a iniciar sesión en tu cuenta bancaria en línea y seleccionar las cuentas que deseas compartir. Alternativamente, puedes subir tus estados de cuenta bancarios manualmente.`,
				button_title: `Iniciar verificación`,
			},
		},
	},
	task_form: {
		weight_helper_text:
			'El peso se utiliza para ordenar. Cuanto menor sea el número, más arriba se mostrará en la lista.',
		weight_label: 'Peso',
	},
	templates: {
		delete_version_prompt:
			'¿Estás seguro de que deseas eliminar esta versión?',
		delete_version: 'Eliminar versión',
		delete_selected_version: 'Eliminar versión seleccionada',
		save_as_new_version: 'Guardar como nueva versión',
	},
	remove_record: {
		permanent_deletion: 'Eliminación Permanente',
		permanent_delete:
			'¿Eliminar permanentemente "{value}" y todos los datos asociados?',
		delete: 'Eliminar',
		cancel: 'Cancelar',
		confirm_delete: 'Confirmar Eliminación',
		confirm_permanent_deletion: 'Confirmar Eliminación Permanente',
		confirm_reactivate: 'Confirmar Reactivación',
		remove: 'Remover',
		restore: 'Restaurar',
		confirm_deletion: 'Sí, Eliminarlo',
		confirm_restore: 'Sí, Restaurarlo',
	},
	warnings: {
		no_revert_possible: 'ADVERTENCIA: ESTO NO SE PUEDE DESHACER',
		accept_no_revert_possible:
			'Al marcar esta casilla, confirmo que entiendo que esta acción no se puede deshacer.',
	},
	modals: {
		confirm_send_to_los: '¿Estás seguro de que deseas enviar a LOS?',
	},
	drafts: {
		send_to_los: 'Solicitud de préstamo',
	},
	sso: {
		sso_integrations: 'Integraciones SSO',
		download_cc_metadata_file:
			'Descargar metadatos de Consumer Connect',
		download_bpos_metadata_file: 'Descargar metadatos de POS',
	},
}
