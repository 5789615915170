import React from 'react'

export const LoanDetailsCard = ({ data = [], title, loading }) => {
	return (
		<div>
			<div>
				{!loading && (
					<p className="font-black font-rubik mx-5 mt-8 dark:text-white">
						{title}
					</p>
				)}

				<div className="md:flex md:flex-row md:justify-start md:flex-wrap w-full my-8">
					{data?.map((item, index) =>
						item?.value && Array.isArray(item.value) ? (
							<div
								className="flex flex-col md:flex-row md:flex-wrap w-full mb-5 mx-5"
								key={index}
							>
								<p className="pt-1 font-bold font-rubik text-neutral-400 w-full dark:text-slate-500">
									{item.title}
								</p>
								{item.value.map((subItem, subIndex) => (
									<div className="w-2/5 mb-5 mx-5" key={subIndex}>
										<p className="pt-1 font-rubik font-bold text-neutral-400 dark:text-slate-500">
											{subItem.title}
										</p>
										<p className="font-bold font-rubik pt-4 text-gray-700 dark:text-white">
											{(subItem.value && subItem.value.toString()) ||
												'-'}
										</p>
									</div>
								))}
							</div>
						) : (
							<div key={index} className="w-2/5 mb-5 mx-5">
								<p className="pt-1 font-rubik font-bold text-neutral-400 dark:text-slate-500">
									{item.title}
								</p>
								<p className="font-bold font-rubik pt-4 text-gray-700 dark:text-white">
									{(item.value && item.value.toString()) || '-'}
								</p>
							</div>
						)
					)}
				</div>
			</div>
		</div>
	)
}
