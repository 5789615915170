import ApplicationPNG from 'assets/lordicons/Application.png'
import ApplicationGIF from 'assets/lordicons/ApplicationAnimated.gif'
import CalendarPNG from 'assets/lordicons/Calendar.png'
import CalendarGIF from 'assets/lordicons/CalendarAnimated.gif'
import PhonePNG from 'assets/lordicons/Phone.png'
import PhoneGIF from 'assets/lordicons/PhoneAnimated.gif'
import PrequalPNG from 'assets/lordicons/Questionnaire.png'
import PrequalGIF from 'assets/lordicons/QuestionnaireAnimated.gif'
import DollarCoinPNG from 'assets/lordicons/DollarCoin.png'
import DollarCoinGIF from 'assets/lordicons/DollarCoinAnimated.gif'
import QuickPricerPNG from 'assets/lordicons/QuickPricer.png'
import QuickPricerGIF from 'assets/lordicons/QuickPricer.gif'
import ListPNG from 'assets/lordicons/List.png'
import ListGIF from 'assets/lordicons/ListAnimated.gif'
import RulesPNG from 'assets/lordicons/Rules.png'
import RulesGIF from 'assets/lordicons/RulesAnimated.gif'
import PaymentPNG from 'assets/lordicons/Payment.png'
import PaymentGIF from 'assets/lordicons/PaymentAnimated.gif'
import LoanApplicationsPNG from 'assets/lordicons/LoanApplications.png'
import LoanApplicationsGIF from 'assets/lordicons/LoanApplicationsAnimated.gif'
import UserPNG from 'assets/lordicons/User.png'
import UserGIF from 'assets/lordicons/UserAnimated.gif'
import SignaturePNG from 'assets/lordicons/Signature.png'
import SignatureGIF from 'assets/lordicons/SignatureAnimated.gif'
import DocumentsPNG from 'assets/lordicons/Documents.png'
import CalculatorPNG from 'assets/lordicons/Calculator.png'
import AddCoBorrowerPNG from 'assets/lordicons/AddCoBorrower.png'
import LOConnectPNG from 'assets/lordicons/LOConnect.png'
import DocumentsGIF from 'assets/lordicons/DocumentsAnimated.gif'
import CalculatorGIF from 'assets/lordicons/CalculatorAnimated.gif'
import AddCoBorrowerGIF from 'assets/lordicons/AddCoBorrowerAnimated.gif'
import LOConnectGIF from 'assets/lordicons/LOConnectAnimated.gif'
import SettingsPNG from 'assets/lordicons/Settings.png'
import BrandsPNG from 'assets/lordicons/Brands.png'
import LocationPinPNG from 'assets/lordicons/LocationPin.png'
import LoanOfficersPNG from 'assets/lordicons/LoanOfficers.png'
import UsersPNG from 'assets/lordicons/Users.png'
import NotificationsPNG from 'assets/lordicons/Notifications.png'
import RequestQueuePNG from 'assets/lordicons/Queue.png'
import SettingsGIF from 'assets/lordicons/SettingsAnimated.gif'
import BrandsGIF from 'assets/lordicons/BrandsAnimated.gif'
import LocationPinGIF from 'assets/lordicons/LocationPinAnimated.gif'
import LoanOfficersGIF from 'assets/lordicons/LoanOfficersAnimated.gif'
import UsersGIF from 'assets/lordicons/UsersAnimated.gif'
import NotificationsGIF from 'assets/lordicons/NotificationsAnimated.gif'
import RequestQueueGIF from 'assets/lordicons/QueueAnimated.gif'
import TimerPNG from 'assets/lordicons/Timer.png'
import TimerGIF from 'assets/lordicons/TimerAnimated.gif'
import ReleaseNotesPNG from 'assets/lordicons/ReleaseNotes.png'
import ReleaseNotesGIF from 'assets/lordicons/ReleaseNotes.gif'
import SupportPNG from 'assets/lordicons/Support.png'
import SupportGIF from 'assets/lordicons/SupportAnimated.gif'
import BuyAHomePNG from 'assets/lordicons/BuyAHome.png'
import BuyAHomeGIF from 'assets/lordicons/BuyAHomeAnimated.gif'
import SellMyHomePNG from 'assets/lordicons/SellMyHome.png'
import SellMyHomeGIF from 'assets/lordicons/SellMyHomeAnimated.gif'
import ClientLifestylePNG from 'assets/lordicons/ClientLifestyle.png'
import ClientLifestyleGIF from 'assets/lordicons/ClientLifestyleAnimated.gif'
import ClientsPNG from 'assets/lordicons/Clients.png'
import ClientsGIF from 'assets/lordicons/ClientsAnimated.gif'
import PartnersPNG from 'assets/lordicons/Partners.png'
import PartnersGIF from 'assets/lordicons/PartnersAnimated.gif'
import KioskPNG from 'assets/lordicons/Kiosk.png'
import KioskGIF from 'assets/lordicons/KioskAnimated.gif'
import AgentListingsPNG from 'assets/lordicons/AgentListings.png'
import AgentListingsGIF from 'assets/lordicons/AgentListingsAnimated.gif'
import ReviewsPNG from 'assets/lordicons/Reviews.png'
import ReviewsGIF from 'assets/lordicons/ReviewsAnimated.gif'
import { Roles, SiteTypes, host } from './client'
import { generatePath } from 'react-router-dom'
import { addressToString } from './formattingUtils'

/**
 * Navigation Linking
 */
export const navigationLinking = {
	Landing: '/',
	SignIn: 'sign-in',
	SupportSignIn: 'support/sign-in',
	AuthChallenge: 'sign-in/challenge',
	SMSVerification: 'sign-in/sms-verification',
	CreateAccount: 'create-account',
	VerifyInvite: 'verify/invite/:token',
	ForgotPassword: 'forgot-password',
	SetPassword: 'set-password',
	Prequalify: 'prequalify',
	LeadApp: 'questionnaire',
	BuyerClient: 'buy-a-home',
	SellerClient: 'sell-my-home',
	ClientLifestyle: 'client-lifestyle',
	OpenHouseWithListing: 'open-house/:address/:listingId',
	OpenHouse: 'open-house/:address',
	OpenHouseNoAddress: 'open-house',
	Walmart: 'walmart',
	LeadCheckIn: 'lead-check-in',
	PrequalifySpanish: 'prequalify/esp',
	PrequalifyCoBorrower: 'prequalify/co-borrower',
	ApplyCoBorrower: 'apply/co-borrower',
	RealtorSignUp: 'partners/realtors/register',
	Pricing: 'pricing',
	PricingTable: 'pricing/rates',
	PricingDetails: 'pricing/rates/details',
	PricingSettings: 'portal/pricing-settings',
	Fees: 'portal/fees',
	Disclosures: 'portal/disclosures/:id?',
	DisclosuresSSO: 'portal/disclosures/sso',
	ListingOffer: 'listings/make-an-offer',
	ListingOfferWithListing: 'listings/make-an-offer/:listingId',
	ListingLanding: 'listings/:slug',
	ImpersonationConfirmation: 'impersonation-confirmation/:token',

	// Protected
	Portal: 'portal',
	Leads: 'portal/leads',
	LeadAdd: 'portal/leads/add',
	LeadEdit: 'portal/leads/edit/:id',
	LeadOpenHouses: 'portal/leads/open-houses',
	LeadOpenHouseDetail: 'portal/leads/open-houses/detail',
	LeadPropertyInfoBuyer: 'portal/leads/property-info/buyer',
	LeadPropertyInfoSeller: 'portal/leads/property-info/seller',
	LeadFinancingInfo: 'portal/leads/financing-info',
	LeadLifestyleInfo: 'portal/leads/lifestyle-info',
	Listings: 'portal/listings',
	ListingAdd: 'portal/listings/add',
	ListingEdit: 'portal/listings/edit/:id',
	ListingDetail: 'portal/listings/detail/:id',
	AgentReferral: 'portal/agent-referral',
	UserProfile: 'portal/profile',
	Tasks: 'portal/application/:loanId/tasks',
	CameraView: 'portal/tasks/camera',
	Documents: 'portal/application/:loanId/documents',
	Document: 'portal/document',
	LoanApplications: 'portal/applications',
	LoanApplication: 'portal/application/:loanId/details',
	Apply: 'apply',
	ApplySpanish: 'apply/esp',
	LOLeadApp: 'portal/lead-app',
	LOApply: 'portal/apply/borrower',
	LOApplySpanish: 'portal/apply/esp',
	LOApplyCoBorrower: 'portal/apply/coborrower',
	LOApplyCoBorrowerSpanish: 'portal/apply/coborrower/esp',
	LOPrequal: 'portal/prequal/borrower',
	LOPrequalSpanish: 'portal/prequal/borrower/esp',
	LOPrequalCoBorrower: 'portal/prequal/coborrower',
	LOPrequalCoBorrowerSpanish: 'portal/prequal/coborrower/esp',
	InviteApply: 'invite/apply',
	InviteApplySpanish: 'invite/apply/esp',
	InvitePrequalify: 'invite/prequalify',
	InvitePrequalifySpanish: 'invite/prequalify/esp',
	CustomWorkflow: 'app/:slug',

	QuickPricer: 'portal/pricing',
	WorkflowEditor: 'portal/workflows',
	LoanCalculator: 'portal/application/:loanId/calculator',
	LoanPricingResults: 'portal/calculator/pricing',
	LoanComparison: 'portal/calculator/comparison',
	Partner: 'portal/my-partners',
	IncompleteLoans: 'portal/incomplete-loans',

	// Admin
	AdminAccount: 'portal/account',
	AdminAccountEdit: 'portal/account/edit',

	AdminCorporate: 'portal/corporations',
	AdminCorporateEdit: 'portal/corporations/:id',

	AdminBranch: 'portal/branches',
	AdminBranchEdit: 'portal/branches/:id',

	AdminLoanOfficer: 'portal/loanOfficers',
	AdminLoanOfficerEdit: 'portal/loanOfficers/:id',

	AdminPartner: 'portal/partners',
	AdminPartnerEdit: 'portal/partners/:id',

	AdminWorkflow: 'portal/workflows',
	AdminWorkflowEdit: 'portal/workflows/:id',

	AdminDevice: 'portal/kiosks',
	AdminDeviceEdit: 'portal/kiosks/:id',

	AdminNotificationTemplates: 'portal/notifications/templates',
	AdminNotificationTemplateAdd: 'portal/notifications/templates/new',
	AdminNotificationTemplateEdit: 'portal/notifications/templates/:id',

	AdminDocumentTemplates: 'portal/documents/templates',
	AdminDocumentTemplateAdd: 'portal/documents/templates/new',
	AdminDocumentTemplateEdit: 'portal/documents/templates/:id',

	AdminRules: 'portal/rules',
	AdminRuleTasks: 'portal/rules/:id/tasks',

	AdminUser: 'portal/users',

	AdminLoanQueue: 'portal/queue',
}

// @todo appLink functions should be moved to a hook
export const filterAppLinks = (links, siteConfig = {}) => {
	return links.filter(
		(link) =>
			((siteConfig?.enabledServices[link.fieldName] !== undefined &&
				siteConfig?.enabledServices[link.fieldName] !== false) ||
				siteConfig[link.fieldName]) &&
			link.entityTypes.includes(siteConfig.entityType)
	)
}

/**
 * Get Application Links
 *
 * @param siteConfig
 * @returns {[{staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon, fieldName: string, animatedIcon, name: string, link: string, description: string, entityTypes: number[], id: string}, {staticIcon: never, fieldName: string, animatedIcon: never, name: string, description: string, entityTypes: (number)[], id: string}, {staticIcon: never, fieldName: string, animatedIcon: never, name: string, link: string, description: string, entityTypes: (number)[], id: string}, null, null, null, null]}
 */
export const getAppLinks = (siteConfig = {}) => {
	const appLinks = [
		{
			name: 'Buy a Home',
			id: 'BuyerClient',
			fieldName: 'buyerClient',
			staticIcon: BuyAHomePNG,
			animatedIcon: BuyAHomeGIF,
			description: 'Find the perfect home',
			link: navigationLinking.BuyerClient,
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: 'Sell my Home',
			id: 'SellerClient',
			fieldName: 'sellerClient',
			staticIcon: SellMyHomePNG,
			animatedIcon: SellMyHomeGIF,
			description: 'Sell my home',
			link: navigationLinking.SellerClient,
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: 'Client Lifestyle',
			id: 'ClientLifestyle',
			fieldName: 'clientLifestyle',
			staticIcon: ClientLifestylePNG,
			animatedIcon: ClientLifestyleGIF,
			description: 'Tailor my experience',
			link: navigationLinking.ClientLifestyle,
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: siteConfig.byPhoneTitle || 'By Phone',
			id: 'RingCentral',
			fieldName: 'ringCentral',
			staticIcon: siteConfig.byPhoneStaticIcon || PhonePNG,
			animatedIcon: siteConfig.byPhoneAnimatedIcon || PhoneGIF,
			description:
				siteConfig.byPhoneSubtitle || 'Speak with an agent',
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
			],
		},
		{
			name: siteConfig.shortAppTitle || 'Get Pre-Qualified',
			id: 'Prequalify',
			fieldName: 'mobilePrequal',
			staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG,
			animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF,
			description:
				siteConfig.shortAppSubtitle || 'Pre-qualify for a loan',
			link: navigationLinking.Prequalify,
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
				SiteTypes.realtor,
			],
		},
		{
			name: siteConfig.fullAppTitle || 'Get Pre-Approved',
			id: 'Apply',
			fieldName: 'fullApp',
			staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG,
			animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF,
			description: siteConfig.fullAppSubtitle || 'Apply for a loan',
			link: navigationLinking.Apply,
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
				SiteTypes.realtor,
			],
		},
		{
			name: 'Super Short App',
			id: 'LeadApp',
			fieldName: 'leadApp',
			staticIcon: siteConfig.superShortAppStaticIcon || TimerPNG,
			animatedIcon: siteConfig.superShortAppAnimatedIcon || TimerGIF,
			description: 'I just want some info',
			link: navigationLinking.LeadApp,
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
				SiteTypes.realtor,
			],
		},
		{
			name: siteConfig.spanishPrequalTitle || 'Forma Corta',
			id: 'PrequalifySpanish',
			fieldName: 'spanishPrequal',
			staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG,
			animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF,
			description:
				siteConfig.spanishPrequalSubtitle ||
				'Obtenga una Precalificación',
			link: navigationLinking.PrequalifySpanish,
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
				SiteTypes.realtor,
			],
		},
		{
			name: siteConfig.spanishFullAppTitle || 'Forma Completa',
			id: 'SpanishFullApp',
			fieldName: 'spanishFullApp',
			staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG,
			animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF,
			description:
				siteConfig.spanishFullAppSubtitle || 'Solicitar Préstamo',
			link: navigationLinking.ApplySpanish,
			entityTypes: [
				SiteTypes.corporate,
				SiteTypes.branch,
				SiteTypes.loanOfficer,
				SiteTypes.realtor,
			],
		},
		{
			name: 'Open House',
			id: 'OpenHouse',
			fieldName: 'openHouseForm',
			staticIcon: AgentListingsPNG,
			animatedIcon: AgentListingsGIF,
			description: 'Check-in',
			link: navigationLinking.OpenHouseNoAddress,
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: 'Offer',
			id: 'Offer',
			fieldName: 'listingOfferForm',
			staticIcon: DollarCoinPNG,
			animatedIcon: DollarCoinGIF,
			description: 'Make an Offer',
			link: navigationLinking.ListingOffer,
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: 'Calendar',
			id: 'Calendar',
			fieldName: 'calendarUrl',
			staticIcon: CalendarPNG,
			animatedIcon: CalendarGIF,
			description: 'Schedule a Meeting',
			link: siteConfig.calendarUrl,
			entityTypes: [SiteTypes.loanOfficer, SiteTypes.realtor],
			external: true,
		},
		{
			name: 'Reviews',
			id: 'Reviews',
			fieldName: 'surveysUrl',
			staticIcon: ReviewsPNG,
			animatedIcon: ReviewsGIF,
			description: 'View Feedback',
			link: siteConfig.surveysUrl,
			entityTypes: [SiteTypes.loanOfficer, SiteTypes.realtor],
			external: true,
		},
		// { name: 'Mobile App', fieldName: 'MobileApp', staticIcon: siteConfig.FullAppStaticIcon || MobilePNG, animatedIcon: siteConfig.FullAppAnimatedIcon || MobileGIF, description: 'Tracking on the go', link: navigationLinking.CreateAccount },
		// { name: 'Alexa', fieldName: 'Alexa', staticIcon: siteConfig.AlexaStaticIcon || MobilePNG, animatedIcon: siteConfig.AlexaAnimatedIcon || MobileGIF, description: 'Apply with Alexa', link: siteConfig.alexaUrl || 'https://www.amazon.com/Francisco-Correal-loan-application/dp/B07Y7WDWQK/ref=sr_1_1?d[…]words=loan-application&qid=1610083950&s=digital-skills&sr=1-1' }
	]

	return filterAppLinks(appLinks, siteConfig)
}

export const getListingLinks = (listing, siteConfig) => {
	const appLinks = [
		{
			name: 'Open House',
			id: 'OpenHouse',
			fieldName: 'openHouseForm',
			staticIcon: AgentListingsPNG,
			animatedIcon: AgentListingsGIF,
			description: 'Check-in',
			link: generatePath(navigationLinking.OpenHouseWithListing, {
				address: addressToString(listing.address),
				listingId: listing.id,
			}),
			entityTypes: [SiteTypes.realtor],
		},
		{
			name: 'Offer',
			id: 'Offer',
			fieldName: 'listingOfferForm',
			staticIcon: DollarCoinPNG,
			animatedIcon: DollarCoinGIF,
			description: 'Make an Offer',
			link: generatePath(navigationLinking.ListingOfferWithListing, {
				listingId: listing.id,
			}),
			entityTypes: [SiteTypes.realtor],
		},
	]

	return filterAppLinks(appLinks, siteConfig)
}

/**
 * Get route name from url path
 *
 * @param path
 * @returns {string}
 */
export const getRouteNameFromPath = (path) => {
	path = path.substring(1)
	for (const [key, value] of Object.entries(navigationLinking)) {
		if (value === path) return key

		// match routes with variables
		const indexesToIgnore = []
		if (value.includes(':')) {
			const valueParts = value.split('/')
			valueParts.forEach((part, index) => {
				if (part.substring(0, 1) === ':') indexesToIgnore.push(index)
			})

			const pathParts = path.split('/')
			if (pathParts.length === valueParts.length) {
				let matching = true
				pathParts.forEach((part, index) => {
					if (
						!indexesToIgnore.includes(index) &&
						part !== valueParts[index]
					)
						matching = false
				})
				if (matching) return key
			}
		}
	}
	return ''
}

/**
 * Get Actions for Portal / Nav by User Role
 * @param user
 * @param siteConfig
 * @param loan
 * @returns {*[]}
 */
export const getActionsByRole = (
	user,
	siteConfig = {},
	loan = { loanId: '', locked: false }
) => {
	const role = user.role
	const loanId = loan?.loanId

	const listingAction = {
		name: 'Listings',
		id: 'listings',
		staticIcon: AgentListingsPNG,
		animatedIcon: AgentListingsGIF,
		route: navigationLinking.Listings,
		description: 'View Listings',
	}
	const myPipelineAction = {
		name: 'My Pipeline',
		id: 'loanApplications',
		staticIcon: siteConfig.pipelineStaticIcon || DollarCoinPNG,
		animatedIcon: siteConfig.pipelineAnimatedIcon || DollarCoinGIF,
		route: navigationLinking.LoanApplications,
		description: 'View My Loans',
	}

	const loanDetailsAction = {
		name: 'Loan Details',
		id: 'loanDetails',
		staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG,
		animatedIcon: siteConfig.loanDetailsAnimatedIcon || ListGIF,
		description: 'Loan Details',
		action: 'loanDetails',
		route: generatePath(`${navigationLinking.LoanApplication}`, {
			loanId,
		}),
	}

	const tasksAction = {
		name: 'Tasks',
		id: 'tasks',
		staticIcon: siteConfig.rulesStaticIcon || RulesPNG,
		animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF,
		route: generatePath(`${navigationLinking.Tasks}`, {
			loanId,
		}),
		description: 'Borrower Tasks',
	}

	const loanDocumentAction = {
		name: 'Documents',
		id: 'documents',
		staticIcon: siteConfig.documentsStaticIcon || DocumentsPNG,
		animatedIcon: siteConfig.documentsAnimatedIcon || DocumentsGIF,
		route: generatePath(`${navigationLinking.Documents}`, {
			loanId,
		}),
		description: 'Borrower Documents',
	}

	const encompassWebAction = {
		name: 'Encompass Web',
		id: 'encompassWeb',
		staticIcon: siteConfig.loConnectStaticIcon || LOConnectPNG,
		animatedIcon: siteConfig.loConnectAnimatedIcon || LOConnectGIF,
		description: 'Edit Application',
		action: 'loConnect',
	}

	const myProfileAction = {
		name: 'My Profile',
		id: 'myProfile',
		staticIcon: siteConfig.myProfileStaticIcon || UserPNG,
		animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF,
		route: navigationLinking.UserProfile,
		description: 'Security Credentials',
	}

	const helpAction = {
		name: 'Need Help?',
		id: 'support',
		staticIcon: siteConfig.supportStaticIcon || SupportPNG,
		animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF,
		href: 'https://support.thebigpos.com',
		description: 'Get Support',
	}

	let actions = []
	switch (true) {
		// Loan Officer
		case [Roles.loanOfficer, Roles.branchManager].includes(role):
			actions = [
				myPipelineAction,
				{
					name: 'Leads',
					id: 'leads',
					staticIcon: UsersPNG,
					animatedIcon: UsersGIF,
					route: navigationLinking.Leads,
					description: 'View My Leads',
				},
				{
					name: 'Partners',
					id: 'partners',
					staticIcon: PartnersPNG,
					animatedIcon: PartnersGIF,
					route: navigationLinking.Partner,
					description: 'View My Partners',
				},
				{
					name: 'Quick Pricer',
					id: 'pricingCalculator',
					staticIcon:
						siteConfig.quickPricerStaticIcon || QuickPricerPNG,
					animatedIcon:
						siteConfig.quickPricerAnimatedIcon || QuickPricerGIF,
					route: navigationLinking.QuickPricer,
					description: 'Price a Loan',
				},
				listingAction,
			]
			if (loanId) {
				actions = !siteConfig.enabledServices?.borrowerTasks
					? []
					: [tasksAction]
				actions = [
					...[
						loanDetailsAction,
						...actions,
						loanDocumentAction,
						{
							name: 'Loan Calculator',
							id: 'loanCalculator',
							staticIcon:
								siteConfig.calculatorStaticIcon || CalculatorPNG,
							animatedIcon:
								siteConfig.calculatorAnimatedIcon || CalculatorGIF,
							route: generatePath(
								`${navigationLinking.LoanCalculator}`,
								{ loanId }
							),
							description: 'Calculate Payments',
						},
						{
							name: 'Add a Co-Borrower',
							id: 'invite',
							staticIcon:
								siteConfig.addCoBorrowerStaticIcon ||
								AddCoBorrowerPNG,
							animatedIcon:
								siteConfig.addCoBorrowerAnimatedIcon ||
								AddCoBorrowerGIF,
							description: 'Send an Invite',
							action: 'invite',
						},
						// { name: "New Application", id: "LODuplicate", staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, route: navigationLinking.LOApply, description: 'Duplicate Application' }
					],
					...[encompassWebAction, helpAction],
				]
			} else
				actions = [
					...actions,
					...[
						{
							name: 'New Application',
							id: 'fullApp',
							staticIcon:
								siteConfig.fullAppStaticIcon || ApplicationPNG,
							animatedIcon:
								siteConfig.fullAppAnimatedIcon || ApplicationGIF,
							route: navigationLinking.LOApply,
							description: 'Start New Application',
						},
						{
							name: 'New Prequal',
							id: 'mobilePrequal',
							staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG,
							animatedIcon:
								siteConfig.shortAppAnimatedIcon || PrequalGIF,
							route: navigationLinking.LOPrequal,
							description: 'Start New Prequal',
						},
						{
							name: 'New Short App',
							id: 'leadApp',
							staticIcon:
								siteConfig.superShortAppStaticIcon || TimerPNG,
							animatedIcon:
								siteConfig.superShortAppAnimatedIcon || TimerGIF,
							route: navigationLinking.LOLeadApp,
							description: 'Super Short App',
						},
						myProfileAction,
						encompassWebAction,
						helpAction,
					],
				]
			break
		case [Roles.loanProcessor, Roles.loanOfficerAssistant].includes(
			role
		):
			actions = [myPipelineAction]
			if (loanId) {
				const taskActionIncluded = !siteConfig.enabledServices
					?.borrowerTasks
					? []
					: [tasksAction]
				actions = [
					loanDetailsAction,
					...taskActionIncluded,
					loanDocumentAction,
					encompassWebAction,
				]
			}

			actions = [...actions, myProfileAction, helpAction]
			break
		// Borrower and Co-Borrower
		case [Roles.borrower].includes(role):
			actions = [
				{
					name: 'Apply for a New Loan',
					id: 'fullApp',
					staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG,
					animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF,
					route: navigationLinking.Apply,
					description: 'New Purchase / Refi',
					action: 'duplicate',
				},
				{
					name: 'Aplicación Nueva',
					id: 'spanishFullApp',
					staticIcon: siteConfig.shortAppStaticIcon || PrequalPNG,
					animatedIcon: siteConfig.shortAppAnimatedIcon || PrequalGIF,
					route: navigationLinking.ApplySpanish,
					description: 'Nueva Compra / Refi',
				},
				{
					name: 'Loan Applications',
					id: 'loanApplications',
					staticIcon:
						siteConfig.loanApplicationsStaticIcon ||
						LoanApplicationsPNG,
					animatedIcon:
						siteConfig.loanApplicationsAnimatedIcon ||
						LoanApplicationsGIF,
					route: navigationLinking.LoanApplications,
					description: 'View My Loans',
				},
			]
			if (loanId)
				actions = [
					...[
						{
							name: 'Loan Details',
							id: 'loanDetails',
							staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG,
							animatedIcon:
								siteConfig.loanDetailsAnimatedIcon || ListGIF,
							route: generatePath(
								`${navigationLinking.LoanApplication}`,
								{ loanId }
							),
							description: 'Track Application',
							action: 'loanDetails',
						},
						{
							name: 'Tasks',
							id: 'tasks',
							staticIcon: siteConfig.rulesStaticIcon || RulesPNG,
							animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF,
							route: generatePath(`${navigationLinking.Tasks}`, {
								loanId,
							}),
							description: 'View My Tasks',
						},
						{
							name: 'E-Sign Documents',
							id: 'disclosures',
							staticIcon:
								siteConfig.signatureStaticIcon || SignaturePNG,
							animatedIcon:
								siteConfig.signatureAnimatedIcon || SignatureGIF,
							description: 'Sign Documents',
							action: 'disclosures',
						},
						{
							name: 'Documents',
							id: 'documents',
							staticIcon:
								siteConfig.documentsStaticIcon || DocumentsPNG,
							animatedIcon:
								siteConfig.documentsAnimatedIcon || DocumentsGIF,
							route: generatePath(`${navigationLinking.Documents}`, {
								loanId,
							}),
							description: 'View My Documents',
						},
						// {
						// 	name: 'Payment',
						// 	id: 'payments',
						// 	staticIcon: siteConfig.paymentStaticIcon || PaymentPNG,
						// 	animatedIcon:
						// 		siteConfig.paymentAnimatedIcon || PaymentGIF,
						// 	description: 'Make a Payment',
						// 	action: 'payment',
						// },
						{
							name: 'Add a Co-Borrower',
							id: 'invite',
							staticIcon:
								siteConfig.addCoBorrowerStaticIcon ||
								AddCoBorrowerPNG,
							animatedIcon:
								siteConfig.addCoBorrowerAnimatedIcon ||
								AddCoBorrowerGIF,
							description: 'Send an Invite',
							action: 'invite',
						},
					],
					...actions,
				]
			if (siteConfig.calendarUrl)
				actions.push({
					name: 'Request a Meeting',
					id: 'calendar',
					staticIcon: CalendarPNG,
					animatedIcon: CalendarGIF,
					href: siteConfig.calendarUrl,
					description: 'View Calendar',
				})
			actions = [
				...actions,
				...[
					{
						name: 'My Profile',
						id: 'myProfile',
						staticIcon: siteConfig.myProfileStaticIcon || UserPNG,
						animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF,
						route: navigationLinking.UserProfile,
						description: 'Security Credentials',
					},
					// { name: "Need Help?", id: "Support", staticIcon: siteConfig.supportStaticIcon || SupportPNG, animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF, href: 'https://support.thebigpos.com', description: 'Get Support' }
				],
			]
			break
		// Admin
		case [Roles.admin, Roles.superAdmin].includes(role):
			actions = [
				// { name: "Loan Applications", id: "LoanApplications", icon: CurrencyInputSVG, staticIcon: siteConfig.LoanApplicationsStaticIcon || DollarCoinPNG, animatedIcon: siteConfig.LoanApplicationsAnimatedIcon || DollarCoinGIF, route: navigationLinking.LoanApplications, description: 'View All Loans' },
				{
					name: 'Rules',
					id: 'Rules',
					staticIcon: siteConfig.rulesStaticIcon || RulesPNG,
					animatedIcon: siteConfig.rulesAnimatedIcon || RulesGIF,
					route: navigationLinking.AdminRules,
					description: 'Set Custom Rules',
				},
				{
					name: 'Account Settings',
					id: 'accountSettings',
					staticIcon:
						siteConfig.accountSettingsStaticIcon || SettingsPNG,
					animatedIcon:
						siteConfig.accountSettingsAnimatedIcon || SettingsGIF,
					route: navigationLinking.AdminAccountEdit,
					description: 'Billing & Profile',
				},
				{
					name: 'Brands',
					id: 'corporations',
					staticIcon: siteConfig.brandStaticIcon || BrandsPNG,
					animatedIcon: siteConfig.brandAnimatedIcon || BrandsGIF,
					route: navigationLinking.AdminCorporate,
					description: 'Corporate Pages',
				},
				{
					name: 'Branches',
					id: 'branches',
					staticIcon: siteConfig.branchesStaticIcon || LocationPinPNG,
					animatedIcon:
						siteConfig.branchesAnimatedIcon || LocationPinGIF,
					route: navigationLinking.AdminBranch,
					description: 'Branch Pages',
				},
				{
					name: 'Loan Officers',
					id: 'loanOfficers',
					staticIcon:
						siteConfig.loanOfficersStaticIcon || LoanOfficersPNG,
					animatedIcon:
						siteConfig.loanOfficersAnimatedIcon || LoanOfficersGIF,
					route: navigationLinking.AdminLoanOfficer,
					description: 'Loan Officer Pages',
				},
				{
					name: 'Partners',
					id: 'partners',
					staticIcon: siteConfig.realtorsStaticIcon || PartnersPNG,
					animatedIcon:
						siteConfig.realtorsAnimatedIcon || PartnersGIF,
					route: navigationLinking.AdminPartner,
					description: 'Partner Pages',
				},
				{
					name: 'Users',
					id: 'users',
					staticIcon: siteConfig.usersStaticIcon || UsersPNG,
					animatedIcon: siteConfig.usersAnimatedIcon || UsersGIF,
					route: navigationLinking.AdminUser,
					description: 'Create and Edit Users',
				},
				{
					name: 'Notifications',
					id: 'notifications',
					staticIcon:
						siteConfig.notificationsStaticIcon || NotificationsPNG,
					animatedIcon:
						siteConfig.notificationsAnimatedIcon || NotificationsGIF,
					route: navigationLinking.AdminNotificationTemplates,
					description: 'Manage Alerts',
				},
				{
					name: 'Documents',
					id: 'generatedDocuments',
					staticIcon:
						siteConfig.documentTemplatesStaticIcon || DocumentsPNG,
					animatedIcon:
						siteConfig.documentTemplatesAnimatedIcon || DocumentsGIF,
					route: navigationLinking.AdminDocumentTemplates,
					description: 'Generated Documents',
				},
				{
					name: 'Kiosks',
					id: 'kiosks',
					staticIcon: KioskPNG,
					animatedIcon: KioskGIF,
					route: navigationLinking.AdminDevice,
					description: 'Manage Kiosks',
				},
				{
					name: 'Loan Queue',
					id: 'queue',
					staticIcon:
						siteConfig.requestQueueStaticIcon || RequestQueuePNG,
					animatedIcon:
						siteConfig.requestQueueAnimatedIcon || RequestQueueGIF,
					route: navigationLinking.AdminLoanQueue,
					description: 'View Queue',
				},
				{
					name: "What's New?",
					id: 'releaseNotes',
					staticIcon:
						siteConfig.releaseNotesStaticIcon || ReleaseNotesPNG,
					animatedIcon:
						siteConfig.releaseNotesAnimatedIcon || ReleaseNotesGIF,
					href: 'https://thebigpos.launchnotes.io',
					description: 'Release Notes',
				},
				{
					name: 'Need Help?',
					id: 'support',
					staticIcon: siteConfig.supportStaticIcon || SupportPNG,
					animatedIcon: siteConfig.supportAnimatedIcon || SupportGIF,
					href: 'https://support.thebigpos.com',
					description: 'Get Support',
				},
				{
					name: 'My Profile',
					id: 'myProfile',
					staticIcon: siteConfig.myProfileStaticIcon || UserPNG,
					animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF,
					route: navigationLinking.UserProfile,
					description: 'Security Credentials',
				},
				{
					name: 'Workflows',
					id: 'Workflows',
					staticIcon: siteConfig.workflowsStaticIcon || PrequalPNG,
					animatedIcon:
						siteConfig.workflowsAnimatedIcon || PrequalGIF,
					route: navigationLinking.WorkflowEditor,
					description: 'Manage Workflows',
				},
				{
					name: 'Incomplete Loans',
					id: 'IncompleteLoans',
					staticIcon: DollarCoinPNG,
					animatedIcon: DollarCoinGIF,
					route: navigationLinking.IncompleteLoans,
					description: 'Manage Incomplete Loans',
				},
			]
			break
		// Realtor
		case role === Roles.realtor:
			actions = [
				{
					name: 'Leads',
					id: 'leads',
					staticIcon: UsersPNG,
					animatedIcon: UsersGIF,
					route: navigationLinking.Leads,
					description: 'View Leads',
				},
				{
					name: 'Clients',
					id: 'loanApplications',
					staticIcon: siteConfig.usersStaticIcon || ClientsPNG,
					animatedIcon: siteConfig.usersAnimatedIcon || ClientsGIF,
					route: navigationLinking.LoanApplications,
					description: 'View My Clients',
				},
				listingAction,
				//{ name: "Pricer", id: "PricingCalculator", staticIcon: siteConfig.QuickPricerStaticIcon || QuickPricerPNG, animatedIcon: siteConfig.QuickPricerAnimatedIcon || QuickPricerGIF, route: navigationViews.QuickPricer, description: 'Price a Loan' }
			]
			if (loanId)
				actions = [
					...[
						{
							name: 'Loan Details',
							id: 'loanDetails',
							staticIcon: siteConfig.loanDetailsStaticIcon || ListPNG,
							animatedIcon:
								siteConfig.loanDetailsAnimatedIcon || ListGIF,
							description: 'Loan Details',
							action: 'loanDetails',
							route: generatePath(
								`${navigationLinking.LoanApplication}`,
								{ loanId }
							),
						},
						{
							name: 'Documents',
							id: 'documents',
							staticIcon:
								siteConfig.documentsStaticIcon || DocumentsPNG,
							animatedIcon:
								siteConfig.documentsAnimatedIcon || DocumentsGIF,
							route: generatePath(`${navigationLinking.Documents}`, {
								loanId,
							}),
							description: 'Borrower Documents',
						},
						{
							name: 'Loan Calculator',
							id: 'loanCalculator',
							staticIcon:
								siteConfig.calculatorStaticIcon || CalculatorPNG,
							animatedIcon:
								siteConfig.calculatorAnimatedIcon || CalculatorGIF,
							route: generatePath(
								`${navigationLinking.LoanCalculator}`,
								{ loanId }
							),
							description: 'Calculate Payments',
						},
						// { name: "Invite Co-Borrower", id: "Invite", staticIcon: siteConfig.addCoBorrowerStaticIcon || AddCoBorrowerPNG, animatedIcon: siteConfig.addCoBorrowerAnimatedIcon || AddCoBorrowerGIF, description: 'Send an Invite', action: 'invite' },
						// { name: "Duplicate", id: "LODuplicate", staticIcon: siteConfig.fullAppStaticIcon || ApplicationPNG, animatedIcon: siteConfig.fullAppAnimatedIcon || ApplicationGIF, route: navigationViews.LOApply, description: 'Duplicate Application' }
					],
					...actions,
				]
			else
				actions = [
					...actions,
					...[
						{
							name: 'Refer New Client',
							id: 'agentReferral',
							staticIcon:
								siteConfig.addCoBorrowerStaticIcon ||
								AddCoBorrowerPNG,
							animatedIcon:
								siteConfig.addCoBorrowerAnimatedIcon ||
								AddCoBorrowerGIF,
							route: navigationLinking.AgentReferral,
							description: 'New Application',
						},
					],
				]
			actions = [
				...actions,
				...[
					{
						name: 'My Profile',
						id: 'myProfile',
						staticIcon: siteConfig.myProfileStaticIcon || UserPNG,
						animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF,
						route: navigationLinking.UserProfile,
						description: 'Security Credentials',
					},
					{
						name: 'Need Help?',
						id: 'support',
						staticIcon: siteConfig.supportStaticIcon || SupportPNG,
						animatedIcon:
							siteConfig.supportAnimatedIcon || SupportGIF,
						href: 'https://support.thebigpos.com',
						description: 'Get Support',
					},
				],
			]
			break
		// All others default to clients and documents
		default:
			actions = [
				{
					name: 'Clients',
					id: 'loanApplications',
					staticIcon: siteConfig.usersStaticIcon || ClientsPNG,
					animatedIcon: siteConfig.usersAnimatedIcon || ClientsGIF,
					route: navigationLinking.LoanApplications,
					description: 'View My Clients',
				},
			]
			if (loanId)
				actions = [
					...[
						{
							name: 'Documents',
							id: 'documents',
							staticIcon:
								siteConfig.documentsStaticIcon || DocumentsPNG,
							animatedIcon:
								siteConfig.documentsAnimatedIcon || DocumentsGIF,
							route: generatePath(`${navigationLinking.Documents}`, {
								loanId,
							}),
							description: 'Borrower Documents',
						},
					],
					...actions,
				]
			actions = [
				...actions,
				...[
					{
						name: 'My Profile',
						id: 'myProfile',
						staticIcon: siteConfig.myProfileStaticIcon || UserPNG,
						animatedIcon: siteConfig.myProfileAnimatedIcon || UserGIF,
						route: navigationLinking.UserProfile,
						description: 'Security Credentials',
					},
					{
						name: 'Need Help?',
						id: 'support',
						staticIcon: siteConfig.supportStaticIcon || SupportPNG,
						animatedIcon:
							siteConfig.supportAnimatedIcon || SupportGIF,
						href: 'https://support.thebigpos.com',
						description: 'Get Support',
					},
				],
			]
			break
	}

	if (loan.locked)
		actions = actions.filter((item) => item.id !== 'invite')

	return actions
}

/**
 * Generate disclosures SSO login url
 * @param {object} siteConfig
 * @param {string} token
 * @returns string
 */
export function generateDisclosuresSSOLoginUrl(siteConfig, token) {
	return `${host}/saml/signin?token=${token}&redirectUri=https://${siteConfig.company.siteUrl}/portal/disclosures`
}

/**
 * Generate disclosures SSO logout url
 * @param {object} siteConfig
 * @returns string
 */
export function generateDisclosuresSSOLogoutUrl(siteConfig) {
	return `${host}/saml/signout?redirectUri=https://${siteConfig.company.siteUrl}/portal`
}
