import React from 'react'
import { Loading } from './Loading'

function LoadingPage() {
	return (
		<div className="max-w-screen-xl flex items-center h-screen mx-auto">
			<div className="max-w-xs mx-auto">
				<Loading />
			</div>
		</div>
	)
}

export default LoadingPage
