import React from 'react'
import MuiLoadingButton from '@mui/lab/LoadingButton'
import type { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab/LoadingButton'
import clsx from 'clsx'

export type LoadingBtnProps = {
	indicatorOnlyOnLoading?: boolean
	text: string
} & MuiLoadingButtonProps

const LoadingBtn = ({
	text,
	variant = 'contained',
	fullWidth = true,
	indicatorOnlyOnLoading = false,
	style,
	loading,
	...rest
}: LoadingBtnProps) => (
	<MuiLoadingButton
		fullWidth={fullWidth}
		variant={indicatorOnlyOnLoading && loading ? 'text' : variant}
		style={{
			textTransform: 'none',
			fontSize: '1rem',
			width: indicatorOnlyOnLoading && loading ? '4px' : undefined,
			height: indicatorOnlyOnLoading && loading ? '2rem' : undefined,
			...style,
		}}
		loading={loading}
		{...rest}
		className={clsx(
			variant === 'outlined' ? 'dark:!text-white' : '',
			rest.className
		)}
	>
		{text}
	</MuiLoadingButton>
)

export default LoadingBtn
export { LoadingBtn } // legacy export. should be default export only
