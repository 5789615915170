import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useVenti } from 'venti'
import { TableColumn } from 'react-data-table-component'
import { SiteConfigurationSummary } from '@matech/thebigpos-sdk'

import withAuth from '../../../components/withAuth'
import CorporationExpandedRow from './CorporationExpandedRow'
import { formatDate, getErrorMessage } from '../../../services/helper'
import { eventTypes } from '../../../services/constants'
import { navigationLinking } from '../../../services/navigation'

import { useAlert } from '../../../hooks'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { getTheme } from '../../../config'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import SiteConfigurationPage, {
	SiteConfigurationEntityType,
} from '../SiteConfigurationPage'

const theme = getTheme()

const columns: TableColumn<SiteConfigurationSummary>[] = [
	{
		name: 'Name',
		selector: (row: SiteConfigurationSummary) => row.name,
		sortable: true,
		sortField: 'name',
	},
	{
		name: 'NMLS ID',
		selector: (row: SiteConfigurationSummary) => row.nmlsid,
		sortable: true,
		sortField: 'nMLSID',
	},
	{
		name: 'Email',
		selector: (row: SiteConfigurationSummary) => row.email || '',
		sortable: true,
		sortField: 'email',
	},
	{
		name: 'URL',
		selector: (row: SiteConfigurationSummary) => row.url || '',
	},
	{
		name: 'Created',
		selector: (row: SiteConfigurationSummary) =>
			row.corporate?.createdAt || '',
		cell: (row: SiteConfigurationSummary) =>
			formatDate(row.corporate?.createdAt, false),
		sortable: true,
		sortField: 'createdAt',
		hide: theme.breakpoints.dataTable,
	},
]

const AdminCorporations = () => {
	const { alert } = useAlert()
	const ventiState = useVenti()
	const mixpanel = useMixpanel()
	const navigate = useNavigate()

	const [activeRow, setActiveRow] =
		useState<SiteConfigurationSummary | null>(null)

	const [removeModalVisible, setRemoveModalVisible] = useState(false)

	const filters = {
		brand: null,
		type: null,
		branch: null,
	}

	const handleMutationSuccess = (
		eventType: string,
		modalSetter: React.Dispatch<React.SetStateAction<boolean>>,
		successMessage: string,
		activeRow: SiteConfigurationSummary | null
	) => {
		if (!activeRow) return

		const { name, corporate } = activeRow
		mixpanel.trackEvent(eventType, {
			id: corporate?.id,
			name,
		})
		modalSetter(false)
		alert(`Brand "${name}" successfully ${successMessage}`)
	}

	const removeCorporateMutation = useMutation({
		mutationFn: (id: string) => TheBigPOSApi.deleteCorporate(id),
		onSuccess: () => {
			handleMutationSuccess(
				eventTypes.BRAND_DELETED,
				setRemoveModalVisible,
				'removed',
				activeRow
			)
			setActiveRow(null)
		},
	})

	const removeCorporate = async () => {
		try {
			if (activeRow?.corporate?.id)
				await removeCorporateMutation.mutateAsync(
					activeRow?.corporate?.id
				)
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		}
	}

	const onAddClick = () => {
		navigate(
			generatePath(`/${navigationLinking.AdminCorporateEdit}`, {
				id: 'new',
			})
		)
	}

	const onRowClicked = async (row: SiteConfigurationSummary) => {
		await ventiState.set(
			theme.storageKeys.editingCorporateSiteConfigurationId,
			row.id
		)
		navigate(
			generatePath(`/${navigationLinking.AdminCorporateEdit}`, {
				id: row?.corporate?.id,
			})
		)
	}

	const handleActiveRowChange = (row: SiteConfigurationSummary) => {
		setActiveRow(row)
	}

	return (
		<SiteConfigurationPage
			entityType={SiteConfigurationEntityType.Corporate}
			title="Brands"
			onRemove={removeCorporate}
			isRemovePending={removeCorporateMutation.isPending}
			filters={filters}
			activeRow={activeRow}
			onActiveRowChange={handleActiveRowChange}
			columns={columns}
			onRowClicked={onRowClicked}
			removeModalVisible={removeModalVisible}
			onRemoveModalVisible={setRemoveModalVisible}
			expandableRowsComponent={CorporationExpandedRow}
			addNewBtn={{ text: 'Add Brand', onClick: onAddClick }}
		/>
	)
}

export default withAuth(AdminCorporations)
