import React, { useState } from 'react'
import { Card, CardContent, Tab, Tabs, useTheme } from '@mui/material'
import { UserLoanTask } from '@matech/thebigpos-sdk'
import { a11yProps } from '../../../../services/helper'
import TabPanel from '../../../../components/TabPanel'
import CommentPaginatedList from './CommentPaginatedList'
import TaskDetailsTab from './TaskDetailsTab'

type TaskRowExpandedProps = {
	data: UserLoanTask
	openChatTab: boolean
	handleAddComment: () => void
	onCommentSubmit: () => void
}
const TaskRowExpanded = ({
	data: loanTask,
	openChatTab,
	onCommentSubmit,
}: TaskRowExpandedProps) => {
	const [activeTab, setActiveTab] = useState(openChatTab ? 1 : 0)
	const themeMUI = useTheme()

	const handleTabChange = (
		_event: React.SyntheticEvent,
		newValue: number
	) => {
		setActiveTab(newValue)
	}

	return (
		<div className="p-2">
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={activeTab}
				onChange={handleTabChange}
				aria-label="Tabs"
			>
				<Tab label="Details" {...a11yProps(0)} />
				<Tab label="Comments" {...a11yProps(1)} />
			</Tabs>
			<TabPanel
				value={activeTab}
				index={0}
				dir={themeMUI.direction}
				noMarginTop
			>
				<Card>
					<CardContent>
						<TaskDetailsTab loanTask={loanTask} />
					</CardContent>
				</Card>
			</TabPanel>
			<TabPanel
				value={activeTab}
				index={1}
				dir={themeMUI.direction}
				noMarginTop
			>
				<Card style={{ boxShadow: 'none' }}>
					<CardContent>
						<CommentPaginatedList
							userLoanTaskId={loanTask.id}
							loanId={loanTask.loanID}
							hasComments={loanTask.commentsCount > 0}
							onCommentSubmit={onCommentSubmit}
						/>
					</CardContent>
				</Card>
			</TabPanel>
		</div>
	)
}

export default TaskRowExpanded
