import React, { useMemo } from 'react'
import {
	FormControl,
	InputLabel,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	FormHelperText,
	MenuItem,
	InputLabelProps,
	FormControlProps as MuiFormControlProps,
} from '@mui/material'
import {
	Controller,
	type FieldErrors,
	useFormContext,
} from 'react-hook-form'
import useFormField from '../../hooks/useFormField'
import { RFHControl } from '../../types'

type ItemProps<T> = {
	disabled?: boolean
} & T

export type SelectProps<T> = {
	name?: string
	menuItems: ItemProps<T>[]
	children?: React.ReactNode
	labelField?: string
	valueField?: string
	control?: RFHControl
	errors?: FieldErrors
	helperText?: string
	selectProps?: Omit<MuiSelectProps, 'disabled' | 'displayEmpty'>
	labelProps?: InputLabelProps
	renderItem?: (item: ItemProps<T>) => React.ReactNode
	displayEmpty?: boolean
	label: string
	placeholder?: string
	disabled?: boolean
} & MuiFormControlProps

const Select = <T,>({
	variant = 'standard',
	name,
	selectProps,
	labelProps,
	label,
	placeholder,
	children,
	errors: propsErrors,
	control: propsControl,
	defaultValue,
	displayEmpty,
	menuItems,
	labelField = 'label',
	valueField = 'value',
	renderItem,
	helperText,
	disabled,
	...rest
}: SelectProps<T>) => {
	const formContext = useFormContext()
	const control = propsControl || formContext?.control
	const errors = propsErrors || formContext?.formState.errors

	const { getError } = useFormField()
	const error = getError(name, errors)
	const EmptyMenuItem = useMemo(
		() =>
			displayEmpty ? (
				<MenuItem value="">
					<em>{placeholder || 'Select value'}</em>
				</MenuItem>
			) : undefined,
		[displayEmpty, placeholder]
	)
	const MenuItems: React.ReactNode[] | undefined = useMemo(
		() =>
			menuItems
				? menuItems.map((item: ItemProps<T>, i) => {
						if (renderItem) {
							return renderItem(item)
						}

						return (
							<MenuItem
								key={`${name}-menu-item-${i}`}
								value={
									item[valueField as keyof ItemProps<T>] as string
								}
								disabled={item.disabled}
							>
								{item[labelField as keyof ItemProps<T>] as string}
							</MenuItem>
						)
					})
				: undefined,
		[labelField, menuItems, name, renderItem, valueField]
	)

	return (
		<FormControl {...rest} variant={variant} error={!!error}>
			<InputLabel {...labelProps}>{label}</InputLabel>
			{control && name ? (
				<Controller
					name={name}
					control={control}
					defaultValue={defaultValue}
					render={({ field }) => (
						<MuiSelect
							{...selectProps}
							{...field}
							disabled={disabled}
						>
							{EmptyMenuItem}
							{MenuItems}
							{children}
						</MuiSelect>
					)}
				/>
			) : (
				<MuiSelect
					name={name}
					defaultValue={defaultValue}
					{...selectProps}
					disabled={disabled}
				>
					{EmptyMenuItem}
					{MenuItems}
					{children}
				</MuiSelect>
			)}
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			{error && <FormHelperText>{error.message}</FormHelperText>}
		</FormControl>
	)
}

export default Select
