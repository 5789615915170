import React from 'react'
import { applyMask } from '../../../../services/utils'
import RowExpandedProperty from '../../../../components/RowExpandedProperty'
import { Check } from '@mui/icons-material'

const TaskRowExpanded = ({ data: task }) => {
	const {
		name,
		type,
		description,
		daysDueFromApplication,
		targetUserRole,
	} = task
	return (
		<div className="p-5">
			<RowExpandedProperty label="Name" value={name} />
			<RowExpandedProperty label="Description" value={description} />
			<RowExpandedProperty label="Type" value={type} />
			<RowExpandedProperty
				label="Days due"
				value={daysDueFromApplication}
			/>
			<RowExpandedProperty label="Role" value={targetUserRole} />
			<RowExpandedProperty
				label="Auto Complete"
				value={task.willAutocompleteAfterResponse ? <Check /> : '-'}
				className="my-2"
			/>
			{/* <RowExpandedProperty
				label="Auto Add"
				value={task?.hasAutoPropagationOnChange ? <Check /> : '-'}
				className="my-2"
			/> */}
		</div>
	)
}

export default TaskRowExpanded
