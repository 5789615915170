import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
	BranchSearchCriteria,
	Corporate,
	GetBranch,
} from '@matech/thebigpos-sdk'

import queryKeys from '../../../services/queryKeys'
import { TheBigPOSApi } from '../../../lib/TheBigPOSClient'
import Autocomplete from '../../../components/formControls/Autocomplete'

type UserSiteConfigurationSearchFiltersProps = {
	handleFilterChange: (
		value: string | undefined,
		field: string
	) => void
	filters: {
		brand: string | null
		branch: string | null
		type: string | null
	}
}

const UserSiteConfigurationSearchFilters = ({
	handleFilterChange,
	filters,
}: UserSiteConfigurationSearchFiltersProps) => {
	const [corporateSearch, setCorporateSearch] = useState('')
	const [branchSearch, setBranchSearch] = useState('')
	const corporateSearchCriteria = {
		searchText: corporateSearch,
		isActive: true,
	}
	const branchSearchCriteria: BranchSearchCriteria = {
		searchText: branchSearch,
		isActive: true,
	}

	const {
		error: errorFetchingCorporations,
		data: corporationsResult,
		isFetching,
		isRefetching,
	} = useQuery({
		queryKey: [queryKeys.corporations, corporateSearchCriteria],
		queryFn: () =>
			TheBigPOSApi.searchCorporate(corporateSearchCriteria),
	})

	const {
		error: errorFetchingBranches,
		data: branchesResult,
		isFetching: isFetchingBranches,
		isRefetching: isRefetchingBranches,
	} = useQuery({
		queryKey: [queryKeys.branches, branchSearchCriteria],
		queryFn: () => TheBigPOSApi.searchBranches(branchSearchCriteria),
	})

	const onCorporateInputValueChange = (value: string | null) =>
		setCorporateSearch(value || '')

	const onBranchInputValueChange = (value: string | null) =>
		setBranchSearch(value || '')

	return (
		<>
			<Autocomplete<Corporate>
				options={corporationsResult?.data?.rows || []}
				initialValue={undefined}
				value={filters.brand || ''}
				getOptionLabel={(option: Corporate) => option?.name}
				renderOption={(props, option: Corporate) => {
					return (
						<li {...props} key={option.id}>
							{option?.name}
						</li>
					)
				}}
				isLoading={isFetching || isRefetching}
				disabled={false}
				error={errorFetchingCorporations != null}
				onChange={(response: Corporate | Corporate[] | null) => {
					if (Array.isArray(response)) return
					handleFilterChange(response?.id, 'brand')
				}}
				onInputValueChange={onCorporateInputValueChange}
				id="corporation-autocomplete"
				label="Brand"
			/>
			<Autocomplete<GetBranch>
				options={branchesResult?.data?.rows || []}
				initialValue={undefined}
				value={filters.branch || ''}
				getOptionLabel={(option: GetBranch) => option?.name}
				renderOption={(props, option: GetBranch) => {
					return (
						<li {...props} key={option.id}>
							{option?.name}
						</li>
					)
				}}
				isLoading={isFetchingBranches || isRefetchingBranches}
				disabled={false}
				error={errorFetchingBranches != null}
				onChange={(response: GetBranch | GetBranch[] | null) => {
					if (Array.isArray(response)) return
					handleFilterChange(response?.id, 'branch')
				}}
				onInputValueChange={onBranchInputValueChange}
				id="branch-autocomplete"
				label="Branch"
			/>
		</>
	)
}

export default UserSiteConfigurationSearchFilters
