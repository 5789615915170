import { useState } from 'react'
import { LoadingBtn } from '../../components/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import AuthWrapper from './AuthWrapper'
import ChangePassword from '../../components/ChangePassword'
import { useAppContext } from '../../components/AppContext'
import { getTheme } from '../../config'
import { navigationLinking } from '../../services/navigation'
import {
	isEmailValid,
	isDarkMode,
	getErrorMessage,
} from '../../services/helper'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { applyMask } from '../../services/utils'
import { useAlert } from '../../hooks'
import { useMixpanel } from '../../hooks/useMixpanel'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'
import { eventTypes } from '../../services/constants'

const theme = getTheme()

export default function ForgotPassword() {
	const { alert } = useAlert()
	const { state } = useAppContext()

	const navigate = useNavigate()
	const { siteConfig } = state
	const mixpanel = useMixpanel()

	const [loading, setLoading] = useState(false)
	const [modalConfirmation, setModalConfirmation] = useState({
		open: false,
		text: 'Your password has been reset',
		isOkButton: true,
	})
	const [errorMessage, setErrorMessage] = useState('')

	const [userEmail, setUserEmail] = useState('')
	const [emailValidation, setEmailValidation] = useState(true)
	const [emailBlurred, setEmailBlurred] = useState(false)

	const [userPassword, setUserPassword] = useState('')
	const [passwordConfirmed, setPasswordConfirmed] = useState('')

	const [code, setCode] = useState('')
	const [codeValidation, setCodeValidation] = useState(true)
	const [codeBlurred, setCodeBlurred] = useState(false)

	const [view, setView] = useState('email')
	const enabledEmailButton = isEmailValid(userEmail)
	const [enabledChangePWButton, setEnabledChangePWButton] =
		useState(true)

	const emailOnChange = (val) => {
		setUserEmail(applyMask(val, 'email'))
		setEmailValidation(isEmailValid(applyMask(val, 'email')))
	}

	const emailOnBlur = (val) => {
		setEmailBlurred(true)
		setEmailValidation(isEmailValid(applyMask(val, 'email')))
	}

	const codeConfirmOnChange = (val) => {
		setCode(applyMask(val, 'numeric'))
		setCodeValidation(applyMask(val, 'numeric').length === 6)
	}
	const codeOnBlur = (val) => {
		setCodeBlurred(true)
		setCodeValidation(applyMask(val, 'numeric').length === 6)
	}

	const handleSendEmail = () => {
		if (emailValidation) {
			setLoading(true)
			setErrorMessage('')
			TheBigPOSApi.forgotPassword({
				email: userEmail,
				siteConfigurationId: siteConfig.id,
			})
				.then(() => {
					setView('code')
					mixpanel.trackEvent(eventTypes.FORGOT_PASSWORD_SUBMITTED, {
						email: userEmail,
					})
				})
				.catch((err) => {
					setErrorMessage(
						err.data === 'User was not found'
							? `The email address you entered was not found in our system`
							: err?.data?.message ||
									err?.data ||
									theme.api_messages.server_error
					)
				})
				.finally(() => setLoading(false))
		}
	}

	const handleChangePassword = () => {
		setLoading(true)
		setErrorMessage('')
		TheBigPOSApi.verifyPassword({
			email: userEmail,
			code: code,
			password: userPassword,
			siteConfigurationId: siteConfig.id,
		})
			.then(() => {
				setModalConfirmation({ ...modalConfirmation, open: true })
				alert('Your password has been changed successfully')
				navigate(`/${navigationLinking.SignIn}`, {
					state: { email: userEmail, passwordChange: true },
				})
			})
			.catch((err) => {
				setLoading(false)
				setErrorMessage(getErrorMessage(err))
			})
	}

	const onKeyDownHandler = (event) => {
		if (event?.keyCode === 13) {
			handleSendEmail()
		}
	}

	return (
		<AuthWrapper
			siteConfig={siteConfig}
			onKeyDown={onKeyDownHandler}
			title="Forgot Password"
		>
			<>
				<img
					className="w-48 mb-5 lg:mb-0 lg:w-72 lg:absolute lg:top-5 lg:right-5"
					src={
						isDarkMode() && siteConfig?.darkModePortalLogoUrl
							? siteConfig.darkModePortalLogoUrl
							: siteConfig?.portalLogoUrl || siteConfig?.logoUrl
					}
					alt="Logo"
				/>
				<p className="text-lg sm:text-2xl font-bold mb-6 dark:text-white">
					{view === 'email' ? 'Reset' : 'Set'} Your Password
				</p>
				{view === 'email' && (
					<>
						<TextField
							onChange={(e) => emailOnChange(e.target.value)}
							onBlur={(e) => emailOnBlur(e.target.value)}
							value={userEmail}
							label={userEmail === '' ? null : 'Email Address'}
							placeholder="Enter your email address"
							error={!emailValidation && emailBlurred}
							id={`SignInEmailInput`}
							helperText={
								!emailValidation && emailBlurred
									? 'Enter a valid email address'
									: ' '
							}
							variant="standard"
							className="w-64 sm:w-96 sm:px-12"
						/>
						{errorMessage && (
							<p
								className={[
									errorMessage
										? 'text-sm text-red-500 mt-5'
										: 'hidden',
								]}
							>
								{errorMessage}
							</p>
						)}
						<LoadingBtn
							id={`ForgotPasswordButton`}
							disabled={!enabledEmailButton}
							loading={loading}
							onClick={handleSendEmail}
							text="Submit"
						/>
						<div className="mt-4">
							<Typography>
								<span className="text-sm dark:text-white">
									I think I remember.&nbsp;
								</span>
								<Link
									className="text-blue-500 cursor-pointer"
									onClick={() => {
										navigate(`/${navigationLinking.SignIn}`)
									}}
									rel="noopener noreferrer"
									underline="hover"
									style={{
										color: theme.siteConfig.color.primary,
										marginTop: 16,
									}}
								>
									<Typography variant="link" className="text-sm">
										Sign In
									</Typography>
								</Link>
							</Typography>
						</div>
					</>
				)}
				{view === 'code' && (
					<>
						<TextField
							onChange={(e) => codeConfirmOnChange(e.target.value)}
							onBlur={(e) => codeOnBlur(e.target.value)}
							value={code}
							label={code === '' ? null : 'Verification Code'}
							id={`ForgotPasswordCode`}
							placeholder="Verification Code"
							error={!codeValidation && codeBlurred}
							helperText={
								!codeValidation && codeBlurred
									? 'Verification code must be 6 numbers'
									: ''
							}
							variant="standard"
							className="w-64 sm:w-96 sm:px-12"
							inputProps={{ maxLength: 6 }}
						/>

						{/* hidden field to prevent browser from autocompleting code field as a username
						 * This is a known issue in Chrome / Firefox
						 * Attempted / FAILED: autoComplete="off" / autoComplete="new-password" / inputProps autoComplete / inputProps.form.autoComplete / style display: none / type: hidden
						 * Tested / SUCCESS in Chrome 112.0 and Firefox 112.0.1
						 */}
						<div
							style={{ zIndex: -5000, opacity: 0, position: 'fixed' }}
						>
							<input type="text" value="" tabIndex={-1} />
						</div>

						<ChangePassword
							id={`ForgotPassword`}
							passwordLabel={'New password'}
							confirmPasswordLabel={'Confirm new password'}
							userPassword={userPassword}
							setUserPassword={setUserPassword}
							handleSubmit={handleChangePassword}
							setConfirmButton={setEnabledChangePWButton}
							confirmButtonEnabled={enabledChangePWButton}
							userEmail={userEmail}
							passwordConfirmed={passwordConfirmed}
							setPasswordConfirmed={setPasswordConfirmed}
							email={true}
						/>
						{errorMessage && (
							<p
								className={[
									errorMessage
										? 'text-sm text-red-500 mt-5'
										: 'hidden',
								]}
							>
								{errorMessage}
							</p>
						)}
						<div className="w-full mt-4">
							<LoadingBtn
								id={`ForgotPasswordChangePWButton`}
								disabled={
									!enabledChangePWButton ||
									!code ||
									!codeValidation ||
									!userPassword ||
									!passwordConfirmed
								}
								loading={loading}
								onClick={handleChangePassword}
								text="Reset Password"
							/>
						</div>
						<div className="mt-4">
							<Typography>
								<span className="text-sm">
									Didn't receive a code?&nbsp;
								</span>
								<Link
									className="text-blue-500 cursor-pointer"
									onClick={handleSendEmail}
									rel="noopener noreferrer"
									underline="hover"
									style={{
										color: theme.siteConfig.color.primary,
										marginTop: 16,
									}}
								>
									<Typography variant="link" className="text-sm">
										Resend
									</Typography>
								</Link>
							</Typography>
						</div>
					</>
				)}
			</>
		</AuthWrapper>
	)
}
