import React from 'react'
import clsx from 'clsx'
import {
	CheckCircleOutlined,
	ErrorOutlineOutlined,
	InfoOutlined,
	WarningOutlined,
} from '@mui/icons-material'
import { merge } from 'lodash'

export type AlertBoxProps = {
	severity?: 'info' | 'success' | 'warning' | 'error'
	className?: string
	children: React.ReactNode | string | number
	icon?: {
		position?: 'top' | 'center'
		visible?: boolean
	}
}

const defaultIconProps: AlertBoxProps['icon'] = {
	position: 'center',
	visible: true,
}

const AlertBox = ({
	severity = 'info',
	children,
	className,
	icon,
}: AlertBoxProps) => {
	const iconMap = {
		info: <InfoOutlined />,
		success: <CheckCircleOutlined />,
		warning: <WarningOutlined />,
		error: <ErrorOutlineOutlined />,
	}

	const classMap = {
		info: 'bg-blue-100 text-blue-800 border-blue-200',
		success: 'bg-green-100 text-green-700 border-green-200',
		warning: 'bg-orange-100 text-orange-700 border-orange-200',
		error: 'bg-red-100 text-red-700 border-red-200',
	}

	const mergedIconProps: AlertBoxProps['icon'] = icon
		? merge({}, defaultIconProps, icon)
		: defaultIconProps

	return (
		<div
			className={clsx(
				'font-bold border rounded px-3 py-2',
				classMap[severity],
				className
			)}
		>
			<div
				className={clsx(
					'flex flex-row',
					mergedIconProps.position === 'center'
						? 'items-center'
						: undefined
				)}
			>
				{mergedIconProps.visible && (
					<div
						className={clsx(
							'mr-2',
							mergedIconProps.position === 'top' ? 'mt-1' : undefined
						)}
					>
						{iconMap[severity]}
					</div>
				)}
				<div className="flex-grow">{children}</div>
			</div>
		</div>
	)
}

export default AlertBox
