import React, { useState } from 'react'
import {
	FormControlLabel,
	FormHelperText,
	Switch,
} from '@mui/material'
import { useAppContext } from '../../../components/AppContext'
import { useAlert } from '../../../hooks'
import { getErrorMessage } from '../../../services/helper'
import ModalMobilePhoneVerificationModal from '../../../components/modals/ModalMobilePhoneVerificationModal'
import useUser from '../../../hooks/useUser'
import { useMixpanel } from '../../../hooks/useMixpanel'
import { eventTypes } from '../../../services/constants'

const UserProfileSettings = () => {
	const { alert } = useAlert()
	const { state } = useAppContext()
	const { user, siteConfig } = state
	const { updateMe } = useUser()
	const mixpanel = useMixpanel()

	const [phoneModalOpen, setPhoneModalOpen] = React.useState(false)
	const [loading, setLoading] = useState(false)

	const updateUserMFASetting = async (isChecked) => {
		try {
			setLoading(true)
			await updateMe({ ...user, mfaEnabled: isChecked })
			mixpanel.trackEvent(eventTypes.USER_PROFILE_MFA_TOGGLED, {
				mfaEnabled: isChecked,
			})
			setPhoneModalOpen(false)
			alert('Your profile settings have been updated', {
				severity: 'success',
			})
		} catch (e) {
			alert(getErrorMessage(e), { severity: 'error' })
		} finally {
			setLoading(false)
		}
	}

	const handleMFAChange = async (event) => {
		const isChecked = event.target.checked
		if (isChecked) {
			setPhoneModalOpen(true)
		} else {
			await updateUserMFASetting(isChecked)
		}
	}

	const onMobileDialogClose = () => {
		setPhoneModalOpen(false)
	}

	const onMFAConfirmationComplete = () => {
		setPhoneModalOpen(false)
		alert(`Your profile settings have been updated`, {
			severity: 'success',
		})
	}

	return (
		<>
			<ModalMobilePhoneVerificationModal
				open={phoneModalOpen}
				onClose={onMobileDialogClose}
				onComplete={onMFAConfirmationComplete}
			/>
			{(siteConfig.mfaPreference !== 'Disabled' ||
				user.mfaEnabled) && (
				<div>
					<div>
						<FormControlLabel
							label="Enable Multi-Factor Authentication (SMS)"
							control={
								<Switch
									checked={user.mfaEnabled}
									onChange={handleMFAChange}
									disabled={
										siteConfig.mfaPreference === 'Required' || loading
									}
								/>
							}
						/>
						{siteConfig.mfaPreference === 'Required' && (
							<FormHelperText>
								MFA is required for your organization
							</FormHelperText>
						)}
					</div>
					{user.phoneVerified && user.mfaEnabled && (
						<div>
							<p className="text-xs">
								Update your MFA phone number in the Personal
								Information tab.
							</p>
						</div>
					)}
				</div>
			)}
		</>
	)
}

export default UserProfileSettings
