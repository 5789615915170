import React from 'react'
import { Button } from '../Button'
import { ButtonProps } from '../Button/Button'
import { TheBigPOSApi } from '../../lib/TheBigPOSClient'
import { downloadBlob } from '../../services/utils'

export type DownloadSSOMetadataButtonProps = {
	ssoIntegration: 'ConsumerConnect' | 'TheBigPOS'
} & Omit<ButtonProps, 'onClick'>

const DownloadSSOMetadataButton = ({
	ssoIntegration,
	...rest
}: DownloadSSOMetadataButtonProps) => {
	const handleClick = async () => {
		const response = await TheBigPOSApi.getSamlMetadata(
			ssoIntegration, // Bug in the SDK requires 2 params instead of 1. The first param is not used
			ssoIntegration.toLowerCase(),
			{ format: 'blob' }
		)

		const filename = `${ssoIntegration.toLowerCase()}_metadata.xml`
		downloadBlob(response.data, filename)
	}

	return <Button {...rest} onClick={handleClick} />
}

export default DownloadSSOMetadataButton
