import React, { useEffect, useMemo } from 'react'
import { Checkbox, Form } from '../../../../components/formControls'
import { Button, FormHelperText } from '@mui/material'
import { LoadingBtn } from '../../../../components/Button'
import useForm from '../../../../hooks/useForm'
import { useFieldArray } from 'react-hook-form'
import clsx from 'clsx'
import * as yup from 'yup'
import { cloneDeep } from 'lodash'
import ErrorText from '../../../../components/ErrorText'

const TaskCheckboxForm = ({
	tasks,
	onSubmit,
	onClose = undefined,
	submitLabel = 'Save',
}) => {
	const schema = yup.object().shape({
		tasks: yup
			.array()
			.test(
				'at-least-one',
				'At least one task must be selected',
				(value) => {
					return value.some((task) => task.checked)
				}
			),
	})

	const {
		handleSubmit,
		formState: { isSubmitting, errors, touchedFields, isSubmitted },
		control,
		getValues,
	} = useForm({
		schema,
		defaultValues: {
			tasks: [],
		},
	})

	const { replace } = useFieldArray({
		control,
		name: 'tasks',
	})

	const allTasks = useMemo(() => {
		const values = getValues()
		const checkedTasks = cloneDeep(values.tasks).filter(
			(task) => task.checked
		)
		const mergedTasks = cloneDeep(tasks)
		checkedTasks.forEach((checkedTask) => {
			const existingTask = mergedTasks.find(
				(t) => t.id === checkedTask.id
			)
			if (!existingTask) {
				mergedTasks.unshift(checkedTask)
			}
		})

		return mergedTasks
	}, [tasks])

	useEffect(() => {
		replace(allTasks)
	}, [allTasks])

	const filterValuesAndSubmit = async () => {
		const values = getValues()
		const checkedTasks = values.tasks.filter((task) => task.checked)
		await onSubmit(checkedTasks)
	}

	return (
		<Form onSubmit={handleSubmit(filterValuesAndSubmit)}>
			{errors.tasks && isSubmitted && (
				<ErrorText>*{errors.tasks?.message}</ErrorText>
			)}

			{allTasks.map((task, i) => {
				const { name, description } = task
				return (
					<div key={task.id} className="mb-1">
						<Checkbox
							name={`tasks[${i}].checked`}
							label={name}
							control={control}
							checked={task.checked}
							helperText={description}
						/>
					</div>
				)
			})}
			<div
				className={clsx(
					'flex flex-row items-center mt-3',
					onClose ? 'justify-between' : 'justify-end'
				)}
			>
				{onClose && (
					<div className="mr-2">
						<Button id="Cancel" onClick={onClose} variant="outlined">
							Cancel
						</Button>
					</div>
				)}

				<LoadingBtn
					type="submit"
					text={submitLabel}
					loading={isSubmitting}
					fullWidth={false}
				/>
			</div>
		</Form>
	)
}

export default TaskCheckboxForm
