import React, { useState, useEffect } from 'react'
import { LoanDetailsCard } from './LoanDetailsCard'
import LoanMilestones from './LoanMilestones'

const title = 'Loan Information and Property Details'
const fields = [
	{ key: 'LoanAmount', title: 'Loan Amount', value: null },
	{ key: 'LoanTerm', title: 'Loan Term', value: null },
	{
		key: 'SubjectPropertySalePrice',
		title: 'Price of New Home',
		value: null,
	},
	{
		key: 'DownPaymentAmount',
		title: 'Down Payment Amount',
		value: null,
	},
	{ key: 'DownPaymentGiftAmount', title: 'Gift Amount', value: null },

	{ key: 'LoanPurpose', title: 'Loan Purpose', value: null },
	{
		key: 'SubjectPropertyResidencyType',
		title: 'Residency Type',
		value: null,
	},
	{ key: 'SubjectPropertyUse', title: 'Occupancy Type', value: null },
	{
		key: 'IsMilitaryService',
		title: 'Military Service',
		value: null,
	},
	{
		key: 'IsHomeOwnershipEducation',
		title: 'Home Ownership Education',
		value: null,
	},
	{
		key: 'SubjectPropertyLocation',
		title: 'Property Location',
		value: [
			{
				key: 'SubjectPropertyAddressCity',
				title: 'City',
				value: null,
			},
			{
				key: 'SubjectPropertyAddressState',
				title: 'State',
				value: null,
			},
			{ key: 'SubjectPropertyAddressZip', title: 'Zip', value: null },
			{
				key: 'SubjectPropertyAddressCounty',
				title: 'County',
				value: null,
			},
			{
				key: 'SubjectPropertyResidencyType',
				title: 'Residency Type',
				value: null,
			},
			{
				key: 'SubjectPropertyNumUnits',
				title: 'Number of Units',
				value: null,
			},
		],
	},

	{ key: 'SubjectPropertyUse', title: 'Property Use', value: null },
	{
		key: 'SubjectPropertyYearBuilt',
		title: 'Year Property Built',
		value: null,
	},
]

export const LoanInfo = ({ data, loading }) => {
	const [loanInfo, setLoanInfo] = useState([])

	useEffect(() => {
		if (data?.hasOwnProperty('LoanAmount') && !loading) {
			setLoanInfo(
				fields.map((p) => {
					// Preferred Loan Term
					if (p.key === 'LoanTerm') {
						return {
							key: 'LoanTerm',
							title: 'Preferred Loan Term',
							value: `${parseInt(data[p.key] / 12)} years`,
						}
					}

					// Price of New Home
					else if (p.key === 'SubjectPropertySalePrice') {
						return {
							key: 'SubjectPropertySalePrice',
							title: 'Price of New Home',
							value: `$${data.SubjectPropertySalePrice}`,
						}
					}

					// Loan Amount Requested
					else if (p.key === 'LoanAmount') {
						return {
							key: 'LoanAmount',
							title: 'Loan Amount Requested',
							value: `$${data.LoanAmount}`,
						}
					}

					// Down Payment
					else if (p.key === 'DownPaymentAmount') {
						return {
							key: 'DownPaymentAmount',
							title: 'Down Payment Amount',
							value: `$${data.DownPaymentAmount}`,
						}
					}

					// Gift Amount
					else if (p.key === 'DownPaymentGiftAmount') {
						return {
							key: 'DownPaymentGiftAmount',
							title: 'Gift Amount',
							value: `$${data.DownPaymentGiftAmount || 0}`,
						}
					} else if (p.key === 'SubjectPropertyLocation') {
						return {
							key: 'SubjectPropertyLocation',
							title: 'Property Location',
							value: [
								{
									key: 'SubjectPropertyAddressCity',
									title: 'City',
									value: data.SubjectPropertyAddressCity,
								},
								{
									key: 'SubjectPropertyAddressState',
									title: 'State',
									value: data.SubjectPropertyAddressState,
								},
								{
									key: 'SubjectPropertyAddressZip',
									title: 'Zip',
									value: data.SubjectPropertyAddressZip,
								},
								{
									key: 'SubjectPropertyAddressCounty',
									title: 'County',
									value: data.SubjectPropertyAddressCounty,
								},
								{
									key: 'SubjectPropertyResidencyType',
									title: 'Residency Type',
									value: data.SubjectPropertyResidencyType,
								},
								{
									key: 'SubjectPropertyNumUnits',
									title: 'Number of Units',
									value: data.SubjectPropertyNumUnits,
								},
							],
						}
					} else {
						p.value =
							typeof data[p.key] !== 'undefined' || data[p.key] === ''
								? `$${data[p.key]}`
								: ''
					}
					p.value = data[p.key]
					return p
				})
			)
		}
	}, [data, loading])

	return (
		<LoanDetailsCard
			title={title}
			data={loanInfo}
			loading={loading}
			style={{ marginBottom: 0 }}
			cardIndex={0}
		/>
	)
}
