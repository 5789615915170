import React from 'react'
import { UserLoanTask } from '@matech/thebigpos-sdk'
import TaskActionButton from './TaskActionButton'
import ModalLoanTask from './ModalLoanTask'

export type LoanTaskActionButtonProps = {
	loanTask: UserLoanTask
	onChange?: () => void
}
const LoanTaskActionButton = ({
	loanTask,
	onChange = undefined,
}: LoanTaskActionButtonProps) => {
	const { task } = loanTask
	const [modalOpen, setModalOpen] = React.useState(false)

	const handleClick = () => {
		setModalOpen(true)
	}

	const handleSubmit = async () => {
		setModalOpen(false)
		if (onChange) {
			await onChange()
		}
	}

	const handleClose = () => {
		setModalOpen(false)
	}

	return (
		<>
			<ModalLoanTask
				open={modalOpen}
				loanTask={loanTask}
				onSubmit={handleSubmit}
				onCancel={handleClose}
			/>
			<TaskActionButton
				task={task}
				status={loanTask.status}
				onClick={handleClick}
			/>
		</>
	)
}

export default LoanTaskActionButton
