import * as React from 'react'
import clsx from 'clsx'

const alignMap = {
	left: 'flex-start',
	right: 'flex-end',
	center: 'center',
}

type AlignItems = 'center' | 'left' | 'right'

export type SubheaderProps = {
	align?: AlignItems
	wrapContent?: boolean
	children?: React.ReactNode
}

const SubHeader = ({
	align = 'right',
	wrapContent = true,
	...rest
}: SubheaderProps) => (
	<header
		className={clsx(
			'flex items-center',
			wrapContent ? 'wrap' : 'nowrap',
			align ? alignMap[align] : 'left'
		)}
		{...rest}
	/>
)

export default SubHeader
