import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import queryKeys from '../../../../services/queryKeys'
import TaskImportStepper from './TaskImportStepper'
import Dialog from '../../../../components/modals/Dialog'
import useSearchText from '../../../../hooks/useSearchText'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'

const ModalImportTasks = ({
	type,
	onSave,
	onClose,
	loanId,
	borrowers,
	...rest
}) => {
	const [isSaving, setIsSaving] = React.useState(false)
	const ONE_SECOND_IN_MILLISECONDS = 60 * 1000
	const typeMap = {
		existing: {
			title: 'Add from Existing Tasks',
			fetch: async () => {
				const result = await TheBigPOSApi.searchTasks({
					searchText,
					isGlobal: true,
				})
				return result.data.rows
			},
			queryKeys: [queryKeys.tasks],
		},
		preliminaryConditions: {
			title: 'Add from Preliminary Conditions',
			fetch: async () => {
				const results =
					await TheBigPOSApi.getPreliminaryConditionsForLoan(loanId)
				return formatAndFilterConditions(results.data)
			},
			queryKeys: [queryKeys.loanPreliminaryConditions, loanId],
			staleTime: ONE_SECOND_IN_MILLISECONDS,
		},
		underwritingConditions: {
			title: 'Add from UW Conditions',
			fetch: async () => {
				const results =
					await TheBigPOSApi.getUnderwritingConditionsForLoan(loanId)
				return formatAndFilterConditions(results.data)
			},
			queryKeys: [queryKeys.loanUnderwritingConditions, loanId],
			staleTime: ONE_SECOND_IN_MILLISECONDS,
		},
	}

	const formatAndFilterConditions = (conditions) => {
		const formattedConditions = conditions.map(
			({ id: losId, title: name, description }) => ({
				losId,
				name,
				description,
			})
		)

		return formattedConditions.filter(
			(c) =>
				c.name.toLowerCase().includes(searchText) ||
				c.description?.toLowerCase().includes(searchText)
		)
	}

	const {
		isFetching,
		data: taskTemplates,
		refetch,
	} = useQuery({
		queryKey: typeMap[type].queryKeys,
		queryFn: typeMap[type].fetch,
	})

	const {
		searchText,
		onChange: onSearchChange,
		onClear: onSearchClear,
	} = useSearchText({
		onFetch: type === 'existing' ? refetch : undefined,
	})

	const typeData = useMemo(() => {
		return typeMap[type || 'existing']
	}, [type])

	const handleSubmit = async (tasks) => {
		setIsSaving(true)
		await onSave(tasks)
		setIsSaving(false)
		handleDialogClose()
	}

	const handleDialogClose = () => {
		if (onClose) {
			onClose()
		}
	}

	const filteredTaskTemplates = useMemo(() => {
		if (!taskTemplates) return []

		if (type !== 'existing') {
			return taskTemplates.filter(
				(c) =>
					c.name.toLowerCase().includes(searchText) ||
					c.description?.toLowerCase().includes(searchText)
			)
		}

		return taskTemplates
	}, [taskTemplates, searchText, type])

	return (
		<Dialog
			{...rest}
			onClose={handleDialogClose}
			maxWidth="md"
			fullWidth
			title={typeData ? typeData.title : 'Import tasks'}
		>
			<TaskImportStepper
				tasks={filteredTaskTemplates || []}
				borrowers={borrowers}
				onSubmit={handleSubmit}
				searchText={searchText}
				onSearchChange={onSearchChange}
				onSearchClear={onSearchClear}
				loanId={loanId}
				loading={isFetching || isSaving}
			/>
		</Dialog>
	)
}

export default ModalImportTasks
