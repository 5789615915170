import React, { useState } from 'react'
import * as yup from 'yup'
import { BranchUser } from '@matech/thebigpos-sdk'
import useForm from '../../../../hooks/useForm'
import { Button, LoadingBtn } from '../../../../components/Button'
import { Form, FormRow } from '../../../../components/formControls'
import Autocomplete from '../../../../components/formControls/Autocomplete'

type AssignLoanOfficerFormProps = {
	onClose: () => void
	onSubmit: (values: BranchUser | undefined | null) => Promise<void>
	loanOfficers: BranchUser[]
	selectedLoanOfficer?: BranchUser | null
	isLoading: boolean
	onInputValueChange: (value: string | null) => void
}

const AssignLoanOfficerForm = ({
	onSubmit,
	onClose,
	loanOfficers,
	selectedLoanOfficer,
	isLoading,
	onInputValueChange,
}: AssignLoanOfficerFormProps) => {
	const schema = yup.object().shape({
		loanOfficer: yup.string(),
	})

	const [currentSelectedValue, setCurrentSelectedValue] = useState<
		BranchUser | null | undefined
	>(selectedLoanOfficer)

	const form = useForm({
		schema,
		resetOptions: {
			keepValues: false,
		},
	})

	const {
		formState: { isSubmitting },
		handleSubmit,
		reset,
	} = form

	const submitForm = async () => {
		await onSubmit(currentSelectedValue)
		onClose()
	}

	const handleCancel = () => {
		onClose()
		reset({ loanOfficer: '' })
	}

	return (
		<Form noValidate onSubmit={handleSubmit(submitForm)}>
			<FormRow>
				<Autocomplete<BranchUser>
					id="loan-officer-autocomplete"
					label="Loan officer"
					options={loanOfficers || []}
					initialValue={currentSelectedValue}
					value={currentSelectedValue?.id}
					getOptionLabel={(option) =>
						`${option.firstName} ${option.lastName}` || ''
					}
					isLoading={isLoading}
					renderOption={(props, option: BranchUser) => {
						return (
							<li {...props} key={option?.id}>
								{`${option.firstName} ${option.lastName}`}
							</li>
						)
					}}
					isOptionEqualToValue={(option, model) =>
						option?.id === model?.id
					}
					onChange={(value: BranchUser | BranchUser[] | null) => {
						if (Array.isArray(value)) return
						setCurrentSelectedValue(value)
					}}
					onInputValueChange={onInputValueChange}
				/>
			</FormRow>
			<div className="mt-5 flex justify-between space-x-4">
				<Button
					text="Cancel"
					onClick={handleCancel}
					className="flex-grow w-full"
					variant="outlined"
				/>
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Update"
				/>
			</div>
		</Form>
	)
}

export default AssignLoanOfficerForm
