import React from 'react'
import ReactDOM from 'react-dom/client'
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import {
	BrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom'
import './index.css'
import * as Sentry from '@sentry/react'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './components/AppContext/AppProvider'
import { AlertsProvider } from './components/Alerts/AlertsProvider'
import AppTheme from './AppTheme'
import config from './config'
import packageInfo from '../package.json'
import { addCustomYupValidators } from './services/yupValidation'
import { MixpanelProvider } from './components/MixPanel/MixPanelProvider'
import LanguageProvider from './contexts/LanguageContext'

const queryClient = new QueryClient()

Sentry.init({
	dsn: config.logging.sentryDSN,
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes,
		}),
		Sentry.replayIntegration(),
	],
	tracesSampleRate: 1.0,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	release: packageInfo.version,
})

addCustomYupValidators()

if (
	localStorage.theme === 'dark' ||
	(!('theme' in localStorage) &&
		window.matchMedia('(prefers-color-scheme: dark)').matches)
) {
	localStorage.theme = 'dark'
	document.documentElement.classList.add('dark')
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<LanguageProvider>
					<MixpanelProvider>
						<AppProvider>
							<AlertsProvider>
								<AppTheme />
							</AlertsProvider>
						</AppProvider>
					</MixpanelProvider>
				</LanguageProvider>
			</QueryClientProvider>
		</BrowserRouter>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
