import React from 'react'

type NoImagePlaceholderProps = {
	value?: string
	inheritedValue?: string
	disabled: boolean
}

const NoImagePlaceholder = ({
	value,
	inheritedValue,
	disabled,
}: NoImagePlaceholderProps) => {
	const hasNoValue = !disabled ? !value : !inheritedValue
	return hasNoValue ? (
		<span
			className="absolute inset-0 text-red-500 font-bold flex items-center justify-center w-full h-full"
			style={{
				fontSize: '3rem',
			}}
		>
			X
		</span>
	) : null
}

export default NoImagePlaceholder
