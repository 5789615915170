import React from 'react'
import clsx from 'clsx'

export type GridRowProps = {
	className?: string
	columns?: 2 | 3
	children?: React.ReactNode
}

const LayoutRowGrid = ({
	className,
	columns,
	children,
}: GridRowProps) => {
	let mergedClassName = className

	if (columns === 2) {
		mergedClassName = clsx('md:grid-cols-2', mergedClassName)
	}

	if (columns === 3) {
		mergedClassName = clsx('md:grid-cols-3', mergedClassName)
	}

	return (
		<div
			className={clsx('grid grid-cols-1 mb-3 gap-4', mergedClassName)}
		>
			{children}
		</div>
	)
}

LayoutRowGrid.defaultProps = {
	className: undefined,
	columns: undefined,
}

export default LayoutRowGrid
