import { BranchUser } from '@matech/thebigpos-sdk'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DialogProps } from '@mui/material'
import Dialog from '../../../../components/modals/Dialog'
import AssignLoanOfficerForm from '../forms/AssignLoanOfficerForm'
import queryKeys from '../../../../services/queryKeys'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'

type AssignLoanOfficerModalProps = {
	onSubmit: (
		loanOfficer: BranchUser | null | undefined
	) => Promise<void>
	item?: BranchUser
	onClose: () => void
} & Omit<DialogProps, 'onSubmit' | 'onClose'>

const AssignLoanOfficerModal = ({
	onSubmit,
	item,
	onClose,
	...rest
}: AssignLoanOfficerModalProps) => {
	const [searchText, setSearchText] = useState('')
	const searchCriteria = { searchText }

	const {
		isFetching,
		isRefetching,
		data: result,
	} = useQuery({
		queryKey: [queryKeys.loanOfficers, searchCriteria],
		queryFn: () =>
			TheBigPOSApi.searchLoanOfficers(searchCriteria, {
				pageNumber: 1,
				pageSize: 25,
			}),
	})

	return (
		<Dialog
			title="Change Loan Officer"
			onClose={onClose}
			{...rest}
			fullWidth
		>
			<p className="pb-5 text-md">
				Select a loan officer from the list
			</p>

			<AssignLoanOfficerForm
				onSubmit={onSubmit}
				onClose={onClose}
				loanOfficers={result?.data?.rows || []}
				selectedLoanOfficer={item}
				isLoading={isFetching || isRefetching}
				onInputValueChange={(value: string | null) =>
					setSearchText(value || '')
				}
			/>
		</Dialog>
	)
}

export default AssignLoanOfficerModal
