import React from 'react'
import { IconButton as MuiIconButton } from '@mui/material'
import type { IconButtonProps as MuiIconButtonProps } from '@mui/material/IconButton'

export type IconButtonProps = {
	children: React.ReactNode
} & MuiIconButtonProps

const IconButton = ({
	children,
	...iconButtonProps
}: IconButtonProps) => {
	return (
		<MuiIconButton {...iconButtonProps}>{children}</MuiIconButton>
	)
}

export default IconButton
