import React from 'react'
import clsx from 'clsx'

export type FormRowProps = {
	className?: string
	children: React.ReactNode
}

const FormRow = ({ className, children }: FormRowProps) => {
	return <div className={clsx('mb-3', className)}>{children}</div>
}

export default FormRow
