import React, { useState, useEffect, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { useVenti } from 'venti'
import { navigationLinking } from './services/navigation'
import PrivateRoute from './routes/PrivateRoute'
import { HeaderNavigation } from './components/HeaderNavigation'
import { useWindowSize } from './hooks'
import { ModalError } from './components/modals/ModalError'
import { getTheme } from './config'
import { useAppContext } from './components/AppContext'
import { ModalPleaseWait } from './components/modals/ModalPleaseWait'
import { ModalSuccess } from './components/modals/ModalSuccess'
import { ModalInfo } from './components/modals/ModalInfo'
import { ModalSessionExpired } from './components/modals/ModalSessionExpired'
import Suspense from './components/Suspense'

// OPEN
import CreateAccount from './pages/auth/CreateAccount'
import SignIn from './pages/auth/SignIn'
import ForgotPassword from './pages/auth/ForgotPassword'
import Landing from './pages/open/Landing'
import Pricing from './pages/open/pricing/Pricing'
import PricingResults from './pages/open/pricing/PricingResults'
import PricingDetails from './pages/open/pricing/PricingDetails'
import Disclosures from './pages/open/Disclosures'

// PROTECTED
import Portal from './pages/protected/Portal'
import UserProfile from './pages/protected/userProfile/UserProfile'
import QuickPricer from './pages/protected/QuickPricer'
import Pipeline from './pages/protected/pipeline/Pipeline'
import LoanDetails from './pages/protected/loanDetails/LoanDetails'
import Fees from './pages/protected/Fees'
import LoanApp from './pages/loanapp/LoanApp'
import Tasks from './pages/protected/tasks/Tasks'
import Documents from './pages/protected/loanDocuments/LoanDocuments'
import LoanCalculator from './pages/protected/loanCalculator/LoanCalculator'
import LoanPricingResults from './pages/protected/LoanPricingResults'

// ADMIN
import AccountEdit from './pages/admin/Account/AccountEdit'
import AdminRules from './pages/admin/Rules/Rules'

import AdminRuleTasks from './pages/admin/Rules/RuleTasks'
import AdminCorporations from './pages/admin/Corporations/Corporations'
import AdminCorporateEdit from './pages/admin/Corporations/CorporateEdit'
import AdminLoanOfficers from './pages/admin/LoanOfficers/LoanOfficers'
import AdminLoanOfficerEdit from './pages/admin/LoanOfficers/LoanOfficerEdit'
import AdminBranches from './pages/admin/Branches/Branches'
import AdminBranchEdit from './pages/admin/Branches/BranchEdit'
import AdminUsers from './pages/admin/Users/Users'
import AdminNotificationTemplates from './pages/admin/NotificationTemplates/NotificationTemplates'
import AdminPartners from './pages/admin/Partners/Partners'
import AdminPartnerEdit from './pages/admin/Partners/PartnerEdit'
import AdminPartnerProfile from './pages/admin/Partners/Profile'
import AdminWorkflows from './pages/admin/Workflows/Workflows'
import AdminWorkflowEdit from './pages/admin/Workflows/WorkflowEdit'
import AdminDevices from './pages/admin/Devices/Devices'
import AdminDeviceEdit from './pages/admin/Devices/DeviceEdit'
import AdminDocumentTemplates from './pages/admin/DocumentTemplates/DocumentTemplates'
import Partners from './pages/protected/partners/Partners'
import ErrorBoundary from './components/ErrorBoundary'
import SMSVerification from './pages/auth/SMSVerification'
import AuthChallenge from './pages/auth/AuthChallenge'
import { Roles } from './services/client'
import DisclosuresSSO from './pages/open/DisclosuresSSO'
import useUser from './hooks/useUser'
import IncompleteLoans from './pages/protected/incompleteLoans/IncompleteLoans'

const Leads = lazy(() => import('./pages/protected/leads'))
const LeadAdd = lazy(() => import('./pages/protected/leads/AddLead'))
const LeadEdit = lazy(
	() => import('./pages/protected/leads/EditLead')
)
const Listings = lazy(
	() => import('./pages/protected/listings/Listings')
)
const ListingAdd = lazy(
	() => import('./pages/protected/listings/AddListing')
)
const ListingEdit = lazy(
	() => import('./pages/protected/listings/EditListing')
)
const ListingDetail = lazy(
	() => import('./pages/protected/listings/ListingDetail')
)
const ListingLanding = lazy(
	() => import('./pages/open/listingLanding/ListingLanding')
)
const ImpersonationConfirmation = lazy(
	() => import('./pages/open/ImpersonationConfirmation')
)

const AddDocumentTemplate = lazy(
	() => import('./pages/admin/DocumentTemplates/AddDocumentTemplate')
)

const EditDocumentTemplate = lazy(
	() => import('./pages/admin/DocumentTemplates/EditDocumentTemplate')
)

const AddNotificationTemplate = lazy(
	() =>
		import(
			'./pages/admin/NotificationTemplates/AddNotificationTemplate'
		)
)

const EditNotificationTemplate = lazy(
	() =>
		import(
			'./pages/admin/NotificationTemplates/EditNotificationTemplate'
		)
)

const AdminLoanQueue = lazy(
	() => import('./pages/admin/LoanQueue/LoanQueue')
)

const theme = getTheme()

let interval = null

const openLoanAppRoutes = [
	navigationLinking.Apply,
	navigationLinking.InviteApply,
	navigationLinking.ApplySpanish,
	navigationLinking.InviteApplySpanish,
	navigationLinking.Prequalify,
	navigationLinking.PrequalifySpanish,
	navigationLinking.InvitePrequalify,
	navigationLinking.InvitePrequalifySpanish,
	navigationLinking.PrequalifyCoBorrower,
	navigationLinking.ApplyCoBorrower,
	navigationLinking.LeadApp,
	navigationLinking.OpenHouse,
	navigationLinking.OpenHouseWithListing,
	navigationLinking.OpenHouseNoAddress,
	navigationLinking.BuyerClient,
	navigationLinking.SellerClient,
	navigationLinking.ClientLifestyle,
	navigationLinking.Walmart,
	navigationLinking.LeadCheckIn,
	navigationLinking.RealtorSignUp,
	navigationLinking.CustomWorkflow,
	navigationLinking.ListingOffer,
	navigationLinking.ListingOfferWithListing,
]

export default function App() {
	const [width] = useWindowSize()
	const ventiState = useVenti()
	const { state } = useAppContext()
	const { user } = state
	const { adminRoles } = useUser()
	const [isSessionModalVisible, setIsSessionModalVisible] =
		useState(false)
	const pleaseWaitMessage =
		ventiState.get(theme.storageKeys.pleaseWaitMessage) || ''
	const successObject = ventiState.get(
		theme.storageKeys.successObject
	) || { message: '', handleOK: null }
	const errorObject = ventiState.get(
		theme.storageKeys.errorObject
	) || { message: '', handleOK: null }
	const infoMessage =
		ventiState.get(theme.storageKeys.infoMessage) || ''
	const authTokenExp = ventiState.get(theme.storageKeys.authTokenExp)

	useEffect(() => {
		if (user?.isLoggedIn && !!authTokenExp && !interval) {
			interval = setInterval(
				() => {
					if (new Date() >= authTokenExp) {
						clearInterval(interval)
						interval = null
						setIsSessionModalVisible(true)
					}
				},
				10000,
				'IS_TOKEN_EXPIRED'
			)
		}

		return () => {
			clearInterval(interval)
		}
	}, [user, authTokenExp])

	const handleSuccessModalClose = () => {
		if (typeof successObject.handleOK === 'function')
			successObject.handleOK()
		else
			ventiState.set(theme.storageKeys.successObject, {
				message: '',
				handleOK: null,
			})
	}

	const handleErrorModalClose = () => {
		if (typeof errorObject.handleOK === 'function')
			errorObject.handleOK()
		else
			ventiState.set(theme.storageKeys.errorObject, {
				message: '',
				handleOK: null,
			})
	}

	return (
		<div className="app-pos">
			<HeaderNavigation width={width}>
				<ErrorBoundary>
					<Routes>
						{/* OPEN */}
						<Route path="/" element={<Landing />} />
						<Route
							path={navigationLinking.CreateAccount}
							element={<CreateAccount />}
						/>
						<Route
							path={navigationLinking.VerifyInvite}
							element={<CreateAccount />}
						/>
						<Route
							path={navigationLinking.SignIn}
							element={<SignIn />}
						/>
						<Route
							path={navigationLinking.SupportSignIn}
							element={<SignIn support />}
						/>
						<Route
							path={navigationLinking.AuthChallenge}
							element={<AuthChallenge />}
						/>
						<Route
							path={navigationLinking.SMSVerification}
							element={<SMSVerification />}
						/>
						<Route
							path={navigationLinking.ForgotPassword}
							element={<ForgotPassword />}
						/>
						<Route
							path={navigationLinking.Pricing}
							element={<Pricing />}
						/>
						<Route
							path={navigationLinking.PricingTable}
							element={<PricingResults />}
						/>
						<Route
							path={navigationLinking.PricingDetails}
							element={<PricingDetails />}
						/>
						<Route
							path={navigationLinking.Disclosures}
							element={<Disclosures />}
						/>
						<Route
							path={navigationLinking.DisclosuresSSO}
							element={<DisclosuresSSO />}
						/>

						{openLoanAppRoutes.map((route) => (
							<Route key={route} path={route} element={<LoanApp />} />
						))}

						<Route
							path={navigationLinking.ListingLanding}
							element={<ListingLanding />}
						/>
						<Route
							path={navigationLinking.ImpersonationConfirmation}
							element={<ImpersonationConfirmation />}
						/>

						{/* PROTECTED */}
						<Route
							path={navigationLinking.Portal}
							element={
								<PrivateRoute>
									<Portal />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminAccountEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AccountEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Leads}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<Leads />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LeadAdd}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<LeadAdd />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LeadEdit}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<LeadEdit />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LoanApplications}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.borrower,
										Roles.settlementAgent,
										Roles.branchManager,
										Roles.loanOfficerAssistant,
										Roles.loanProcessor,
									]}
								>
									<Pipeline />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LoanApplication}
							element={
								<PrivateRoute
									userRoles={[
										Roles.borrower,
										Roles.loanOfficer,
										Roles.branchManager,
										Roles.realtor,
										Roles.settlementAgent,
										Roles.loanOfficerAssistant,
										Roles.loanProcessor,
									]}
								>
									<LoanDetails />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AgentReferral}
							element={
								<PrivateRoute userRoles={[Roles.realtor]}>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.UserProfile}
							element={
								<PrivateRoute>
									<UserProfile />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.QuickPricer}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<QuickPricer />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Fees}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<Fees />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOApply}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOApplySpanish}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOApplyCoBorrower}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOApplyCoBorrowerSpanish}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOPrequal}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOPrequalSpanish}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOPrequalCoBorrower}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOPrequalCoBorrowerSpanish}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LOLeadApp}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanApp />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Tasks}
							element={
								<PrivateRoute
									userRoles={[
										Roles.borrower,
										Roles.loanOfficer,
										Roles.branchManager,
										Roles.loanOfficerAssistant,
										Roles.loanProcessor,
									]}
								>
									<Tasks />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Documents}
							element={
								<PrivateRoute
									userRoles={[
										Roles.borrower,
										Roles.loanOfficer,
										Roles.branchManager,
										Roles.realtor,
										Roles.loanOfficerAssistant,
										Roles.loanProcessor,
										Roles.settlementAgent,
									]}
								>
									<Documents />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LoanCalculator}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
										Roles.loanOfficerAssistant,
										Roles.loanProcessor,
									]}
								>
									<LoanCalculator />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.LoanPricingResults}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<LoanPricingResults />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Partner}
							element={
								<PrivateRoute
									userRoles={[Roles.loanOfficer, Roles.branchManager]}
								>
									<Partners />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.IncompleteLoans}
							element={
								<PrivateRoute userRoles={[Roles.admin]}>
									<IncompleteLoans />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.Listings}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<Listings />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.ListingAdd}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<ListingAdd />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.ListingEdit}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<ListingEdit />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.ListingDetail}
							element={
								<PrivateRoute
									userRoles={[
										Roles.realtor,
										Roles.loanOfficer,
										Roles.branchManager,
									]}
								>
									<Suspense>
										<ListingDetail />
									</Suspense>
								</PrivateRoute>
							}
						/>

						{/* ADMIN */}
						<Route
							path={navigationLinking.AdminPartner}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminPartners />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminPartnerEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminPartnerEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminPartnerEditProfile}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminPartnerProfile />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminRules}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminRules />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminRuleTasks}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminRuleTasks />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminCorporate}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminCorporations />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminCorporateEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminCorporateEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminLoanOfficer}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminLoanOfficers />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminLoanOfficerEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminLoanOfficerEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminBranch}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminBranches />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminBranchEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminBranchEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminUser}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminUsers />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminLoanQueue}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<Suspense>
										<AdminLoanQueue />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminNotificationTemplates}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminNotificationTemplates />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminNotificationTemplateAdd}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<Suspense>
										<AddNotificationTemplate />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminNotificationTemplateEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<Suspense>
										<EditNotificationTemplate />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminDocumentTemplates}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminDocumentTemplates />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminDocumentTemplateAdd}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<Suspense>
										<AddDocumentTemplate />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminDocumentTemplateEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<Suspense>
										<EditDocumentTemplate />
									</Suspense>
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminWorkflow}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminWorkflows />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminWorkflowEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminWorkflowEdit />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminDevice}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminDevices />
								</PrivateRoute>
							}
						/>
						<Route
							path={navigationLinking.AdminDeviceEdit}
							element={
								<PrivateRoute userRoles={adminRoles}>
									<AdminDeviceEdit />
								</PrivateRoute>
							}
						/>

						{/* 404 */}
						<Route path="*" element={<Landing />} />
					</Routes>
				</ErrorBoundary>
			</HeaderNavigation>

			<ModalPleaseWait
				visible={!!pleaseWaitMessage}
				message={pleaseWaitMessage}
			/>
			<ModalInfo
				visible={!!infoMessage}
				setVisible={() =>
					ventiState.set(theme.storageKeys.infoMessage, '')
				}
				message={infoMessage}
				handleOk={() =>
					ventiState.set(theme.storageKeys.infoMessage, '')
				}
			/>
			<ModalError
				visible={!!errorObject.message}
				setVisible={handleErrorModalClose}
				message={errorObject.message}
				secondaryMessage={errorObject.secondaryMessage}
			/>
			<ModalSuccess
				visible={!!successObject.message}
				setVisible={handleSuccessModalClose}
				message={successObject.message}
				secondaryMessage={successObject.secondaryMessage}
			/>
			<ModalSessionExpired
				visible={isSessionModalVisible}
				setVisible={setIsSessionModalVisible}
			/>

			<Tooltip id="pos-tooltip" />
		</div>
	)
}
