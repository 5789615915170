import React from 'react'
import loading from 'assets/loading.gif'

export type LoadingProps = {
	size?: 'small'
}

export const Loading = ({ size }: LoadingProps) => {
	return (
		<img
			className={size === 'small' ? 'w-24 h-24 m-auto' : ''}
			src={loading}
			alt="loading"
		/>
	)
}
