import React from 'react'
import LoanPage from '../../../components/LoanPage'
import withAuth from '../../../components/withAuth'
import LoanDocumentsContent from './LoanDocumentsContent'

const LoanDocuments = () => {
	return (
		<LoanPage page="documents" title="Documents" isFullWidth>
			<LoanDocumentsContent className="overflow-auto" />
		</LoanPage>
	)
}

export default withAuth(LoanDocuments)
