import { useEffect, useState, useReducer } from 'react'
import { isDarkMode } from '../services/helper'
import {
	setThemeColorsAndImages,
	setFavicon,
	setTitle,
} from '../config'
import {
	faFacebookF,
	faXTwitter,
	faInstagram,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import {
	TheBigPOSApi,
	setSiteConfiguration as setPOSApiSiteConfiguration,
} from '../lib/TheBigPOSClient'
import { FormattedMessage, useIntl } from 'react-intl'

const initialState = {
	siteConfig: null,
	socialLinks: null,
	legalLinks: null,
	errorStatus: null,
}

export function useSiteConfig() {
	const [state, dispatch] = useReducer(reducer, initialState, init)
	const [isLoading, setIsLoading] = useState(true)
	const { getSiteConfigurationByUrl } = TheBigPOSApi
	const { formatMessage } = useIntl()

	useEffect(() => {
		const currentUrl =
			window.location.hostname === 'localhost' ||
			window.location.hostname.includes('ngrok-free')
				? process.env.REACT_APP_LOCALHOST_ALIAS
				: window.location.hostname
		getSiteConfigurationByUrl({ url: currentUrl })
			.then((res) => {
				const siteConfig = res.data
				setPOSApiSiteConfiguration(siteConfig.id)
				setThemeColorsAndImages(siteConfig)
				setFavicon(
					isDarkMode() && siteConfig.darkModeIconUrl
						? siteConfig.darkModeIconUrl
						: siteConfig.iconUrl
				)
				setTitle(siteConfig.name)

				// Set Social Links
				const socialLinks = []
				if (siteConfig.facebookUrl)
					socialLinks.push({
						linkItem: 'Facebook',
						link: siteConfig.facebookUrl,
						icon: faFacebookF,
					})
				if (siteConfig.twitterUrl)
					socialLinks.push({
						linkItem: 'Twitter',
						link: siteConfig.twitterUrl,
						icon: faXTwitter,
					})
				if (siteConfig.instagramUrl)
					socialLinks.push({
						linkItem: 'Instagram',
						link: siteConfig.instagramUrl,
						icon: faInstagram,
					})
				if (siteConfig.linkedInUrl)
					socialLinks.push({
						linkItem: 'LinkedIn',
						link: siteConfig.linkedInUrl,
						icon: faLinkedin,
					})

				// Set Legal Links
				const legalLinks = []
				if (siteConfig.companyUrl)
					legalLinks.push({
						linkItem: formatMessage({
							id: 'legal.website',
						}),
						link: siteConfig.companyUrl,
					})
				if (siteConfig.contactUsUrl)
					legalLinks.push({
						linkItem: formatMessage({
							id: 'legal.contact_us',
						}),
						link: siteConfig.contactUsUrl,
					})
				if (siteConfig.privacyPolicyUrl)
					legalLinks.push({
						linkItem: formatMessage({
							id: 'legal.privacy_policy',
						}),
						link: siteConfig.privacyPolicyUrl,
					})
				if (siteConfig.termsUrl)
					legalLinks.push({
						linkItem: formatMessage({
							id: 'legal.terms',
						}),
						link: siteConfig.termsUrl,
					})
				if (siteConfig.licenseInfoUrl)
					legalLinks.push({
						linkItem: formatMessage({
							id: 'legal.license_info',
						}),
						link: siteConfig.licenseInfoUrl,
					})

				dispatch({
					type: 'setSiteConfig',
					payload: { siteConfig, socialLinks, legalLinks },
				})
			})
			.catch((err) => {
				dispatch({
					type: 'setSiteConfig',
					payload: {
						errorStatus: err?.status,
						siteConfig: null,
						socialLinks: null,
						legalLinks: null,
					},
				})
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	return {
		siteConfig: state.siteConfig,
		socialLinks: state.socialLinks,
		legalLinks: state.legalLinks,
		isLoading,
		errorStatus: state.errorStatus,
	}
}

function init() {
	return initialState
}

function reducer(state, action) {
	switch (action.type) {
		case 'setSiteConfig':
			return {
				siteConfig: action.payload.siteConfig,
				socialLinks: action.payload.socialLinks,
				legalLinks: action.payload.legalLinks,
				errorStatus: action.payload.errorStatus,
			}
		default:
			throw new Error()
	}
}
