import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { Tabs, Tab, Card, CardContent, useTheme } from '@mui/material'
import { a11yProps } from '../../../services/helper'
import Page from '../../../components/Page'
import withAuth from '../../../components/withAuth'
import TabPanel from '../../../components/TabPanel'
import LoansTab from './components/LoansTab'
import LoanDrafts from './components/LoanDrafts'
import useUser from '../../../hooks/useUser'

function Pipeline() {
	const [activeTab, setActiveTab] = useState(0)
	const themeMUI = useTheme()
	const { isBorrower, isLoanOfficer } = useUser()

	const pageTitle = isBorrower
		? 'My Applications'
		: 'Loan Applications'

	const handleTabChange = (
		_event: React.SyntheticEvent,
		newValue: number
	) => {
		setActiveTab(newValue)
	}

	const handleChangeIndex = (index: number) => {
		setActiveTab(index)
	}

	return (
		<Page page="app-pipeline" title={pageTitle} isFullWidth>
			<div className="pl-5 pr-5 pb-10 h-full">
				{isLoanOfficer ? (
					<>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							value={activeTab}
							onChange={handleTabChange}
							aria-label="Tabs"
						>
							<Tab label="Loan Pipeline" {...a11yProps(0)} />
							<Tab label="Incomplete loans" {...a11yProps(1)} />
						</Tabs>
						<SwipeableViews
							axis={themeMUI.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={activeTab}
							onChangeIndex={handleChangeIndex}
						>
							<TabPanel
								value={activeTab}
								index={0}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<LoansTab pageTitle={pageTitle} />
									</CardContent>
								</Card>
							</TabPanel>
							<TabPanel
								value={activeTab}
								index={1}
								dir={themeMUI.direction}
							>
								<Card>
									<CardContent>
										<LoanDrafts />
									</CardContent>
								</Card>
							</TabPanel>
						</SwipeableViews>
					</>
				) : (
					<LoansTab pageTitle={pageTitle} />
				)}
			</div>
		</Page>
	)
}

export default withAuth(Pipeline)
