import Dialog from '@mui/material/Dialog'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { Button, LoadingBtn } from '../Button'
import { isDarkMode } from '../../services/helper'
import { useAppContext } from '../AppContext'

export type ModalConfirmationProps = {
	open: boolean
	text: string
	isOkButton?: boolean
	isSave?: boolean
	canBeSaved?: boolean
	loadingText?: string
}

export type ModalYesNoProps = {
	modalConfirmation: ModalConfirmationProps
	setModalConfirmation: (data: ModalConfirmationProps) => void
	modalConfirm: (data: ModalConfirmationProps) => void
	modalDismiss?: () => void
	saveForm?: () => void
	yesButtonName?: string
	noButtonName?: string
	loading?: boolean
}

export const ModalYesNo = ({
	modalConfirmation,
	setModalConfirmation,
	modalConfirm,
	modalDismiss,
	saveForm,
	yesButtonName,
	noButtonName,
	loading,
}: ModalYesNoProps) => {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
	const { state } = useAppContext()
	const { siteConfig } = state

	const typedSiteConfig = siteConfig as unknown as {
		darkModeLogoUrl: string
		logoUrl: string
	}

	const handleYesClick = () => {
		if (modalConfirmation.isSave) {
			modalConfirm({ ...modalConfirmation, canBeSaved: true })
			if (saveForm) {
				saveForm()
			}
		} else {
			modalConfirm(modalConfirmation)
		}
	}

	const handleNoClick = () => {
		if (typeof modalDismiss === 'function') modalDismiss()
		else
			setModalConfirmation({
				...modalConfirmation,
				canBeSaved: false,
				open: false,
			})
	}

	return (
		<Dialog
			open={modalConfirmation.open}
			aria-labelledby="responsive-dialog-title"
		>
			<div className="flex flex-col items-center px-6 md:max-w-lg py-8 md:px-10">
				<img
					src={
						isDarkMode() && typedSiteConfig?.darkModeLogoUrl
							? typedSiteConfig.darkModeLogoUrl
							: typedSiteConfig?.logoUrl
					}
					alt="Company Logo"
					className="w-64 sm:w-96 py-5"
				/>
				<p className="pb-5 text-xl text-center font-bold whitespace-pre-wrap">
					{modalConfirmation.loadingText && loading
						? modalConfirmation.loadingText
						: modalConfirmation.text}
				</p>
				{modalConfirmation.isOkButton ? (
					<Button
						id="ModalYesNoOKButton"
						text="Ok"
						onClick={() =>
							setModalConfirmation({
								...modalConfirmation,
								canBeSaved: false,
								open: false,
							})
						}
						style={fullScreen ? { width: '90%' } : { width: 290 }}
						variant="outlined"
					/>
				) : (
					<>
						<LoadingBtn
							id="ModalYesNoOKButton"
							text={yesButtonName || 'Yes'}
							onClick={handleYesClick}
							style={
								fullScreen
									? { width: '90%', marginBottom: 10 }
									: { width: 290, marginBottom: 10 }
							}
							variant="contained"
							loading={loading}
						/>
						<Button
							id="ModalYesNoYesButton"
							text={noButtonName || 'No'}
							onClick={handleNoClick}
							style={fullScreen ? { width: '90%' } : { width: 290 }}
							variant="outlined"
						/>
					</>
				)}
			</div>
		</Dialog>
	)
}
