import React, { ChangeEvent } from 'react'
import { InputAdornment } from '@mui/material'
import { merge } from 'lodash'
import TextField, { TextFieldProps } from './TextField'
import { applyMask } from '../../services/utils'

export type MoneyFieldProps = TextFieldProps

const MoneyField = ({ InputProps, ...rest }: MoneyFieldProps) => {
	const startAdornment = (
		<InputAdornment position="start">$</InputAdornment>
	)
	const mergedInputProps = InputProps
		? merge(InputProps, { startAdornment })
		: { startAdornment }

	const onChange = (event: ChangeEvent<{ value: string }>) => {
		// eslint-disable-next-line no-param-reassign
		event.target.value = applyMask(event.target.value, 'currency') // @todo this should be handled with state and not set on the event
	}

	return (
		<TextField
			onChange={onChange}
			InputProps={mergedInputProps}
			{...rest}
		/>
	)
}

export default MoneyField
