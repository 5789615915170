import React from 'react'
import * as yup from 'yup'
import { FieldValues } from 'react-hook-form'
import { LoadingBtn } from '../../../../components/Button'
import { Form, TextField } from '../../../../components/formControls'
import useForm from '../../../../hooks/useForm'

type TaskCommentFormProps = {
	handleSubmit: (value: string) => void
	isSubmitting: boolean
	isRejecting: boolean
	handleIsRejected?: () => void
}

const TaskCommentForm = ({
	handleSubmit: handleFormSubmit,
	isSubmitting,
	isRejecting,
	handleIsRejected,
}: TaskCommentFormProps) => {
	const schema = yup.object().shape({
		comment: yup.string().required('Comment is required'),
	})

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		schema,
		values: {
			comment: '',
		},
		resetOptions: {
			keepValues: false,
		},
	})

	const onSubmit = (data: FieldValues) => {
		const { comment } = data
		handleFormSubmit(comment)
	}
	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				name="comment"
				label=""
				placeholder="Add a comment..."
				id="comment"
				className="w-full"
				sx={{ mb: 2 }}
				multiline
				variant="outlined"
				rows={10}
				control={control}
				error={!!errors.comment}
			/>

			<div className="flex justify-between">
				{isRejecting && (
					<LoadingBtn
						text="Reject without Comment"
						variant="outlined"
						sx={{ textTransform: 'none' }}
						loading={isSubmitting}
						disabled={isSubmitting}
						fullWidth={false}
						onClick={handleIsRejected}
					/>
				)}
				<LoadingBtn
					type="submit"
					text="Submit"
					loading={isSubmitting}
					disabled={isSubmitting}
					fullWidth={!isRejecting}
				/>
			</div>
		</Form>
	)
}

export default TaskCommentForm
