import React, { useEffect } from 'react'
import { useAppContext } from './AppContext'
import { FixMeLater } from '../types'
// import ChatBot from "./ChatBot"

export type PageProps = {
	children: React.ReactNode
	page?: string
	title?: string
	isFullWidth?: boolean
	noDarkMode?: boolean
}

export default function Page({
	children,
	page = '',
	title,
	isFullWidth = false,
	noDarkMode = false,
}: PageProps) {
	const { state } = useAppContext()
	const { siteConfig } = state

	useEffect(() => {
		const titleName = (siteConfig as FixMeLater)?.name || ''

		// @todo - fix nested ternary
		// eslint-disable-next-line no-nested-ternary
		document.title = title
			? titleName
				? `${titleName} - ${title}`
				: title
			: titleName
	}, [title, siteConfig])

	return (
		<div className={`${noDarkMode ? '' : 'dark:bg-[#121212]'}`}>
			<div
				className={`${page} ${isFullWidth ? '' : 'max-w-screen-xl'} mx-auto ${noDarkMode ? '' : 'dark:bg-[#121212]'}`}
			>
				{children}
				{/* {process.env.REACT_APP_ENV_NAME !== 'shared' && user?.isLoggedIn && ["LoanOfficer", "BranchManager"].includes(user.role) && <ChatBot />} */}
			</div>
		</div>
	)
}
