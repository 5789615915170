import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import Dialog from '../../../../components/modals/Dialog'
import TaskCommentForm from './TaskCommentForm'
import { TheBigPOSApi } from '../../../../lib/TheBigPOSClient'
import { useAlert } from '../../../../hooks'

type TaskCommentModalProps = {
	open: boolean
	onClose: () => void
	userLoanTaskId: string
	loanId: string
	handleConfirm?: () => void
	isRejecting?: boolean
}

const TaskCommentModal = ({
	open,
	onClose,
	userLoanTaskId,
	loanId,
	handleConfirm,
	isRejecting = false,
}: TaskCommentModalProps) => {
	const { alert } = useAlert()
	const [isSubmitting, setIsSubmitting] = useState(false)

	const addCommentMutation = useMutation({
		mutationFn: async (data: string) =>
			TheBigPOSApi.createLoanTaskComment(loanId, userLoanTaskId, {
				comment: data,
			}),
		onSuccess: async () => {
			onClose()
			setIsSubmitting(false)
			if (handleConfirm) handleConfirm()
			else alert('Comment successfully added')
		},
	})

	const handleAddComment = async (comment: string) => {
		setIsSubmitting(true)
		await addCommentMutation.mutateAsync(comment)
	}

	const handleIsRejected = async () => {
		setIsSubmitting(true)
		if (handleConfirm) handleConfirm()
		onClose()
		setIsSubmitting(false)
	}

	return (
		<Dialog
			title="Add a Comment"
			open={open}
			onClose={() => onClose()}
			fullWidth
		>
			<TaskCommentForm
				handleSubmit={handleAddComment}
				isSubmitting={isSubmitting}
				isRejecting={isRejecting}
				handleIsRejected={handleIsRejected}
			/>
		</Dialog>
	)
}

export default TaskCommentModal
