import React from 'react'
import { useIntl } from 'react-intl'
import DownloadSSOMetadataButton from './DownloadSSOMetadataButton'
import Heading from '../Heading'

const SSOIntegrations = () => {
	const { formatMessage } = useIntl()

	return (
		<div>
			<Heading size="xl" className="mb-3">
				{formatMessage({ id: 'sso.sso_integrations' })}
			</Heading>
			<div className="flex items-center">
				<div className="mr-2">
					<DownloadSSOMetadataButton
						ssoIntegration="TheBigPOS"
						text={formatMessage({
							id: 'sso.download_bpos_metadata_file',
						})}
					/>
				</div>

				<DownloadSSOMetadataButton
					ssoIntegration="ConsumerConnect"
					text={formatMessage({
						id: 'sso.download_cc_metadata_file',
					})}
				/>
			</div>
		</div>
	)
}

export default SSOIntegrations
